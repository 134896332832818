import { useState, useLayoutEffect, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import useAuth from "../hooks/useAuth";
import {
  formatDate,
  formatFilename,
  getFilenameDate,
} from "../common/utilities";
import useUserList from "../hooks/useUserList";
import useActivityDetailsWithTasks from "../hooks/useActivityDetailsWithTasks";
import useWorkflowDetails from "../hooks/useWorkflowDetails";
import { Dropdown } from "primereact/dropdown";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Divider } from "primereact/divider";
import WorkflowCanvas from "../components/WorkflowCanvas";
import TaskAssigneeList from "../components/TaskAssigneeList";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScrollPanel } from "primereact/scrollpanel";
import CommentFeedLayout from "../components/CommentFeedLayout";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Button } from "primereact/button";
import { getBinaryFile } from "../services/apiFiles";
import { Sidebar } from "primereact/sidebar";
import DocumentViewer from "../components/DocumentViewer";

export default function Tasks(props) {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const params = useParams();
  const navigate = useNavigate();
  const { getToken, userId } = useAuth();
  const accessToken = getToken();
  const [tasks, setTasks] = useState([]);
  const [activityTasks, setActivityTasks] = useState([]);
  const [collaboratorList, setCollaboratorList] = useState();
  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();
  const [taskAssignees, setTaskAssignees] = useState();
  const [loadingIndicatorTasks, setLoadingIndicatorTasks] = useState(true);
  const [loadingIndicatorActivity, setLoadingIndicatorActivity] =
    useState(true);
  const [activityFolderId, setActivityFolderId] = useState(-1);
  const [filters, setFilters] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const [fileInReview, setFileInReview] = useState(null);
  const [visibleTop, setVisibleTop] = useState(false);
  const [binaryFile, setBinaryFile] = useState();

  const { userList } = useUserList(accessToken);

  const { activityDetails } = useActivityDetailsWithTasks(
    params.activityId,
    accessToken
  );

  const { workflowDetails } = useWorkflowDetails(
    activityDetails?.templateId > 0 ? activityDetails?.templateId : 0,
    accessToken
  );

  const loadFile = async (file) => {
    getBinaryFile(file.id, accessToken)
      .then((buffer) => {
        setBinaryFile(buffer.data);
      })
      .catch((e) => {
        throw e;
      });
  };

  useEffect(() => {
    if (activityDetails && userList) {
      const workflow = JSON.parse(activityDetails.workflow);
      const assignees = JSON.parse(activityDetails.taskAssignees);
      const userIsTaskAssignee = assignees.find(
        (user) => user.userId === userId
      );

      const notificationNodeTargets = workflow.nodes
        .map((node) => {
          if (node.type === "notificationNode") {
            return node.data.targetUsers;
          } else {
            return false;
          }
        })
        .filter(Boolean)
        .flat();

      const userIsNotificationTarget = notificationNodeTargets.find(
        (user) => user.code === userId
      );

      const userIsCollaborator = activityDetails.collaborators.find(
        (user) => user.collaboratorId === userId
      );

      if (
        userIsTaskAssignee ||
        userIsCollaborator ||
        userIsNotificationTarget ||
        activityDetails?.createdById === userId
        // || activityDetails?.alternateOICId === userId
      ) {
        setNodes(workflow.nodes);
        setEdges(workflow.edges);

        resolveTaskAssignees(assignees, workflow.nodes);

        setActivityTasks(activityDetails.tasks);

        setFilters(userSettings.filters.tasks);
        setPageSize(userSettings.pageSize);

        setLoadingIndicatorTasks(false);
      } else {
        navigate("/error", { replace: true });
      }
    }
  }, [activityDetails, userList]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (activityTasks && activityTasks.length > 0 && userList) {
      resolveUserIds(); // activityTasks
    }
  }, [activityTasks, userList]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (activityDetails && userList) {
      resolveCollaboratorUserIds();
    }
  }, [activityDetails, userList]); // eslint-disable-line react-hooks/exhaustive-deps

  const resolveTaskAssignees = (taskAssignees, nodes) => {
    const updatedTaskAssignees = taskAssignees.map((assignee) => {
      let user = userList.find((user) => user.code === assignee.userId);

      if (!user && assignee.userId === -1) {
        user = { name: "< ACTIVITY OWNER >", code: -1 };
      }

      const node = nodes.find((node) => node.id === assignee.nodeId);

      if (node) {
        const taskAssignee = {
          user: user ? user : { name: "", code: 0 },
          node: { nodeId: node.id, label: node.data.label },
        };

        return taskAssignee;
      } else {
        return false;
      }
    });

    setTaskAssignees(updatedTaskAssignees.filter(Boolean));
  };

  const resolveCollaboratorUserIds = () => {
    const updatedCollaboratorList = activityDetails?.collaborators?.map(
      (collaboratorUser) => {
        return userList.find(
          (user) => user.code === parseInt(collaboratorUser.collaboratorId)
        );
      }
    );
    setCollaboratorList(updatedCollaboratorList);

    setLoadingIndicatorActivity(false);
  };

  const resolveUserIds = () => {
    const updatedTasks = activityTasks.map((task) => {
      const initiatorUser = userList.find(
        (user) => user.code === parseInt(task.initiatorUserId)
      );

      const assigneeUser = userList.find(
        (user) => user.code === parseInt(task.assigneeId)
      );

      let taskDetails = {
        id: task.id,
        name: task.name,
        customNodeName: task.customNodeName,
        dateRequested: task.dateRequested,
        status: task.status,
        activityName: activityDetails.name,
        templateId: activityDetails.templateId,
        folderId: activityDetails.folderId,
        initiatorName: initiatorUser ? initiatorUser.name : "",
        assigneeName: assigneeUser ? assigneeUser.name : "",
      };

      return taskDetails;
    });
    setTasks(updatedTasks);

    if (updatedTasks.length > 0) {
      setActivityFolderId(updatedTasks[0].folderId);
    }
  };

  const dateDataFormat = (rowData) => {
    return formatDate(rowData.dateRequested);
  };

  const statusDisplayTemplate = (data) => {
    if (data.status === "IN_PROGRESS") {
      return "IN PROGRESS";
    } else if (data.status === "NOT_STARTED") {
      return "NOT STARTED";
    } else if (data.status === "REJECT_AND_REVISE") {
      return "FOR REVISION";
    } else if (data.status === "REJECTED") {
      return "DISAPPROVED";
    } else {
      return data.status;
    }
  };

  const handleFilterChange = (newFilters) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      filters: {
        ...userSettings.filters,
        tasks: newFilters.filters,
      },
    }));

    setFilters(newFilters.filters);
  };

  const handlePageChange = ({ first, rows }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      pageSize: rows,
    }));

    setPageSize(rows);
    setOffset(first);
  };

  const statuses = [
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "FOR REVISION", value: "REJECT_AND_REVISE" },
    { label: "IN PROGRESS", value: "IN_PROGRESS" },
    { label: "DISAPPROVED", value: "REJECTED" },
  ];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        className="p-column-filter"
        placeholder="Select a Status"
        value={options.value}
        options={statuses}
        optionValue="value"
        optionLabel="label"
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  const actionTasksTemplate = (rowData) => {
    // Need Id of step from workflow template (workflowStepId) to identify where we are in the template view
    // Also, use workflowStepId to get type of step (basic or review) and step configuration
    return rowData.status !== "CANCELLED" && rowData.name !== "Notification" ? (
      <div className="actions flex-nowrap flex justify-content-center">
        <Link
          to={"/task/" + rowData.id}
          className="p-button p-button-icon-only p-button-text mr-2"
          // tooltip="View Task"
          // tooltipOptions={{ position: "top" }}
        >
          {rowData.status !== "COMPLETED" &&
            rowData.status !== "REJECT_AND_REVISE" &&
            rowData.status !== "REJECTED" && (
              <i className="pi pi-arrow-circle-right text-2xl font-light" />
            )}
          {rowData.status === "COMPLETED" && (
            <i className="pi pi-check-circle text-2xl font-light text-green-500" />
          )}
          {rowData.status === "REJECT_AND_REVISE" && (
            <i className="pi pi-exclamation-circle text-2xl font-light text-yellow-500" />
          )}
          {rowData.status === "REJECTED" && (
            <i className="pi pi-times-circle text-2xl font-light text-pink-500" />
          )}
        </Link>
      </div>
    ) : (
      <div></div>
    );
  };

  const taskNameDisplayTemplate = (rowData) => {
    if (rowData.customNodeName && rowData.customNodeName !== "< Node Name >") {
      return rowData.customNodeName;
    } else {
      return rowData.name;
    }
  };

  const bodyTemplate = (rowData) => {
    return (
      <>
        <div className="flex w-full justify-content-between">
          <Button
            label={formatFilename(rowData.name)}
            className="p-button-link"
            onClick={() => {
              loadFile(rowData);
              setFileInReview(rowData);

              setTimeout(() => {
                setVisibleTop(true);
              }, 100);
            }}
          />
          <p>{getFilenameDate(rowData.name)}</p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="grid">
        <div className="lg:col-8 md:col-12">
          <div className="card">
            <span className="flex justify-content-between">
              <h3 className="m-3 mb-4">
                {activityDetails && (
                  <>
                    {activityDetails.name}&nbsp;(
                    {activityDetails.status === "IN_PROGRESS"
                      ? "IN PROGRESS"
                      : activityDetails.status === "NOT_STARTED"
                      ? "NOT STARTED"
                      : activityDetails.status === "REJECT_AND_REVISE"
                      ? "FOR REVISION"
                      : activityDetails.status}
                    )
                  </>
                )}
              </h3>

              <div className="flex justify-content-end align-items-center"></div>
            </span>

            <DataTable
              value={tasks}
              paginator
              rows={pageSize}
              first={offset}
              rowsPerPageOptions={[10, 25, 50]}
              className="p-datatable-gridlines"
              showGridlines
              dataKey="id"
              loading={loadingIndicatorTasks}
              responsiveLayout="scroll"
              emptyMessage="No pending tasks found."
              size="small"
              filters={filters}
              filterDisplay="menu"
              globalFilterFields={["status"]}
              rowClassName={(data) => {
                return data.status === "COMPLETED"
                  ? "bg-green-50"
                  : data.status === "IN_PROGRESS"
                  ? "bg-blue-50"
                  : data.status === "REJECT_AND_REVISE"
                  ? "bg-yellow-50"
                  : data.status === "REJECTED"
                  ? "bg-pink-50"
                  : data.status === "CANCELLED"
                  ? "bg-gray-50"
                  : "";
              }}
              onFilter={handleFilterChange}
              onPage={handlePageChange}
            >
              <Column body={taskNameDisplayTemplate} header="Name" />
              <Column
                header="Assignee"
                field="assigneeName"
                showFilterMatchModes={false}
                filterMenuStyle={{ width: "14rem" }}
              />
              <Column
                header="Date Requested"
                field="dateRequested"
                dataType="date"
                bodyClassName="text-center"
                body={dateDataFormat}
                style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
              />
              <Column
                field="status"
                header="Status"
                showFilterMatchModes={false}
                showFilterMenuOptions={false}
                showFilterMenu={true}
                showFilterOperator={false}
                body={statusDisplayTemplate}
                filter
                filterElement={statusFilterTemplate}
                style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
              />
              <Column
                body={actionTasksTemplate}
                bodyClassName="text-center"
                style={{ maxWidth: "8rem", width: "8rem", minWidth: "5rem" }}
              />
            </DataTable>
          </div>
          {activityDetails?.mainFiles &&
            activityDetails?.mainFiles.length > 0 && (
              <div className="card">
                <DataTable
                  value={activityDetails.mainFiles}
                  paginator
                  rows={pageSize}
                  rowsPerPageOptions={[10, 25, 50]}
                  className="p-datatable-gridlines"
                  showGridlines
                  dataKey="id"
                  responsiveLayout="scroll"
                  emptyMessage="No files found."
                  size="small"
                  sortMode="single"
                  removableSort
                  onFilter={handleFilterChange}
                  onPage={handlePageChange}
                >
                  <Column
                    field="name"
                    header="Wet Signature Document(s)"
                    sortable
                    sortField="name"
                    body={bodyTemplate}
                  />
                </DataTable>

                <Sidebar
                  fullScreen
                  visible={visibleTop}
                  className="bg-white-100"
                  onHide={() => setVisibleTop(false)}
                  blockScroll
                >
                  <DocumentViewer
                    file={binaryFile}
                    fileName={fileInReview?.name}
                    isCurrentUserTaskOwner={false}
                  />
                </Sidebar>
              </div>
            )}
          <div className="card">
            <BlockUI
              blocked={loadingIndicatorActivity}
              template={<ProgressSpinner />}
            >
              <div className="grid row mb-2">
                <div className="col-1"></div>
                <div className="col-3">
                  <span className="font-bold">Activity Name</span>
                </div>
                <div className="col">
                  <span>{activityDetails && activityDetails.name}</span>
                </div>
              </div>

              <div className="grid row mb-2">
                <div className="col-1"></div>
                <div className="col-3">
                  <span className="font-bold">Workflow Template</span>
                </div>
                <div className="col">
                  <span>{workflowDetails?.name}</span>
                </div>
              </div>

              <div className="grid row mb-2">
                <div className="col-1"></div>
                <div className="col-3">
                  <span className="font-bold">Shared To</span>
                </div>
                <div className="col">
                  <ScrollPanel
                    style={{
                      width: "100%",
                      height: "160px",
                    }}
                    className="customScrollbar"
                  >
                    {collaboratorList &&
                      collaboratorList.map((user, i) => {
                        return (
                          <div className="mb-1" key={i}>
                            {user && user.name}
                          </div>
                        );
                      })}
                  </ScrollPanel>
                </div>
              </div>

              <Divider align="left" type="solid">
                <span className="text-pink-500">
                  <b>NOTE:</b> Below is the snapshot of the workflow template
                  when the activity was created.
                </span>
              </Divider>
              <div className="mb-3 flex justify-content-start">
                <div className="w-screen h-fit max-w-full">
                  {nodes && edges && taskAssignees && (
                    <WorkflowCanvas
                      nodes={nodes}
                      edges={edges}
                      allowEdit={false}
                      assignUser={true}
                      reviewMode={true}
                      taskAssignees={taskAssignees}
                    />
                  )}
                </div>
                <div className="ml-4 mr-6">
                  {nodes && taskAssignees && (
                    <TaskAssigneeList
                      taskAssignees={taskAssignees}
                      nodes={nodes}
                    />
                  )}
                </div>
              </div>
            </BlockUI>
          </div>
        </div>
        <div className="lg:col-4 md:col mb-3">
          <div className="card">
            <CommentFeedLayout
              activityId={params.activityId}
              activityFolderId={activityFolderId}
            />
          </div>
        </div>
      </div>
    </>
  );
}
