import { useState, useLayoutEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import useAuth from "../hooks/useAuth";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import WorkflowCanvas from "../components/WorkflowCanvas";
import TaskAssigneeList from "../components/TaskAssigneeList";
import { updateActivityStatus } from "../services/apiActivities";
import { actionSuccess, dialogMessageList } from "../common/dialogMessages";
import { Toast } from "primereact/toast";
import useUserList from "../hooks/useUserList";
import useActivityDetails from "../hooks/useActivityDetails";
import useActivityList from "../hooks/useActivityList";
import useCollaborationList from "../hooks/useCollaborationList";
import useActivityDetailsWithTasks from "../hooks/useActivityDetailsWithTasks";
import useTaskList from "../hooks/useTaskList";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";
import useAssignmentCounts from "../hooks/useAssignmentCounts";

export default function ActivityReviewDialog(props) {
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [picklistTargetValue, setPicklistTargetValue] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const [activityName, setActivityName] = useState();
  const [nodes, setNodes] = useState();
  const [edges, setEdges] = useState();
  const { getToken, userId } = useAuth();
  const accessToken = getToken();

  const toast = useRef(null);

  const { userList } = useUserList(accessToken);
  const { activityDetails, mutateActivityDetails } = useActivityDetails(
    props.activityId > 0 ? props.activityId : 0,
    accessToken
  );
  const { mutateTaskList } = useTaskList(accessToken);
  const { mutateActivityList } = useActivityList(accessToken);
  const { mutateCollaborationList } = useCollaborationList(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );
  const { mutateActivityDetailsWithTasks } = useActivityDetailsWithTasks(
    props.activityId > 0 ? props.activityId : 0,
    accessToken
  );
  const { mutateAssignmentCounts } = useAssignmentCounts(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  useLayoutEffect(() => {
    setBlockedPanel(true);
    if (activityDetails && props.activityId > 0) {
      getActivityCollaborators();

      setActivityName(activityDetails.name);

      const workflow = JSON.parse(activityDetails.workflow);
      setNodes(workflow.nodes);
      setEdges(workflow.edges);
      resolveTaskAssignees(
        JSON.parse(activityDetails.taskAssignees),
        workflow.nodes
      );
    }
  }, [activityDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActivityCollaborators = () => {
    getCollaboratorList(activityDetails.collaborators);
    setBlockedPanel(false);
  };

  const getCollaboratorList = (collaborators) => {
    const selectedUserOptions = userList.map(function (user) {
      if (
        collaborators.find((element) => element.collaboratorId === user.code)
      ) {
        return user;
      } else {
        return false;
      }
    }, collaborators);
    setPicklistTargetValue(selectedUserOptions.filter(Boolean));
  };

  const resolveTaskAssignees = (taskAssignees, nodes) => {
    const updatedTaskAssignees = taskAssignees.map((assignee) => {
      let user = userList.find((user) => user.code === assignee.userId);

      if (!user && assignee.userId === -1) {
        user = { name: "< ACTIVITY OWNER >", code: -1 };
      }

      const node = nodes.find((node) => node.id === assignee.nodeId);

      if (node) {
        const taskAssignee = {
          user: user ? user : { name: "", code: 0 },
          node: { nodeId: node?.id, label: node?.data?.label },
        };

        return taskAssignee;
      } else {
        return false;
      }
    });

    setTaskAssignees(updatedTaskAssignees.filter(Boolean));
  };

  const handleStartActivity = () => {
    // update collaborators list first then:
    updateActivityStatus(
      props.activityId,
      { activityId: props.activityId, status: "IN_PROGRESS" },
      accessToken
    )
      .then((response) => {
        if (response.status === "IN_PROGRESS") {
          toast.current.show(
            actionSuccess(dialogMessageList.ActivityStartSuccessful)
          );
          mutateActivityDetails();
          // mutate task list
          mutateTaskList();
          // mutate collab list
          mutateCollaborationList();
          // mutate activity list
          mutateActivityList();
          // mutate activity lists with tasks
          mutateActivityDetailsWithTasks();
          // mutate In Progress task and activity counts
          mutateAssignmentCounts();

          props.onHide();
        }
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const footer = () => {
    return (
      <>
        <div className="flex justify-content-center">
          <div className="flex justify-content-center">
            <Button
              label="Close"
              icon="pi pi-times"
              onClick={props.onHide}
              className="p-button p-button-outlined mr-2"
            />
          </div>
          <div className="flex justify-content-center">
            <Button
              label="Start Activity"
              icon="pi pi-play"
              type="submit"
              className="p-button ml-2"
              onClick={handleStartActivity}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={<div>Review Activity Details</div>}
        footer={footer}
        visible={props.displayActivityReview}
        onHide={props.onHide}
        resizable={false}
        draggable={false}
        blockScroll
        maximized={false}
        className="w-fit"
      >
        <BlockUI blocked={blockedPanel} template={<ProgressSpinner />}>
          {props.displayActivityReview && (
            <>
              <div className="grid row mb-2">
                <div className="col-1"></div>
                <div className="col-3">
                  <span className="font-bold">Activity Name</span>
                </div>
                <div className="col">
                  <span>{activityName}</span>
                </div>
              </div>

              <div className="grid row mb-2">
                <div className="col-1"></div>
                <div className="col-3">
                  <span className="font-bold">Shared To</span>
                </div>
                <div className="col">
                  {picklistTargetValue.map((user, i) => {
                    return (
                      <div className="mb-1" key={i}>
                        {user.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              <Divider align="left" type="solid">
                <b>Workflow Template</b>
              </Divider>
              <div className="mb-3 flex justify-content-start">
                <div
                  className="w-screen"
                  style={{
                    maxWidth: "50vw",
                  }}
                >
                  {nodes && (
                    <WorkflowCanvas
                      nodes={nodes}
                      edges={edges}
                      allowEdit={false}
                      assignUser={true}
                      reviewMode={true}
                      taskAssignees={taskAssignees}
                    />
                  )}
                </div>
                <div className="ml-2">
                  {nodes && (
                    <TaskAssigneeList
                      taskAssignees={taskAssignees}
                      nodes={nodes}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </BlockUI>
      </Dialog>
    </>
  );
}
