import React, { useState, useRef } from "react";
import { createWorkflow, deleteWorkflow } from "../services/apiWorkflow";
import { Button } from "primereact/button";
import WorkflowDialog from "../components/WorkflowDialog";
import useAuth from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import { workflowJsonModel } from "../common/workflowHelper";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import {
  actionCancel,
  dialogMessageList,
  deleteDialog,
  actionSuccess,
} from "../common/dialogMessages";
import { useNavigate } from "react-router-dom";
import WorkflowList from "../components/WorkflowList";
import WorkflowTemplateDialog from "../components/WorkflowTemplateDialog";
import useWorkflowList from "../hooks/useWorkflowList";

export default function Workflow() {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loadingIndicatorButtons, setLoadingIndicatorButtons] = useState(false);
  const [workflowId, setWorkflowId] = useState();

  const [displayBasic, setDisplayBasic] = useState(false);
  const [showTemplateName, setShowTemplateName] = useState(false);
  const defaultValues = {
    workflowName: "",
    departmentId: -1,
  };
  const { getToken } = useAuth();
  const accessToken = getToken();

  const { mutateWorkflowList } = useWorkflowList(accessToken);

  const workflowModel = {
    name: "",
    departmentId: -1,
    active: true,
    workflow: JSON.stringify(workflowJsonModel),
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const createNewWorkflow = (data) => {
    setLoadingIndicatorButtons(true);

    workflowModel.name = data.workflowName;
    workflowModel.departmentId = data.departmentId;

    createWorkflow(workflowModel, accessToken)
      .then((response) => {
        navigate("/designer/" + response.id, { replace: true });
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const handleShowTemplate = (data) => {
    setDisplayBasic(true);
    setWorkflowId(data);
  };

  const handleShowTemplateName = () => {
    setShowTemplateName(true);
  };

  const onHide = () => {
    reset();
    setDisplayBasic(false);
    setShowTemplateName(false);
    setLoadingIndicatorButtons(false);
  };

  const accept = (data) => {
    deleteWorkflow(data, accessToken)
      .then((response) => {
        mutateWorkflowList();
      })
      .catch((e) => {
        console.log("e: ", e);
      })
      .finally(() => {
        toast.current.show(
          actionSuccess(dialogMessageList.WorkflowDeleteSuccessful)
        );
      });
  };

  const reject = () => {
    toast.current.show(actionCancel(dialogMessageList.UserCanceled));
  };

  const confirmDelete = (data) => {
    confirmDialog(
      deleteDialog(
        () => accept(data),
        reject,
        dialogMessageList.WorkflowDeletePrompt
      )
    );
  };

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card" id="card">
            <div className="flex justify-content-between">
              <h3 className="m-3 mb-4">Workflow Templates</h3>
              <div className="mt-3">
                <Button
                  label="New Template"
                  icon="pi pi-plus"
                  className="button p-button-icon-left"
                  onClick={() => handleShowTemplateName()}
                />
              </div>
            </div>

            <WorkflowList
              handleShowTemplate={handleShowTemplate}
              confirmDelete={confirmDelete}
            />

            <WorkflowDialog
              displayBasic={displayBasic}
              onHide={onHide}
              workflowId={workflowId}
            />

            <WorkflowTemplateDialog
              showTemplateName={showTemplateName}
              onHide={onHide}
              handleSubmit={handleSubmit(createNewWorkflow)}
              control={control}
              errors={errors}
              setValue={setValue}
              getFormErrorMessage={getFormErrorMessage}
              loadingIndicatorButtons={loadingIndicatorButtons}
            />
          </div>
        </div>
      </div>
    </>
  );
}
