import { classNames } from "primereact/utils";
import { ToggleButton } from "primereact/togglebutton";
import { Divider } from "primereact/divider";

export default function TaskRoute(props) {

  const routeByHandle = (handle) => {
    return props.targetRoutes.find((route) => route.sourceHandle === handle);
  };

  const routeLabel = (handle) => {
    const targetRoute = routeByHandle(handle);
    const targetRouteUserAssignees = targetRoute?.taskAssignee?.name
      ? "by " + targetRoute?.taskAssignee?.name
      : "to " + targetRoute?.targetUsers?.length + " user(s)";

    return targetRoute.label + "\u000A" + targetRouteUserAssignees;
  };

  return (
    <>
      <Divider align="left" type="solid">
        <b>Route Options</b>
      </Divider>

      <span className="p-buttonset flex justify-content-center">
        {routeByHandle("e") && (
          <ToggleButton
            onChange={props.onRoute1Selection}
            className={classNames(
              "w-2",
              props.route1Selection ? "bg-gray-500" : ""
            )}
            checked={props.route1Selection}
            onLabel={routeLabel("e")}
            offLabel={routeLabel("e")}
            id="e"
            onIcon="pi pi-reply"
            offIcon="pi pi-reply"
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}

        {routeByHandle("f") && (
          <ToggleButton
            onChange={props.onRoute2Selection}
            className={classNames(
              "w-2",
              props.route2Selection ? "bg-gray-500" : ""
            )}
            checked={props.route2Selection}
            onLabel={routeLabel("f")}
            offLabel={routeLabel("f")}
            id="f"
            onIcon="pi pi-reply"
            offIcon="pi pi-reply"
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}

        {routeByHandle("g") && (
          <ToggleButton
            onChange={props.onRoute3Selection}
            className={classNames(
              "w-2",
              props.route3Selection ? "bg-gray-500" : ""
            )}
            checked={props.route3Selection}
            onLabel={routeLabel("g")}
            offLabel={routeLabel("g")}
            id="g"
            onIcon="pi pi-reply"
            offIcon="pi pi-reply"
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}

        {routeByHandle("h") && (
          <ToggleButton
            onChange={props.onRoute4Selection}
            className={classNames(
              "w-2",
              props.route4Selection ? "bg-gray-500" : ""
            )}
            checked={props.route4Selection}
            onLabel={routeLabel("h")}
            offLabel={routeLabel("h")}
            id="h"
            onIcon="pi pi-reply"
            offIcon="pi pi-reply"
            style={{ whiteSpace: "pre-wrap" }}
          />
        )}
      </span>
    </>
  );
}
