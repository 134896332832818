import { useState, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import CollaboratorSelection from "../components/CollaboratorSelection";
import useAuth from "../hooks/useAuth";
import {
  DELETE_ACTIVITY_COLLABORATORS,
  CREATE_ACTIVITY_COLLABORATORS,
} from "../graphQL/activities";
import { mutateGraphQL } from "../services/apiGraphQL";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import useUserList from "../hooks/useUserList";
import useActivityDetails from "../hooks/useActivityDetails";
import useCollaborationList from "../hooks/useCollaborationList";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";
import useAssignmentCounts from "../hooks/useAssignmentCounts";

export default function ActivityCollaboratorsDialog(props) {
  const [picklistSourceValue, setPicklistSourceValue] = useState([]);
  const [picklistTargetValue, setPicklistTargetValue] = useState([]);
  const [blockedPanel, setBlockedPanel] = useState(false);

  const [activityName, setActivityName] = useState();
  const { getToken, userId } = useAuth();
  const accessToken = getToken();

  const { userList } = useUserList(accessToken);
  const { activityDetails } = useActivityDetails(
    props.activityId > 0 ? props.activityId : 0,
    accessToken
  );
  const { mutateCollaborationList } = useCollaborationList(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );
  const { mutateAssignmentCounts } = useAssignmentCounts(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  useLayoutEffect(() => {
    setBlockedPanel(true);
    if (activityDetails && props.activityId > 0) {
      getActivityCollaborators();
    }
  }, [activityDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActivityCollaborators = () => {
    setActivityName(activityDetails.name);
    buildUserLists(activityDetails.collaborators);

    setBlockedPanel(false);
  };

  const buildUserLists = (collaborators) => {
    const selectedUserOptions = userList.map(function (user) {
      if (
        collaborators.find((element) => element.collaboratorId === user.code)
      ) {
        return user;
      } else {
        return false;
      }
    }, collaborators);

    setPicklistTargetValue(
      selectedUserOptions.filter(Boolean).sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );

    const userOptions = userList.map(function (user) {
      if (
        collaborators.find((element) => element.collaboratorId === user.code) ||
        activityDetails?.createdById === user.code
      ) {
        return false;
      } else {
        return user;
      }
    }, collaborators);
    setPicklistSourceValue(
      userOptions.filter(Boolean).sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );
  };

  const handleCollaboratorsUpdate = (e) => {
    setPicklistSourceValue(
      e.source.sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );
    setPicklistTargetValue(
      e.target.sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );
  };

  const handleUpdateCollaborators = () => {
    const payload = picklistTargetValue.map(function (targetUser) {
      return {
        id: 0,
        collaboratorId: targetUser.code,
        addedBy: userId,
        activityId: props.activityId,
        createdDate: new Date().toISOString(),
        updatedDate: new Date().toISOString(),
      };
    });

    mutateGraphQL(
      DELETE_ACTIVITY_COLLABORATORS,
      { activityId: props.activityId },
      accessToken
    )
      .then((response) => {
        mutateGraphQL(
          CREATE_ACTIVITY_COLLABORATORS,
          { data: payload },
          accessToken
        )
          .then((response) => {
            mutateCollaborationList();
            mutateAssignmentCounts();
            props.onHide();
          })
          .catch((e) => {
            console.log("e: ", e);
          });
      })
      .catch((e) => {
        console.log("e: ", e);
      })
      .finally(() => {});
  };

  return (
    <>
      <Dialog
        header={<div>{activityName}</div>}
        visible={props.displayActivityCollaboratorsDialog}
        onHide={props.onHide}
        resizable={true}
        draggable={false}
        maximized={true}
        style={{ maxWidth: 900, maxHeight: 605 }}
      >
        <BlockUI blocked={blockedPanel} template={<ProgressSpinner />}>
          {props.displayActivityCollaboratorsDialog && (
            <CollaboratorSelection
              picklistSourceValue={picklistSourceValue}
              picklistTargetValue={picklistTargetValue}
              onCollaboratorsUpdate={handleCollaboratorsUpdate}
            />
          )}
        </BlockUI>
        <div className="flex justify-content-center">
          <div className="flex justify-content-center">
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={props.onHide}
              className="p-button p-button-outlined mr-2"
            />
          </div>
          <div className="flex justify-content-center">
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              className="p-button ml-2"
              loading={props.loadingIndicatorButtons}
              onClick={handleUpdateCollaborators}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
