import { Button } from "primereact/button";

export default function FinalizeTask(props) {
  return (
    <>
      {props.isCurrentUserTaskOwner && (
        <>
          <div className="flex justify-content-center mt-4">
            <Button
              label="Submit"
              icon="pi pi-check"
              disabled={!props.hasSelection}
              onClick={props.onTaskSubmission}
              loading={props.isLoading}
            />
          </div>
        </>
      )}
      {!props.isCurrentUserTaskOwner && (
        <>
          <div className="flex justify-content-center mt-5">
            <p className="font-bold text-pink-500">
              You are not the task assignee.
            </p>
          </div>
          <div className="flex justify-content-center mt-4">
            <p className="font-bold text-pink-500">
              Only the task assignee can complete this task.
            </p>
          </div>
        </>
      )}
    </>
  );
}
