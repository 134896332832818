import { postRequest } from "../common/apiHelper";

import axios from "axios";

function getTasks() {
  return axios.get("assets/test/data/tasks.json").then((res) => {
    return res.data.data;
  });
}

const updateTaskStatus = (id, data, accessToken) => {
  return postRequest("/tasks/" + id + "/status", data, accessToken);
};

const updateTaskData = (id, data, accessToken) => {
  return postRequest("/tasks/" + id + "/data", data, accessToken);
};

const attachTaskFile = (id, data, accessToken) => {
  return postRequest("/tasks/" + id + "/file", data, accessToken);
};

export { getTasks, updateTaskStatus, updateTaskData, attachTaskFile };
