import { gql } from "@apollo/client";

export const GET_USER_WORKFLOWS = gql`
  query ($userId: String) {
    workflowTemplates(where: { createdById: { equals: $userId } }) {
      id
      name
      workflow
      active
      createdBy
      createdById
    }
  }
`;

export const GET_DEPARTMENT_WORKFLOWS = gql`
  query ($departmentId: BigInt) {
    workflowTemplates(
      where: { departmentId: { equals: $departmentId } }
      orderBy: { name: asc }
    ) {
      id
      name
      workflow
      active
      createdBy
      createdById
      createdDate
      departmentId
      updatedBy
      updatedById
      updatedDate
      department {
        name
        code
      }
    }
  }
`;

export const GET_ALL_WORKFLOWS = gql`
  query {
    workflowTemplates(orderBy: { name: asc }) {
      id
      name
      workflow
      active
      createdBy
      updatedBy
      updatedById
      createdDate
      updatedDate
      department {
        id
        name
      }
    }
  }
`;

export const GET_WORKFLOW_DETAILS = gql`
  query ($workflowId: BigInt) {
    workflowTemplates(where: { id: { equals: $workflowId } }) {
      id
      name
      workflow
      active
      createdBy
      createdById
      createdDate
      updatedBy
      updatedById
      updatedDate
      department {
        id
        name
      }
    }
  }
`;
