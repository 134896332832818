const createNode = (key, label, data, icon) => {
  return {
    key: key,
    label: label,
    data: data,
    icon: icon,
    children: [],
    leaf: false,
  };
};

const findAndAppendChildrenNode = (object, key, node) => {
  if (object !== null) {
    if (object.hasOwnProperty(key) && object[key] === node.key) {
      object.children = node.children;
    } else {
      for (var i = 0; i < Object.keys(object).length; i++) {
        if (typeof object[Object.keys(object)[i]] == "object") {
          findAndAppendChildrenNode(object[Object.keys(object)[i]], key, node);
        }
      }
    }
  }
};

const findNodeById = (nodeArray, nodeId) => {
  let targetNode;
  if (nodeArray.length > 0) {
    for (let i = 0; i < nodeArray.length; i++) {
      const node = nodeArray[i];

      if (node.key === nodeId) {
        targetNode = node;
        break;
      } else {
        if (node.children) {
          targetNode = findNodeById(node.children, nodeId);
        }
      }
    }
  }

  return targetNode;
};

const removeChildrenFromNodeExpansionList = (nodeArray, expanded) => {
  if (nodeArray) {
    for (let i = 0; i < nodeArray.length; i++) {
      const node = nodeArray[i];

      if (node.children) {
        removeChildrenFromNodeExpansionList(node.children, expanded);
      }

      const id = node.key;
      if (expanded.hasOwnProperty(id)) {
        delete expanded[id];
      }
    }
  }
};

const getNodeIcon = (nodeName) => {
  let icon = "pi pi-fw pi-folder";

  if (nodeName === "PRIVATE") {
    icon = "pi pi-fw pi-lock";
  } else if (nodeName === "PUBLIC") {
    icon = "pi pi-fw pi-eye";
  } else if (nodeName === "SHARED") {
    icon = "pi pi-fw pi-share-alt";
  } else if (nodeName === "TRASH" || nodeName === "ARCHIVE") {
    icon = "";
  } else if (nodeName === "LINK") {
    icon = "pi pi-fw pi-file";
  }

  return icon;
};

export {
  createNode,
  findAndAppendChildrenNode,
  getNodeIcon,
  findNodeById,
  removeChildrenFromNodeExpansionList,
};
