import React, { useState, useEffect, useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import {
  createFolder,
  getFolderDetails,
  updateFolder,
  deleteFolder,
} from "../services/apiFolders";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Tree } from "primereact/tree";
import useAuth from "../hooks/useAuth";
import {
  createNode,
  findAndAppendChildrenNode,
  getNodeIcon,
  removeChildrenFromNodeExpansionList,
  findNodeById,
} from "../common/treeNodeHelper";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import useFolderList from "../hooks/useFolderList";
import useFolderDetails from "../hooks/useFolderDetails";

import { SelectedFolderContext } from "../contexts/SelectedFolderContext";

export default function FolderNavigator(props) {
  const defaultValues = {
    folderName: "",
    parentFolderName: "",
    parentFolderId: 0,
  };
  const [loadingIndicatorFolders, setLoadingIndicatorFolders] = useState(true);
  const [loadingIndicatorButtons, setLoadingIndicatorButtons] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [currentExpandingNode, setCurrentExpandingNode] = useState({});
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const { getToken, userId } = useAuth();
  const accessToken = getToken();

  const [newFolderFormData, setNewFolderFormData] = useState(defaultValues);
  const [renameFolderFormData, setRenameFolderFormData] =
    useState(defaultValues);
  const [deleteFolderFormData, setDeleteFolderFormData] =
    useState(defaultValues);

  const [displayFolderDialog, setDisplayFolderDialog] = useState(false);
  const [displayNewFolderDialog, setDisplayNewFolderDialog] = useState(false);
  const [displayRenameFolderDialog, setDisplayRenameFolderDialog] =
    useState(false);
  const [displayDeleteFolderDialog, setDisplayDeleteFolderDialog] =
    useState(false);

  const { selectedFolder, setSelectedFolder } = useContext(
    SelectedFolderContext
  );
  const [currentFolderId, setCurrentFolderId] = useState(0);

  const { folderList, mutateFolderList } = useFolderList(accessToken);
  const { folderDetails, mutateFolderDetails } = useFolderDetails(
    currentExpandingNode.key,
    accessToken
  );
  const {
    folderDetails: folderParentDetails,
    mutateFolderDetails: mutateParentFolderDetails,
  } = useFolderDetails(currentFolderId, accessToken);

  const folderMenu1 = useRef(null);
  const folderMenu2 = useRef(null);

  useEffect(() => {
    if (folderList) {
      setLoadingIndicatorFolders(true);
      loadRootFoldersAsync();
    }
  }, [folderList]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadRootFoldersAsync = () => {
    const data = folderList
      .map(function (folder) {
        if (folder.parentId === null) {
          return folder;
        } else {
          return false;
        }
      })
      .filter(Boolean);

    let nodeList = [];
    for (let i = 0; i < data.length; i++) {
      const node = createNode(
        data[i].id,
        data[i].name,
        data[i],
        getNodeIcon(data[i].folderType)
      );

      if (node.label === "PRIVATE" && selectedFolder.key === undefined) {
        setSelectedFolder(node);
        setSelectedNodeKey(node.key);
      } else if (node.key === selectedFolder.key) {
        setSelectedNodeKey(node.key);
      }

      if (
        data[i].name !== "TRASH" &&
        data[i].name !== "ARCHIVE" &&
        data[i].name !== "SHARED"
      ) {
        nodeList.push(node);
      }
    }
    setNodes(nodeList);

    setLoadingIndicatorFolders(false);
  };

  useEffect(() => {
    if (folderDetails) {
      loadChildrenNodes(currentExpandingNode);
    }
  }, [folderDetails, currentExpandingNode]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadChildrenNodes = (currentNode) => {
    if (folderDetails?.children?.length > 0) {
      currentNode.children = [];

      for (let i = 0; i < folderDetails.children.length; i++) {
        currentNode.children.push(
          createNode(
            folderDetails.children[i].id,
            folderDetails.children[i].name,
            folderDetails.children[i],
            getNodeIcon(folderDetails.children[i].folderType)
          )
        );
      }

      let value = [...nodes];

      for (let i = 0; i < value.length; i++) {
        findAndAppendChildrenNode(value[i], "key", currentNode);
      }
      setNodes(value);
    }

    setLoadingIndicatorFolders(false);
  };

  const handleNodeSelect = (node) => {
    setSelectedFolder(node.node);
  };

  const handleNodeExpand = (event) => {
    setLoadingIndicatorFolders(true);
    let currentNode = { ...event.node };

    setCurrentExpandingNode(currentNode);
    setCurrentFolderId(currentNode.key);
    //loadChildrenNodes(event.node.key, currentNode, accessToken);
  };

  const handleNodeCollapse = (event) => {
    if (event.node.children) {
      let expanded = { ...expandedKeys };
      // do recursion here for all children, removing in expanded keys
      removeChildrenFromNodeExpansionList([event.node], expanded);
      setExpandedKeys(expanded);
      // remove all children
      event.node.children = [];
    }
  };

  const handleNodeToggleClick = (event) => {
    setExpandedKeys(event.value); // event.value is node.key
  };

  const nodeTemplate = (node, options) => {
    const folderName = node.label;
    const folderType = node.data.folderType;
    const label = <>{node.label}</>;

    const addOnlyFolderMenuItems = [
      {
        label: "New Folder",
        icon: "pi pi-plus",
        command: (event) => {
          setDisplayNewFolderDialog(true);
          setDisplayFolderDialog(true);
        },
      },
    ];

    const addRenameFolderMenuItems = [
      {
        label: "New Folder",
        icon: "pi pi-plus",
        command: (event) => {
          setDisplayNewFolderDialog(true);
          setDisplayFolderDialog(true);
        },
      },
      {
        label: "Rename Folder",
        icon: "pi pi-pencil",
        command: (event) => {
          setDisplayRenameFolderDialog(true);
          setDisplayFolderDialog(true);
        },
      },
    ];

    return (
      <>
        <span className={options.className}>
          <div className="grid grid-nogutter flex align-items-center justify-content-between">
            {label}
            <>
              {((folderName.toUpperCase() === "PRIVATE" &&
                folderType.toUpperCase() === "PRIVATE") ||
                (folderName.toUpperCase() === "SHARED" &&
                  folderType.toUpperCase() === "SHARED") ||
                (folderName.length > 0 &&
                  folderType.toUpperCase() === "GENERAL")) &&
                !props.readOnlyMode && (
                  <>
                    <Button
                      className="p-button p-button-text"
                      icon="pi pi-ellipsis-v"
                      onClick={(event) => {
                        updateNewFolderFormData(folderName, node.key);
                        updateRenameFolderFormData(
                          folderName,
                          node.key,
                          folderName
                        );
                        updateDeleteFolderFormData(folderName, node.key);

                        if (
                          (folderType.toUpperCase() === "PUBLIC" ||
                            folderType.toUpperCase() === "GENERAL") &&
                          !props.readOnlyMode
                        ) {
                          folderMenu1.current.toggle(event);
                        }

                        if (
                          (folderType.toUpperCase() === "PRIVATE" ||
                            folderType.toUpperCase() === "SHARED") &&
                          !props.readOnlyMode
                        ) {
                          folderMenu2.current.toggle(event);
                        }
                      }}
                    />

                    {(folderType.toUpperCase() === "PUBLIC" ||
                      folderType.toUpperCase() === "GENERAL") &&
                    !props.readOnlyMode ? (
                      <Menu
                        ref={folderMenu1}
                        model={addRenameFolderMenuItems}
                        popup
                      />
                    ) : (folderType.toUpperCase() === "PRIVATE" ||
                        folderType.toUpperCase() === "SHARED") &&
                      !props.readOnlyMode ? (
                      <Menu
                        ref={folderMenu2}
                        model={addOnlyFolderMenuItems}
                        popup
                      />
                    ) : (
                      []
                    )}
                  </>
                )}
            </>
          </div>
        </span>
      </>
    );
  };

  const onHide = () => {
    setDisplayNewFolderDialog(false);
    setDisplayRenameFolderDialog(false);
    setDisplayDeleteFolderDialog(false);
    setDisplayFolderDialog(false);
    setLoadingIndicatorButtons(false);
  };

  const updateNewFolderFormData = (parentFolderName, parentId) => {
    setNewFolderFormData({
      ...newFolderFormData,
      parentFolderName: parentFolderName,
      parentFolderId: parentId ?? 0,
      folderName: "",
    });
  };

  const updateRenameFolderFormData = (
    parentFolderName,
    parentId,
    folderName
  ) => {
    setRenameFolderFormData({
      ...renameFolderFormData,
      parentFolderName: parentFolderName,
      parentFolderId: parentId ?? 0,
      folderName: folderName,
    });
  };

  const updateDeleteFolderFormData = (parentFolderName, parentId) => {
    setDeleteFolderFormData({
      ...deleteFolderFormData,
      parentFolderName: parentFolderName,
      parentFolderId: parentId ?? 0,
    });
  };

  useEffect(() => {
    if (folderParentDetails) {
      loadChildrenNodes(
        createNode(
          folderParentDetails.id,
          folderParentDetails.name,
          folderParentDetails,
          getNodeIcon(folderParentDetails.folderType)
        )
      );
    }
  }, [folderParentDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNewSubmit = (data) => {
    setLoadingIndicatorFolders(true);
    setLoadingIndicatorButtons(true);
    createFolder(
      {
        userId: userId,
        name: data.folderName,
        parentId: newFolderFormData.parentFolderId,
        canBeDeleted: true,
        folderType: "GENERAL",
      },
      accessToken
    )
      .then((data) => {
        setCurrentFolderId(data.parentId);
        mutateParentFolderDetails();
        mutateFolderDetails();
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        onHide();
        reset();
      });
  };

  const onRenameSubmit = (data) => {
    setLoadingIndicatorFolders(true);
    setLoadingIndicatorButtons(true);

    getFolderDetails(renameFolderFormData.parentFolderId, accessToken)
      .then((response) => {
        updateFolder(
          {
            name: data.renameFolderName,
            id: response.id,
            parentId: response.parentId,
            folderType: response.folderType,
            userId: response.userId,
            canBeDeleted: response.canBeDeleted,
          },
          accessToken
        )
          .then((responseData) => {
            if (responseData.parentId !== null) {
              let parentNode = findNodeById(nodes, responseData.parentId);

              let expanded = { ...expandedKeys };
              removeChildrenFromNodeExpansionList(
                parentNode.children,
                expanded
              );
              setExpandedKeys(expanded);

              getFolderDetails(responseData.parentId, accessToken).then(
                (response) => {
                  mutateFolderList();

                  mutateFolderDetails();
                  mutateParentFolderDetails();
                  loadRootFoldersAsync();

                  loadChildrenNodes(currentExpandingNode);
                }
              );
            } else {
              loadRootFoldersAsync();
            }
          })
          .catch((e) => {
            throw e;
          })
          .finally(() => {
            onHide();
            reset();
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  const onDeleteSubmit = (data) => {
    setLoadingIndicatorFolders(true);
    setLoadingIndicatorButtons(true);
    getFolderDetails(deleteFolderFormData.parentFolderId, accessToken)
      .then((data) => {
        deleteFolder(deleteFolderFormData.parentFolderId, accessToken).then(
          (result) => {
            if (deleteFolderFormData.parentFolderId === selectedFolder.key) {
              setSelectedFolder({});
            }

            getFolderDetails(data.parentId, accessToken).then((response) => {
              loadChildrenNodes(
                response.id,
                createNode(
                  response.id,
                  response.name,
                  response,
                  getNodeIcon(response.folderType)
                ),
                accessToken
              );
            });
          }
        );
      })
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        onHide();
        reset();
      });
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const getDialogHeaderText = () => {
    if (displayNewFolderDialog) {
      return "Add New Folder";
    } else if (displayRenameFolderDialog) {
      return "Rename Folder";
    } else if (displayDeleteFolderDialog) {
      return "Delete Folder";
    }
  };

  const getDialogMainSection = () => {
    // do not refactor
    // in case more details needs to be shown depending on operation
    if (displayNewFolderDialog) {
      return (
        <>
          <div className="text-base font-semibold">Parent Folder</div>
          <div className="field">{newFolderFormData.parentFolderName}</div>
        </>
      );
    } else if (displayRenameFolderDialog) {
      return (
        <>
          <div className="text-base font-semibold">Current Folder Name</div>
          <div className="field">{renameFolderFormData.parentFolderName}</div>
        </>
      );
    } else if (displayDeleteFolderDialog) {
      return (
        <>
          <div className="text-base font-semibold">Target Folder</div>
          <div className="field">{deleteFolderFormData.parentFolderName}</div>
        </>
      );
    }
  };

  const getDialogFormFieldsSection = () => {
    if (displayNewFolderDialog) {
      return (
        <>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="folderName"
                control={control}
                rules={{
                  required: "Folder name is required.",
                  validate: (value) => {
                    return !!value.trim() || "Folder name is required.";
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.folderName}
                    {...field}
                    autoFocus
                    className={classNames({
                      "p-invalid": fieldState.error,
                    })}
                  />
                )}
              />
              <label
                htmlFor="folderName"
                className={classNames({ "p-error": errors.name })}
              >
                New folder name *
              </label>
            </span>
            {getFormErrorMessage("folderName")}
          </div>
        </>
      );
    } else if (displayRenameFolderDialog) {
      return (
        <>
          <div className="field">
            <span className="p-float-label">
              <Controller
                name="renameFolderName"
                control={control}
                rules={{
                  required: "New folder name is required.",
                  validate: (value) => {
                    return !!value.trim() || "New folder name is required.";
                  },
                }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.folderName}
                    {...field}
                    autoFocus
                    className={classNames({
                      "p-invalid": fieldState.error,
                    })}
                    value={field.folderName}
                  />
                )}
              />
              <label
                htmlFor="renameFolderName"
                className={classNames({ "p-error": errors.folderName })}
              >
                New folder name *
              </label>
            </span>
            {getFormErrorMessage("renameFolderName")}
          </div>
        </>
      );
    } else if (displayDeleteFolderDialog) {
      return <></>;
    }
  };

  const folderDialog = () => {
    return (
      <>
        <Dialog
          header={getDialogHeaderText}
          visible={displayFolderDialog}
          style={{ width: "350px" }}
          onHide={() => onHide()}
        >
          <div className="mt-2">
            <div className="flex justify-content-between">
              {getDialogMainSection()}
            </div>
            <form
              onSubmit={handleSubmit(
                displayNewFolderDialog
                  ? onNewSubmit
                  : displayRenameFolderDialog
                  ? onRenameSubmit
                  : displayDeleteFolderDialog
                  ? onDeleteSubmit
                  : null
              )}
              className="p-fluid"
            >
              {getDialogFormFieldsSection()}
              <div className="flex justify-content-evenly">
                <div className="flex justify-content-evenly">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => onHide()}
                    className="p-button p-button-outlined"
                    type="button"
                  />
                </div>
                <div className="flex justify-content-evenly">
                  <Button
                    label={displayDeleteFolderDialog ? "Delete" : "Save"}
                    icon={
                      displayDeleteFolderDialog ? "pi pi-trash" : "pi pi-check"
                    }
                    type="submit"
                    className={classNames("p-button", {
                      "p-button-danger": displayDeleteFolderDialog,
                    })}
                    loading={loadingIndicatorButtons}
                  />
                </div>
              </div>
            </form>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {nodes.length > 0 && (
        <Tree
          loading={loadingIndicatorFolders}
          className="border-none p-0"
          contentClassName="p-0"
          value={nodes}
          nodeTemplate={nodeTemplate}
          selectionMode="single"
          selectionKeys={selectedNodeKey}
          onSelectionChange={(e) => setSelectedNodeKey(e.value)}
          onSelect={handleNodeSelect}
          onExpand={handleNodeExpand}
          onCollapse={handleNodeCollapse}
          onToggle={(e) => handleNodeToggleClick(e)}
          expandedKeys={expandedKeys}
        />
      )}
      {folderDialog()}
    </>
  );
}
