import useSWR from "swr";
import { getUser } from "../services/apiUsers";
import { getUserIdClaim, isTokenExpired } from "../common/utilities";

export default function useCurrentUser(accessToken) {
  let tokenState = !isTokenExpired(accessToken);
  let userId;
  if (accessToken && tokenState) {
    userId = getUserIdClaim(accessToken);
  }

  const { data, error, mutate } = useSWR(
    accessToken && userId && tokenState && ["getUser", userId],
    () => getUser(userId, accessToken)
  );

  return {
    userData: data,
    isLoading: !error && !data,
    isError: error,
    mutateUserData: mutate,
  };
}
