import useSWR from "swr";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_ALL_WORKFLOWS } from "../graphQL/workflows";

export default function useWorkflowList(accessToken) {
  const { data, error, mutate } = useSWR(["getWorkflowList"], () =>
    queryGraphQL(GET_ALL_WORKFLOWS, {}, accessToken).then(
      (response) => response.workflowTemplates
    )
  );
  return {
    workflowList: data,
    isLoading: !error && !data,
    isError: error,
    mutateWorkflowList: mutate,
  };
}
