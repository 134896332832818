import useSWR from "swr";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_ACTIVITY_DETAILS } from "../graphQL/activities";

export default function useActivityDetails(activityId, accessToken) {
  const { data, error, mutate } = useSWR(
    activityId && ["getActivityDetails", activityId],
    () =>
      queryGraphQL(
        GET_ACTIVITY_DETAILS,
        { activityId: activityId },
        accessToken
      ).then((response) => response.activities[0])
  );

  return {
    activityDetails: data,
    isLoading: !error && !data,
    isError: error,
    mutateActivityDetails: mutate,
  };
}
