import { gql } from "@apollo/client";

export const GET_USER_TASKS = gql`
  query ($userId: BigInt) {
    tasks(
      where: {
        AND: [
          { assigneeId: { equals: $userId } }
        ]
      }
      orderBy: { createdDate: desc }
    ) {
      id
      name
      customNodeName
      dateRequested
      status
      initiatorUserId
      initiator
      assigneeId
      activity {
        id
        name
        templateId
      }
    }
  }
`;

export const GET_TASK_DETAILS = gql`
  query ($taskId: BigInt) {
    tasks(where: { id: { equals: $taskId } }) {
      id
      name
      customNodeName
      dateRequested
      startTime
      endTime
      nodeId
      status
      initiatorUserId
      assigneeId
      data
      previousTaskId
      previousTaskNode
      nextTaskId
      nextTaskNode
      activity {
        id
        name
        templateId
        workflow
        folderId
        mainFiles {
          id
          name
          folderId
          urlPath
          originalName
        }
      }
      files {
        id
        name
        folderId
        urlPath
        originalName
      }
    }
  }
`;

export const GET_ACTIVITY_TASKS = gql`
  query tasks($activityId: BigInt) {
    tasks(
      where: { activityId: { equals: $activityId } }
      orderBy: { createdDate: desc }
    ) {
      id
      name
      customNodeName
      status
      activityId
      assigneeId
      initiatorUserId
      initiator
      dateRequested
      createdDate
      startTime
      activity {
        id
        name
        templateId
        folderId
      }
    }
  }
`;

export const UPDATE_ACTIVITY_TASKS = gql`
  mutation updateManyTask(
    $data: TaskUpdateManyMutationInput!
    $activityId: BigInt
  ) {
    updateManyTask(
      data: $data
      where: { activityId: { equals: $activityId } }
    ) {
      count
    }
  }
`;

export const UPDATE_TASK_ASSIGNEES = gql`
  mutation updateOneActivity($data: ActivityUpdateInput!, $id: BigInt!) {
    updateOneActivity(data: $data, where: { id: $id }) {
      id
      name
      taskAssignees
    }
  }
`;

export const GET_TASK_ASSIGNEES = gql`
  query ($id: BigInt) {
    activity(where: { id: $id }) {
      taskAssignees
      name
      customNodeName
      workflow
      templateId
      id
    }
  }
`;

export const COUNT_USER_ASSIGNMENTS_INPROGRESS = gql`
  query (
    $userId: BigInt!
    $taskStatus: TaskStatus!
    $activityStatus: ActivityStatus!
    $filter: String
  ) {
    aggregateTask(
      where: {
        AND: {
          assigneeId: { equals: $userId }
          status: { equals: $taskStatus }
        }
      }
    ) {
      _count {
        id
      }
    }
    aggregateActivity(
      where: {
        AND: {
          OR: [
            { alternateOICId: { equals: $userId } }
            { collaborators: { some: { collaboratorId: { in: [$userId] } } } }
            { taskAssignees: { string_contains: $filter } }
          ]
          status: { equals: $activityStatus }
        }
      }
    ) {
      _count {
        id
      }
    }
  }
`;
