import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

export default function ActivityButtons(props) {
  return (
    <>
      <div className="actions flex-nowrap flex justify-content-start flex-shrink-1">
        {(props.activityStatus === "IN_PROGRESS" ||
          props.activityStatus === "COMPLETED" || props.activityStatus ==="CANCELLED") && (
          <>
            <Tooltip target=".p-button-custom" />
            <div className="flex">
              <span
                className="p-button-custom"
                data-pr-tooltip="Activity Dashboard"
                data-pr-position="top"
                data-pr-at="center top-7"
              >
                <Link
                  to={"/tasks/" + props.activityId}
                  className="p-button p-button-icon-only p-button-text"
                >
                  <i className="pi pi-list text-2xl font-light" />
                </Link>
              </span>
            </div>
          </>
        )}

        {(props.activityStatus === "DRAFT" ||
          props.activityStatus === "IN_PROGRESS" ||
          props.activityStatus === "COMPLETED") && (
          <div className="flex">
            <Button
              icon="pi pi-users text-2xl font-light"
              className="p-button p-button-icon-only p-button-text text-pink-500"
              tooltip="Shared To"
              tooltipOptions={{ position: "top" }}
              onClick={props.onShowCollaboratorsDialog}
            />
          </div>
        )}

        {props.activityStatus === "DRAFT" && (
          <div className="flex">
            <Button
              icon="pi pi-play text-2xl font-light"
              className="p-button p-button-icon-only p-button-text text-green-500"
              tooltip="Start Activity"
              tooltipOptions={{ position: "top" }}
              onClick={props.onShowActivityReviewDialog}
            />
          </div>
        )}

        {(props.activityStatus === "DRAFT" ||
          props.activityStatus === "IN_PROGRESS") && (
          <div className="flex">
            <Button
              icon="pi pi-pencil text-2xl font-light"
              className="p-button p-button-icon-only p-button-text text-yellow-500"
              tooltip="Rename"
              tooltipOptions={{ position: "top" }}
              onClick={props.onShowActivityRenameDialog}
            />
          </div>
        )}
        {props.activityStatus === "IN_PROGRESS" &&
          props.taskCount === 1 &&
          props.tasks[0].status === "IN_PROGRESS" && (
            <div className="flex">
              <Button
                icon="pi pi-ban text-2xl font-light"
                className="p-button p-button-icon-only p-button-text"
                tooltip="Cancel Activity"
                tooltipOptions={{ position: "top" }}
                onClick={props.onShowCancelDialog}
              />
            </div>
          )}
      </div>
    </>
  );
}
