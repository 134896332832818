import React from "react";

const AppFooter = () => {
  const appVersion = process.env.REACT_APP_VERSION;

  return (
    <div className="layout-footer">
      <div className="grid">
        <div className="col">
          <div className="layout-footer-appname">DTPMS v{appVersion}</div>
        </div>
        <div className="col col-align-right">
          <span>All Rights Reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
