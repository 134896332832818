import { getRequest, postRequest, putRequest, deleteRequest } from "../common/apiHelper";

const getFolders = (accessToken) => {
  return getRequest("/folders", accessToken);
};

const getFolderDetails = (folderId, accessToken) => {
  return getRequest("/folders/" + folderId, accessToken);
};

const createFolder = (data, accessToken) => {
  return postRequest("/folders", data, accessToken);
};

const updateFolder = (data, accessToken) => {
  return putRequest("/folders", data, accessToken);
};

const deleteFolder = (folderId, accessToken) => {
  return deleteRequest("/folders/" + folderId, accessToken);
};

export { getFolders, getFolderDetails, createFolder, updateFolder, deleteFolder };
