import useSWR from "swr";
import useCurrentUser from "../hooks/useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_ACTIVITY_COLLABORATORS } from "../graphQL/activities";

export default function useCollaborationList(accessToken, filterString) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getActivityCollaborators", userData.id],
    () =>
      queryGraphQL(
        GET_ACTIVITY_COLLABORATORS,
        { userId: userData.id, filter: filterString },
        accessToken
      ).then((response) => response.activities)
  );

  return {
    collaborationList: data,
    isLoading: !error && !data,
    isError: error,
    mutateCollaborationList: mutate,
  };
}
