import React, { useState } from "react";

const RefreshFolderContentContext = React.createContext();

const RefreshFolderContentProvider = (props) => {
  const [shouldRefreshContents, setShouldRefreshContents] = useState({});

  return (
    <RefreshFolderContentContext.Provider
      value={{ shouldRefreshContents, setShouldRefreshContents }}
    >
      {props.children}
    </RefreshFolderContentContext.Provider>
  );
};

export { RefreshFolderContentContext, RefreshFolderContentProvider };
