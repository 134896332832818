import { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import WorkflowButtons from "../components/WorkflowButtons";
import { formatDate } from "../common/utilities";
import useAuth from "../hooks/useAuth";
import useWorkflowList from "../hooks/useWorkflowList";
import useUserList from "../hooks/useUserList";
import { UserSettingsContext } from "../contexts/UserSettingsContext";

export default function WorkflowList(props) {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [filters, setFilters] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const { workflowList, isLoading } = useWorkflowList(accessToken);
  const [updatedWorkflowList, setUpdatedWorkflowList] = useState(null);

  const { userList } = useUserList(accessToken);

  useEffect(() => {
    if (workflowList && userList) {
      resolveUserIds(); // activityTasks

      setFilters(userSettings.filters.workflows);
      setPageSize(userSettings.pageSize);
    }
  }, [workflowList, userList]); // eslint-disable-line react-hooks/exhaustive-deps

  const resolveUserIds = () => {
    const updatedWorkflow = workflowList.map((workflow) => {
      const updatedByUser = userList.find(
        (user) => user.code === parseInt(workflow.updatedById)
      );
      return {
        ...workflow,
        updatedBy: updatedByUser ? updatedByUser.name : "",
      };
    });
    setUpdatedWorkflowList(updatedWorkflow);
  };
  const dateDataFormat = (rowData) => {
    if (rowData.createdDate) {
      return formatDate(new Date(rowData.createdDate));
    }
  };

  const dateModifiedDataFormat = (rowData) => {
    if (rowData.updatedDate) {
      return formatDate(new Date(rowData.updatedDate));
    }
  };

  const workflowButtonsTemplate = (rowData) => {
    return (
      <WorkflowButtons
        workflowId={rowData.id}
        onShowTemplate={() => props.handleShowTemplate(rowData.id)}
        onDelete={() => props.confirmDelete(rowData.id)}
      />
    );
  };

  const handleFilterChange = (newFilters) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      filters: {
        ...userSettings.filters,
        workflows: newFilters.filters,
      },
    }));

    setFilters(newFilters.filters);
  };

  const handlePageChange = ({ first, rows }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      pageSize: rows,
    }));

    setPageSize(rows);
    setOffset(first);
  };

  return (
    <>
      {updatedWorkflowList && (
        <DataTable
          value={updatedWorkflowList}
          paginator
          rows={pageSize}
          first={offset}
          rowsPerPageOptions={[10, 25, 50]}
          className="p-datatable-gridlines"
          showGridlines
          dataKey="id"
          loading={isLoading}
          responsiveLayout="scroll"
          emptyMessage="No workflows found."
          size="small"
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={["name", "department.name"]}
          sortMode="multiple"
          removableSort
          onFilter={handleFilterChange}
          onPage={handlePageChange}
        >
          <Column
            field="name"
            header="Name"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            showFilterMenu={true}
            showFilterOperator={false}
            filter
            filterField="name"
            sortable
            filterPlaceholder="Workflow name"
          />
          <Column
            field="department.name"
            header="Division/Unit"
            filter
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            showFilterMenu={true}
            showFilterOperator={false}
            filterPlaceholder="Division/Unit name"
            sortable
          />
          <Column
            header="Date Created"
            field="createdDate"
            dataType="date"
            bodyClassName="text-center"
            body={dateDataFormat}
            style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
            sortable
          />
          <Column
            header="Last Modified"
            field="modifiedDate"
            dataType="date"
            bodyClassName="text-center"
            body={dateModifiedDataFormat}
            style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
            sortable
          />
          <Column
            header="Modified By"
            field="updatedBy"
            bodyClassName="text-left"
            sortable
          />
          <Column
            body={workflowButtonsTemplate}
            style={{ maxWidth: "8rem", width: "8rem", minWidth: "5rem" }}
            bodyClassName="text-center"
          />
        </DataTable>
      )}
    </>
  );
}
