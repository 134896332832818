import React, { useState } from "react";

const UserSettingsContext = React.createContext();

const UserSettingsContextProvider = (props) => {
  const [userSettings, setUserSettings] = useState({
    filters: {
      activities: {
        status: {
          constraints: [
            {
              matchMode: "equals",
              value: null,
            },
          ],
          operator: "and",
        },
        name: {
          constraints: [
            {
              matchMode: "contains",
              value: null,
            },
          ],
          operator: "and",
        },
      },
      tasks: {
        status: {
          constraints: [
            {
              matchMode: "equals",
              value: null,
            },
          ],
          operator: "and",
        },
      },
      taskLists: {
        displayName: {
          operator: "and",
          constraints: [
            {
              value: null,
              matchMode: "contains",
            },
          ],
        },
        "activity.name": {
          operator: "and",
          constraints: [
            {
              value: null,
              matchMode: "contains",
            },
          ],
        },
        status: {
          operator: "and",
          constraints: [
            {
              value: "IN_PROGRESS",
              matchMode: "equals",
            },
          ],
        },
      },
      collaborations: {
        status: {
          constraints: [
            {
              matchMode: "equals",
              value: "IN_PROGRESS",
            },
          ],
          operator: "and",
        },
        name: {
          constraints: [
            {
              matchMode: "contains",
              value: null,
            },
          ],
          operator: "and",
        },
      },
      workflows: {
        name: {
          constraints: [
            {
              matchMode: "contains",
              value: null,
            },
          ],
          operator: "and",
        },
        "department.name": {
          operator: "and",
          constraints: [
            {
              value: null,
              matchMode: "contains",
            },
          ],
        },
      },
    },
    pageSize: 10,
    dashboardSelectedTabIndex: 0,
  });

  return (
    <UserSettingsContext.Provider value={{ userSettings, setUserSettings }}>
      {props.children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsContext, UserSettingsContextProvider };
