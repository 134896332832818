import { getIdPRequest } from "../common/apiHelper";

const getUsers = (accessToken) => {
  return getIdPRequest("/users", accessToken);
};

const getUser = (id, accessToken) => {
  return getIdPRequest("/users/" + id, accessToken);
};

export { getUser, getUsers };
