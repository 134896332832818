import React, { useState, useEffect } from "react";
import { ListBox } from "primereact/listbox";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import WorkflowCanvas from "../components/WorkflowCanvas";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import useDepartmentWorkflows from "../hooks/useDepartmentWorkflows";

const workflowOption = (workflow) => {
  return { name: workflow.name, code: workflow.id };
};
export default function TemplateSelection(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [blockedPanel, setBlockedPanel] = useState(false);

  const { workflowList: departmentWorkflows } =
    useDepartmentWorkflows(accessToken);

  useEffect(() => {
    if (!props.selectedWorkflow) {
      setBlockedPanel(true);
      props.setValue("activityName", "");
    } else {
      setBlockedPanel(false);
    }
  }, [props.selectedWorkflow]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="mb-3">
        <div className="field col">
          <span className="p-float-label">
            <Controller
              name="activityName"
              control={props.control}
              rules={{
                required: "Activity name is required.",
                validate: (value) => {
                  return !!value.trim() || "Activity name is required.";
                },
              }}
              render={({ field, fieldState }) => (
                <>
                  <InputText
                    id={field.activityName}
                    {...field}
                    className={classNames("w-full", {
                      "p-invalid": fieldState.error,
                    })}
                  />
                </>
              )}
            />
            <label
              htmlFor="activityName"
              className={classNames({ "p-error": props.errors.name })}
            >
              Activity Name *
            </label>
          </span>
          <small id="activityName-help" className="text-blue-500">
            FORMAT:
            <br />
            &lt;UNIT/DIVISION&gt;-&lt;TYPE OF
            DOCUMENT&gt;-&lt;SUBJECT&gt;-&lt;Activity Date(DDMmmYY)&gt;
            <br />
            <br />
            EXAMPLE:
            <br />
            REC-CSW-RecordsOrientation-12Dec22
          </small>
          {props.getFormErrorMessage("activityName")}
        </div>
      </div>
      <div className="mb-3 flex justify-content-between">
        <div className="mr-3">
          {departmentWorkflows && (
            <ListBox
              value={props.selectedWorkflow}
              options={departmentWorkflows.map(workflowOption)}
              onChange={props.onWorkflowTemplateSelection}
              optionLabel="name"
              style={{ maxWidth: "20rem", minWidth: "15rem" }}
              filter
              listStyle={{ maxHeight: "29rem" }}
            />
          )}
        </div>
        <div className="w-screen h-fit">
          <BlockUI
            blocked={blockedPanel}
            template={
              props.selectedWorkflow ? (
                <ProgressSpinner />
              ) : (
                <i
                  className="pi pi-eye-slash text-pink-500"
                  style={{ fontSize: "3rem" }}
                />
              )
            }
          >
            <WorkflowCanvas
              nodes={props.selectedWorkflow ? props.nodes : []}
              edges={props.selectedWorkflow ? props.edges : []}
              allowEdit={false}
              assignUser={false}
              reviewMode={true}
              taskAssignees={props.taskAssignees}
            />
          </BlockUI>
        </div>
      </div>
    </>
  );
}
