import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { signIn } from "../services/apiAuth";
import { useSWRConfig } from "swr";
import {
  getUserIdClaim,
  getUsernameClaim,
  getPermissionsClaim,
  isTokenExpired,
} from "../common/utilities";

const useAuth = () => {
  const { cache } = useSWRConfig();
  const navigate = useNavigate();
  const [user, setUser] = useContext(AuthContext);

  useEffect(() => {
    restoreSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userId]);

  const signInUser = (userCredentials) => {
    cache.clear();
    return signIn(userCredentials)
      .then((response) => {
        decodeAuthToken(response.authToken);
        persistSession(response.authToken);
      })
      .catch((e) => {})
      .finally(() => {});
  };

  const signOutUser = () => {
    localStorage.setItem("userSession", "");

    setUser((user) => ({
      ...user,
      accessToken: "",
      displayName: "",
      emailAddress: "",
      userId: "",
      userName: "",
      idToken: "",
      permissions: [],
    }));

    cache.clear();

    navigate("/login", { replace: true });
  };

  const decodeAuthToken = (authToken) => {
    const userId = getUserIdClaim(authToken);
    const username = getUsernameClaim(authToken);
    const permissions = getPermissionsClaim(authToken);

    setUser((user) => ({
      ...user,
      accessToken: authToken,
      userName: username,
      userId: userId,
      permissions: permissions,
    }));
  };

  const persistSession = (authToken) => {
    localStorage.setItem("userSession", authToken);
  };

  const restoreSession = () => {
    const authToken = localStorage.getItem("userSession");

    if (authToken) {
      try {
        decodeAuthToken(authToken);
      } catch {
        console.log("InvalidTokenError");
      }
    }
  };

  const isSessionValid = () => {
    const expiredToken = isTokenExpired(getToken());
    return user.userId > 0 && !expiredToken;
  };

  const getToken = () => {
    const authToken = localStorage.getItem("userSession");

    if (authToken) {
      return authToken;
    }

    return "";
  };

  return {
    signInUser,
    signOutUser,
    isAuthenticated: isSessionValid(),
    restoreSession,
    getToken,
    userId: user.userId,
  };
};

export default useAuth;
