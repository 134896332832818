import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ActivityButtons from "../components/ActivityButtons";
import { formatDate } from "../common/utilities";
import useAuth from "../hooks/useAuth";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import useActivityList from "../hooks/useActivityList";
import { UserSettingsContext } from "../contexts/UserSettingsContext";

export default function ActivityList(props) {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [loadingIndicatorActivityStarted, setLoadingIndicatorActivityStarted] =
    useState(true);
  const [filters, setFilters] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const { getToken } = useAuth();
  const accessToken = getToken();

  const { activityList: activitiesStarted } = useActivityList(accessToken);

  useEffect(() => {
    if (activitiesStarted) {
      setLoadingIndicatorActivityStarted(false);

      setFilters(userSettings.filters.activities);
      setPageSize(userSettings.pageSize);
    }
  }, [activitiesStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateDataFormat = (rowData) => {
    return formatDate(rowData.createdDate);
  };

  const statusDisplayTemplate = (data) => {
    if (data.status === "IN_PROGRESS") {
      return "IN PROGRESS";
    } else {
      return data.status;
    }
  };

  const finalDecisionTemplate = (data) => {
    // only if activity is CANCELLED or COMPLETED
    if (data.status === "CANCELLED" || data.status === "COMPLETED") {
      // get last task
      if (data.tasks?.length > 0) {
        var lastTask = data.tasks?.reduce(function (max, obj) {
          return obj.id > max.id ? obj : max;
        });

        if (lastTask.status === "REJECTED") {
          return "DISAPPROVED";
        } else {
          return lastTask.status;
        }
      }
    } else {
      return "";
    }
  };

  const activityButtonsTemplate = (rowData) => {
    return (
      <ActivityButtons
        activityStatus={rowData.status}
        activityId={rowData.id}
        taskCount={rowData._count.tasks}
        tasks={rowData.tasks}
        onShowCollaboratorsDialog={() =>
          props.handleShowCollaboratorsDialog(rowData.id)
        }
        onShowActivityReviewDialog={() =>
          props.handleShowActivityReviewDialog(rowData.id)
        }
        onShowActivityRenameDialog={() =>
          props.handleShowActivityRenameDialog(rowData.id, rowData.name)
        }
        onShowActivityTasksDialog={() =>
          props.handleShowActivityTasksDialog(rowData.id, rowData.name)
        }
        onShowCancelDialog={() =>
          props.handleShowCancelDialog(rowData.id, rowData.name)
        }
      />
    );
  };

  const handleFilterChange = (newFilters) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      filters: {
        ...userSettings.filters,
        activities: newFilters.filters,
      },
    }));

    setFilters(newFilters.filters);
  };

  const handlePageChange = ({ first, rows }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      pageSize: rows,
    }));

    setPageSize(rows);
    setOffset(first);
  };

  const statuses = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "DRAFT", value: "DRAFT" },
    { label: "IN PROGRESS", value: "IN_PROGRESS" },
  ];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        className="p-column-filter"
        placeholder="Select a Status"
        value={options.value}
        options={statuses}
        optionValue="value"
        optionLabel="label"
        onChange={(e) => options.filterCallback(e.value)}
        showClear
      />
    );
  };

  const paginatorTemplate = {
    layout: `FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport`,
    CurrentPageReport: (options) => {
      const itemTerm = options.totalRecords > 1 ? "activities" : "activity";
      return (
        <>
          <div className="flex justify-content-end ml-4">
            <span className="font-bold">{options.first}</span>&nbsp;to&nbsp;
            <span className="font-bold">{options.last}</span>&nbsp;of&nbsp;
            <span className="font-bold">{options.totalRecords}</span>
            &nbsp;{itemTerm}.
          </div>
        </>
      );
    },
  };

  return (
    <>
      <Tooltip target=".custom-target-icon" />
      <DataTable
        value={activitiesStarted}
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate={paginatorTemplate}
        paginator
        rows={pageSize}
        first={offset}
        rowsPerPageOptions={[10, 25, 50]}
        className="p-datatable-gridlines"
        showGridlines
        dataKey="id"
        loading={loadingIndicatorActivityStarted}
        responsiveLayout="scroll"
        emptyMessage="No started activities found."
        size="small"
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={["name", "status"]}
        sortMode="multiple"
        removableSort
        rowClassName={(data) => {
          return data.status === "COMPLETED"
            ? "bg-green-75"
            : data.status === "IN_PROGRESS"
            ? "bg-blue-75"
            : data.status === "CANCELLED"
            ? "bg-gray-75"
            : "";
        }}
        onFilter={handleFilterChange}
        onPage={handlePageChange}
      >
        <Column
          field="name"
          header="Activity"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showFilterMenu={true}
          showFilterOperator={false}
          filter
          filterField="name"
          sortable
          filterPlaceholder="Activity name"
        />
        <Column
          header="Date Requested"
          field="createdDate"
          dataType="date"
          bodyClassName="text-center"
          body={dateDataFormat}
          style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
          sortable
        />
        <Column
          header="Creator"
          field="createdBy"
          bodyClassName="text-left"
          sortable
        />
        <Column
          field="status"
          header="Activity Status"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={statusDisplayTemplate}
          filter
          filterElement={statusFilterTemplate}
          style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
          sortable
        />
        <Column
          body={finalDecisionTemplate}
          header="Final Decision"
          style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
        />
        <Column
          body={activityButtonsTemplate}
          bodyClassName="text-center"
          style={{ width: "10rem", minWidth: "5rem" }}
        />
      </DataTable>
    </>
  );
}
