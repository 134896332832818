import React, { useState, useEffect, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import { Editor } from "primereact/editor";
import MessageFeed from "./MessageFeed";
import { addActivityComment } from "../services/apiComments";
import useAuth from "../hooks/useAuth";
import useWindowSize from "../hooks/useWindowSize";
import useActivityComments from "../hooks/useActivityComments";
import { mutateGraphQL } from "../services/apiGraphQL";
import ScrollToBottom from "react-scroll-to-bottom";
import Quill from "quill";
import * as Emoji from "quill-emoji";
import { DELETE_ACTIVITY_COMMENT } from "../graphQL/activities";
import useUserList from "../hooks/useUserList";
import { formatDate } from "../common/utilities";

Quill.register("modules/emoji", Emoji);

export default function CommentFeedLayout(props) {
  const { getToken, userId } = useAuth();
  const accessToken = getToken();
  const [messageText, setMessageText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activityMessages, setActivityMessages] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(formatDate(new Date()));

  const { userList } = useUserList(accessToken);
  const { activityComments, mutateActivityComments } = useActivityComments(
    props.activityId,
    accessToken
  );
  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    setActivityMessages([]);
  }, []);

  useEffect(() => {
    if (activityComments) {
      getActivityComments();
    }
  }, [activityComments]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActivityComments = () => {
    mutateActivityComments();
    setLastUpdate(formatDate(new Date()));

    const activityCommentsList = activityComments.map((comment) => {
      const commentOwner = userList.find(
        (user) => user.code === parseInt(comment.createdById)
      );

      return {
        id: comment.id,
        createdBy: comment.createdBy,
        createdById: comment.createdById,
        commentOwner: commentOwner,
        createdDate: comment.createdDate,
        messageText: comment.message,
        fileName:
          comment.attachments.length > 0 ? comment.attachments[0].name : "",
        fileId: comment.attachments.length > 0 ? comment.attachments[0].id : -1,
      };
    });
    setActivityMessages(activityCommentsList);
  };

  const handleSubmit = () => {
    if (messageText !== null && messageText.trim() !== "") {
      setIsLoading(true);

      addActivityComment(
        props.activityId,
        { message: messageText },
        accessToken
      )
        .then((response) => {
          mutateActivityComments();
          let existingMessages = activityMessages;
          existingMessages.push({
            id: response.id,
            createdBy: response.createdBy,
            createdById: response.createdById,
            createdDate: response.createdDate,
            messageText: response.message,
            fileName: "",
            fileId: -1,
          });

          setActivityMessages(existingMessages);

          setMessageText("");
        })
        .catch((e) => {
          console.log("e: ", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const deleteComment = (messageId) => {
    mutateGraphQL(DELETE_ACTIVITY_COMMENT, { id: messageId }, accessToken)
      .then((response) => {
        const messagesList = activityMessages.map(function (message) {
          if (message.id === this.id) {
            return false;
          } else {
            return message;
          }
        }, response.deleteOneActivityComment);

        setActivityMessages(messagesList.filter(Boolean));
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const header = (
    <>
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>

        <button
          className="ql-list"
          value="ordered"
          aria-label="OrderedList"
        ></button>
        <button
          className="ql-list"
          value="bullet"
          aria-label="OrderedList"
        ></button>
        <button className="ql-blockquote" aria-label="Blockquote"></button>
      </span>
      <span className="ql-formats">
        <button className="ql-emoji" aria-label="Emoji"></button>
        <button className="ql-link" aria-label="Link"></button>
        <button className="ql-image" aria-label="Image"></button>
        <button className="ql-clean" aria-label="Clean"></button>
      </span>
    </>
  );

  return (
    <>
      {windowSize.height && windowSize.width && (
        <div className="flex flex-column justify-content-between h-full">
          <div className="flex justify-content-between">
            <div className="flex align-items-baseline">
              <h3>Comments</h3>
              <Button
                className="pi p-button-icon-only p-button-text pi-refresh bg-transparent"
                onClick={getActivityComments}
              />
            </div>
            <div>{lastUpdate && <p>Last updated: {lastUpdate}</p>}</div>
          </div>
          <div
            className="flex flex-grow-1 justify-content-end w-12"
            style={
              windowSize ? { height: windowSize.height / 2 } : { height: 0 }
            }
          >
            <ScrollToBottom
              mode="bottom"
              initialScrollBehavior="smooth"
              className="w-12"
            >
              <MessageFeed
                messages={activityMessages}
                userId={userId}
                deleteComment={deleteComment}
              />
            </ScrollToBottom>
          </div>

          <div className="flex justify-content-end">
            <div className="ml-3 mt-4" style={{ width: "95%" }}>
              <Editor
                style={{ height: "120px" }}
                value={messageText}
                onTextChange={(e) => setMessageText(e.htmlValue)}
                className="mr-3 w-auto"
                headerTemplate={header}
                modules={{
                  toolbar: {
                    container: [
                      "bold",
                      "italic",
                      "underline",
                      "blockquote",
                      { list: "ordered" },
                      { list: "bullet" },
                      "emoji",
                    ],
                    handlers: { emoji: function () {} },
                  },
                  "emoji-toolbar": true,
                  "emoji-textarea": false,
                  "emoji-shortname": true,
                }}
                formats={[
                  "bold",
                  "italic",
                  "underline",
                  "blockquote",
                  "list",
                  "emoji",
                ]}
              />
              <div className="flex justify-content-between">
                <div className="flex justify-content-start"></div>
                <div className="flex justify-content-end">
                  <Button
                    label="Send"
                    icon="pi pi-envelope"
                    className="my-2 mr-3"
                    onClick={handleSubmit}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
