import { useState, useLayoutEffect } from "react";

export default function TaskAssigneeList(props) {
  useLayoutEffect(() => {
    setNodes(props.nodes);
    setTaskAssignees(props.taskAssignees);
  }, [props]);

  const [nodes, setNodes] = useState(props.nodes);
  const [taskAssignees, setTaskAssignees] = useState(props.taskAssignees);

  return (
    <>
      <h4>Task Assignees</h4>
      {taskAssignees &&
        nodes &&
        taskAssignees.map((nodeAssignee, key) => {
          const targetNode = nodes.find(
            (node) => node.id === nodeAssignee.node.nodeId
          );

          return (
            <div key={key}>
              <div className="flex flex-column mb-2">
                <div className="flex flex-row">
                  <span className="font-bold mb-2">
                    {targetNode &&
                      (targetNode.data.customNodeName
                        ? targetNode.data.customNodeName !== "< Node Name >"
                          ? targetNode.data.customNodeName
                          : targetNode.data.label
                        : targetNode.data.label)}
                  </span>
                </div>
                <span className="ml-4">
                  <div className="flex align-items-center">
                    <div className="grid row mb-2 w-18rem">
                      <div className="col-6">
                        <span className="font-bold text-gray-600">
                          {targetNode &&
                            targetNode.type !== "notificationNode" && (
                              <>Assigned User:&nbsp;</>
                            )}
                          {targetNode &&
                            targetNode.type === "notificationNode" && (
                              <>Target User(s):&nbsp;</>
                            )}
                        </span>
                      </div>
                      <div className="col">
                        {targetNode &&
                          targetNode.type !== "notificationNode" && (
                            <>
                              {nodeAssignee.user.name.length > 0 ? (
                                nodeAssignee.user.name
                              ) : (
                                <span className="text-pink-500">
                                  NONE SELECTED
                                </span>
                              )}
                            </>
                          )}
                        {targetNode && targetNode.type === "notificationNode" && (
                          <>
                            {targetNode &&
                            targetNode.data.targetUsers?.length > 0 ? (
                              targetNode.data.targetUsers.map((user, i) => {
                                return (
                                  <div className="mb-1" key={i}>
                                    {user.name}
                                  </div>
                                );
                              })
                            ) : (
                              <span className="text-pink-500">
                                NONE SELECTED
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          );
        })}
    </>
  );
}
