import { useEffect, useState } from "react";
import WorkflowCanvas from "../components/WorkflowCanvas";
import { useParams } from "react-router-dom";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_WORKFLOW_DETAILS } from "../graphQL/workflows";
import { updateWorkflow } from "../services/apiWorkflow";
import useAuth from "../hooks/useAuth";

import { Inplace, InplaceDisplay, InplaceContent } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
export default function WorkflowDesigner() {
  const { getToken } = useAuth();
  const params = useParams();

  const [text, setText] = useState("");
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [targetWorkflow, setTargetWorkflow] = useState({});
  const accessToken = getToken();
  const [taskAssignees, setTaskAssignees] = useState([]);

  useEffect(() => {
    getWorkflowById(params.workflowId);
  }, [params.workflowId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getWorkflowById = async (workflowId) => {
    queryGraphQL(GET_WORKFLOW_DETAILS, { workflowId: workflowId }, accessToken)
      .then((response) => {
        const workflow = JSON.parse(response.workflowTemplates[0].workflow);

        setTargetWorkflow(response.workflowTemplates[0]);
        setNodes(workflow.nodes);
        setEdges(workflow.edges);
        setText(response.workflowTemplates[0].name);

        let newList = [];
        for (let i = 0; i < workflow.nodes.length; i++) {
          const node = workflow.nodes[i];

          if (node.type !== "terminalNode") {
            newList.push({
              node: {
                nodeId: node.id,
                label: node.data.label,
              },
              user: node.data.taskAssignee
                ? node.data.taskAssignee
                : { name: "", code: 0 },
            });
          }
        }

        setTaskAssignees(newList);
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const handleNameUpdate = () => {
    if (targetWorkflow.name !== text) {
      const renamed = JSON.parse(JSON.stringify(targetWorkflow));

      renamed.name = text;
      delete renamed["createdBy"];
      delete renamed["createdById"];
      delete renamed["updatedBy"];
      delete renamed["updatedById"];
      delete renamed["createdDate"];
      delete renamed["updatedDate"];

      updateWorkflow(renamed, accessToken)
        .then((response) => {
          setTargetWorkflow({ ...targetWorkflow, name: text });
        })
        .catch((e) => {
          console.log("e: ", e);
        });
    }
  };

  const handleAssignUser = (node, selectedUser) => {
    const user = selectedUser ? selectedUser : { name: "", code: 0 };
    const assignee = {
      user: user,
      node: node,
    };

    let isUpdate = false;
    if (taskAssignees.length > 0) {
      let assignees = taskAssignees.map((record) => {
        if (record.node.nodeId === node.nodeId) {
          record.user = user;
          isUpdate = true;
          return record;
        } else {
          return record;
        }
      });

      if (!isUpdate) {
        assignees.push(assignee);
      }

      setTaskAssignees(assignees);
    } else {
      setTaskAssignees([assignee]);
    }
  };
  return (
    <>
      <div className="grid">
        <div className="col-12">
          <div className="card" id="card">
            <div className="flex justify-content-start">
              <div className="ml-2 -mt-1">
                <Inplace closable onClose={handleNameUpdate}>
                  <InplaceDisplay>
                    <div className="flex align-self-center">
                      <span className="text-4xl font-bold">{text}</span>
                      <span className="ml-2 mt-2">
                        <i className="pi pi-pencil"></i>
                      </span>
                    </div>
                  </InplaceDisplay>
                  <InplaceContent>
                    <InputText
                      className="mt-3"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      autoFocus
                    />
                  </InplaceContent>
                </Inplace>
              </div>
            </div>
            <div className="flex justify-content-start">
              <div className="ml-2 -mt-1">
                <h5>{targetWorkflow?.department?.name}</h5>
              </div>
            </div>

            <WorkflowCanvas
              nodes={nodes}
              edges={edges}
              allowEdit={true}
              workflowId={params.workflowId}
              assignUser={true}
              onAssignUserClick={handleAssignUser}
              reviewMode={false}
              taskAssignees={taskAssignees}
            />
          </div>
        </div>
      </div>
    </>
  );
}
