import useSWR from "swr";
import { getUsers } from "../services/apiUsers";
import { formatUserOption, isTokenExpired } from "../common/utilities";

export default function useUserList(accessToken) {
  let tokenState = !isTokenExpired(accessToken);

  const { data, error, mutate } = useSWR(
    accessToken && tokenState && "getUsers",
    () =>
      getUsers(accessToken).then((response) => response.map(formatUserOption))
  );

  return {
    userList: data,
    isLoading: !error && !data,
    isError: error,
    mutateUserList: mutate,
  };
}
