import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Handle,
  Position,
  useReactFlow,
  getConnectedEdges,
} from "react-flow-renderer";
import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { removeNodeWithEdges } from "../common/workflowHelper";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { PickList } from "primereact/picklist";
import useAuth from "../hooks/useAuth";
import useUserList from "../hooks/useUserList";

export default function WorkflowNotificationNode(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [displayAssignee, setDisplayAssignee] = useState(false);
  const [displayRenameNode, setDisplayRenameNode] = useState(false);
  const [targetUsers, setTargetUsers] = useState();
  const [customNodeName, setCustomNodeName] = useState();
  const [picklistSourceValue, setPicklistSourceValue] = useState([]);
  const [picklistTargetValue, setPicklistTargetValue] = useState([]);
  const { userList } = useUserList(accessToken);
  const [extendedUserList, setExtendedUserList] = useState(userList);
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow();

  useEffect(() => {
    const users = [...userList];
    users.sort((a, b) => {
      return a.name - b.name;
    });

    users.splice(0, 0, { name: "< ACTIVITY OWNER >", code: -1 });
    setExtendedUserList(users);
  }, [userList]);

  useLayoutEffect(() => {
    setTargetUsers(props.data.targetUsers);
    setCustomNodeName(props.data.customNodeName);
  }, [props.data.targetUsers, props.data.customNodeName]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === props.id) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.data = {
            ...node.data,
            customNodeName: customNodeName,
            targetUsers: targetUsers,
          };
        }
        return node;
      })
    );
  }, [customNodeName, targetUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUserLists(targetUsers);
  }, [targetUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserLists = (targetUsers) => {
    const selectedUserOptions = extendedUserList.map(function (user) {
      if (targetUsers?.find((element) => element.code === user.code)) {
        return user;
      } else {
        return false;
      }
    }, targetUsers);

    setPicklistTargetValue(selectedUserOptions.filter(Boolean));

    const userOptions = extendedUserList.map(function (user) {
      if (targetUsers?.find((element) => element.code === user.code)) {
        return false;
      } else {
        return user;
      }
    }, targetUsers);

    setPicklistSourceValue(userOptions.filter(Boolean));
  };

  const handleTargetUsersUpdate = (e) => {
    setPicklistSourceValue(e.source);
    setPicklistTargetValue(e.target);
    setTargetUsers(e.target);
  };

  const handleAssignee = () => {
    setDisplayAssignee(true);
  };

  const handleRenameNode = () => {
    setDisplayRenameNode(true);
  };

  const onHide = () => {
    setDisplayAssignee(false);
    setDisplayRenameNode(false);
  };

  const handleRemoveNode = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to remove this step?",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => acceptFunc(),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const edgeList = removeNodeWithEdges(
      nodes,
      edges,
      props.id,
      getConnectedEdges
    );

    setNodes(nodes);
    setEdges(edgeList);
  };

  const rejectFunc = () => {};

  const defaultValues = {
    customNodeName: "< Node Name >",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renameNode = () => {
    const formValue = getValues();
    setCustomNodeName(formValue.customNodeName);
    onHide();
  };

  const onResetNodeNameClick = () => {
    setValue("customNodeName", "< Node Name >");
    setCustomNodeName("< Node Name >");
  };

  return (
    <>
      <Tooltip target=".input-port" />
      <Handle
        type="target"
        position={Position.Top}
        data-pr-tooltip="INPUT"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{ fontSize: "2rem", cursor: "pointer" }}
        className="p-1 input-port"
      />
      <Tooltip target=".output-port-green" />
      <Handle
        type="source"
        position={Position.Bottom}
        className="p-1 output-port-green"
        data-pr-tooltip="OUTPUT"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{ fontSize: "2rem", cursor: "pointer" }}
      />
      <div className="card mb-0 p-2 border-1 border-gray-600 w-15rem">
        <div className="flex justify-content-between">
          <div className="flex justify-content-start"></div>
          <div className="flex justify-content-end mb-2">
            {props.data.assignUser && (
              <>
                {!props.data.reviewMode && (
                  <i
                    className="pi pi-user-edit mr-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={handleAssignee}
                  ></i>
                )}
              </>
            )}
            {props.data.allowEdit && (
              <i
                className="pi pi-pencil mr-2"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleRenameNode}
              ></i>
            )}
            {/* <i
              className="pi pi-cog mr-2"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleConfigureNode}
            ></i> */}
            {props.data.allowEdit && (
              <>
                <i
                  className="pi pi-times"
                  style={{ color: "#f00", cursor: "pointer" }}
                  onClick={handleRemoveNode}
                ></i>
                <ConfirmPopup />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-content-end">
          <label className="text-gray-500 text-sm">{props.data.label}</label>
        </div>
        <div className="flex justify-content-center">
          <label className="mt-3">
            {props.data.customNodeName
              ? props.data.customNodeName
              : "< Node Name >"}
          </label>
        </div>
        <div className="flex justify-content-center">
          <label className="text-green-500">
            {props.data.targetUsers
              ? props.data.targetUsers.length + ` user(s) selected`
              : `0 users selected`}
          </label>
        </div>
      </div>

      <Dialog
        header={props.data.label + " For Target Users"}
        visible={displayAssignee}
        onHide={() => onHide()}
        resizable={true}
        draggable={false}
        maximized={true}
        style={{ maxWidth: 900, maxHeight: 605 }}
      >
        <PickList
          filter
          filterBy="name"
          source={picklistSourceValue}
          target={picklistTargetValue}
          sourceHeader="Available"
          targetHeader="Target Users"
          itemTemplate={(item) => <div>{item.name}</div>}
          onChange={handleTargetUsersUpdate}
          sourceStyle={{ minHeight: "29rem" }}
          targetStyle={{ minHeight: "29rem" }}
          showSourceControls={false}
          showTargetControls={false}
        />
      </Dialog>

      <Dialog
        header={"Rename Node"}
        visible={displayRenameNode}
        onHide={() => onHide()}
      >
        <form onSubmit={handleSubmit(renameNode)}>
          <div className="flex justify-content-between">
            <div className="text-base font-semibold">Current Node Name</div>
            <div className="field">
              {props.data.customNodeName
                ? props.data.customNodeName
                : "< Node Name >"}
            </div>
          </div>
          <br />
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="customNodeName"
                control={control}
                rules={{
                  required: "Node name is required.",
                  validate: (value) => {
                    return !!value.trim() || "Node name is required.";
                  },
                  nameMatches: (value) =>
                    value === "< Node Name >" || "Node name is incorrect.",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="p-inputgroup">
                      <InputText
                        id={field.customNodeName}
                        {...field}
                        className={classNames("w-18rem", {
                          "p-invalid": fieldState.error,
                        })}
                        //value={field.customNodeName}
                      />
                      <Button
                        icon="pi pi-times-circle"
                        type="submit"
                        label="Reset"
                        onClick={() => {
                          onHide();
                          onResetNodeNameClick();
                        }}
                        className="p-button p-button-danger"
                      />
                    </div>
                  </>
                )}
              />
            </span>
            {getFormErrorMessage("customNodeName")}
          </div>
          <div className="flex justify-content-center mt-3">
            <div className="flex justify-content-center">
              <Button
                label="Close"
                icon="pi pi-times"
                onClick={() => onHide()}
                className="p-button p-button-outlined mr-2"
              />
            </div>
            <div className="flex justify-content-center"></div>
            <div className="flex justify-content-center">
              <Button
                label="Set"
                icon="pi pi-check"
                type="submit"
                className="p-button ml-2"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
