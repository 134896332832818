import MessageContent from "../components/MessageContent";
import { classNames } from "primereact";
import { formatDate } from "../common/utilities";

export default function MessageFeed(props) {
  return (
    <>
      {props.messages.map((message, index) => {
        if (message.id) {
          const isMine = parseInt(message.createdById) === props.userId;

          return (
            <div
              className={classNames(
                "my-2 p-2 border-round",
                isMine
                  ? "justify-content-end ml-8 mr-2 bg-green-100"
                  : "justify-content-start mr-8 ml-2 bg-blue-100"
              )}
              key={index}
            >
              <MessageContent
                isMessageMine={isMine}
                displayName={isMine ? "YOU" : message?.commentOwner?.name}
                timeStamp={formatDate(message.createdDate)}
                message={message.messageText}
                messageId={message.id}
                onDelete={props.deleteComment}
                fileId={message.fileId}
                fileName={message.fileName}
              />
            </div>
          );
        } else {
          return '';
        }
      })}
    </>
  );
}
