import useSWR from "swr";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_FOLDER_DETAILS } from "../graphQL/files";

export default function useFolderDetails(folderId, accessToken) {
  const { data, error, mutate } = useSWR(
    folderId && ["getFolderDetails", folderId],
    () =>
      queryGraphQL(
        GET_FOLDER_DETAILS,
        { folderId: folderId },
        accessToken
      ).then((response) => response.folders[0])
  );

  return {
    folderDetails: data,
    isLoading: !error && !data,
    isError: error,
    mutateFolderDetails: mutate,
  };
}
