import React, { useState, useLayoutEffect } from "react";
import WorkflowCanvas from "./WorkflowCanvas";
import { Dialog } from "primereact/dialog";
import useAuth from "../hooks/useAuth";
import useActivityDetails from "../hooks/useActivityDetails";

export default function ActivityWorkflowDialog(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const { activityDetails } = useActivityDetails(
    props.activityId > 0 ? props.activityId : 0,
    accessToken
  );

  useLayoutEffect(() => {
    if (activityDetails && props.activityId > 0) {
      const workflow = JSON.parse(activityDetails.workflow);

      setNodes(workflow.nodes);
      setEdges(workflow.edges);

      let newList = [];
      for (let i = 0; i < workflow.nodes.length; i++) {
        const node = workflow.nodes[i];

        if (node.type !== "terminalNode") {
          newList.push({
            node: {
              nodeId: node.id,
              label: node.data.label,
            },
            user: node.data.taskAssignee
              ? node.data.taskAssignee
              : { name: "", code: 0 },
          });
        }
      }

      setTaskAssignees(newList);
    }
  }, [activityDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dialog
        header={<div>{activityDetails && activityDetails.name}</div>}
        visible={props.displayBasic}
        onHide={props.onHide}
        resizable={true}
        draggable={false}
        maximized={true}
        style={{ maxWidth: 900, maxHeight: 800 }}
      >
        {props.displayBasic && (
          <WorkflowCanvas
            nodes={nodes}
            edges={edges}
            allowEdit={false}
            assignUser={false}
            reviewMode={true}
            taskAssignees={taskAssignees}
          />
        )}
      </Dialog>
    </>
  );
}
