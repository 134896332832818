import React, { useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { deleteMetadata, upsertMetadata } from "../services/apiMetadata";
import { Button } from "primereact/button";
import useAuth from "../hooks/useAuth";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";

export default function KeyValueControl(props) {
  const [keyValuePair, setKeyValuePair] = useState({
    propertyKey: props.propertyKey,
    propertyValue: props.propertyValue,
  });
  const { getToken } = useAuth();
  const accessToken = getToken();
  const { setShouldRefreshContents } = useContext(RefreshFolderContentContext);

  const handleUpdateKVP = () => {
    let metadata = {};

    metadata[`${keyValuePair.propertyKey}`] = keyValuePair.propertyValue;

    deleteMetadata(props.fileId, props.kvpId, accessToken)
      .then(() => {
        upsertMetadata(props.fileId, metadata, accessToken)
          .then(() => {
            setShouldRefreshContents(true);
          })
          .catch((e) => {
            throw e;
          });
      })
      .catch((e) => {
        throw e;
      });
  };

  const handleDelete = (metadataId, fileId) => {
    deleteMetadata(fileId, metadataId, accessToken).then(() => {
      setShouldRefreshContents(true);
    });
  };

  return (
    <>
      <div className="grid">
        {props.readOnlyMode && (
          <>
            <div className="col mb-2">
              <strong>{keyValuePair.propertyKey}</strong>
              <br />
              <label>{keyValuePair.propertyValue}</label>
            </div>
          </>
        )}

        {!props.readOnlyMode && (
          <>
            <div className="col-5">
              <InputText
                autoFocus
                value={keyValuePair.propertyKey}
                onChange={(e) =>
                  setKeyValuePair({
                    ...keyValuePair,
                    propertyKey: e.target.value,
                  })
                }
                className="w-full"
              />
            </div>
            <div className="col-5">
              <InputText
                value={keyValuePair.propertyValue}
                onChange={(e) =>
                  setKeyValuePair({
                    ...keyValuePair,
                    propertyValue: e.target.value,
                  })
                }
                className="w-full"
              />
            </div>

            <div className="col-2">
              <Button
                type="button"
                icon="pi pi-save"
                onClick={() => {
                  handleUpdateKVP();
                }}
                className="p-button-text p-button-lg p-button-icon-only text-green-500"
              />
              <Button
                type="button"
                icon="pi pi-trash"
                onClick={() => {
                  handleDelete(props.kvpId, props.fileId);
                }}
                className="p-button-text p-button-lg p-button-icon-only text-pink-500"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
