import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";

export default function SignaturesAdded(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();

  const { userData } = useCurrentUser(accessToken);
  return (
    <>
      {props.userSignatures?.map((signature, key) => {
        const signatureData = signature.metadata;

        // show only on current page
        if (
          signatureData?.pageNumber === props?.pageNumber &&
          signatureData?.fileName === props?.fileName &&
          signatureData?.activityId === props?.activityId
        ) {
          // handle zoom and image size
          const signatureWidth = signatureData.imageWidth * props.zoomLevel;
          const signatureHeight = signatureData.imageHeight * props.zoomLevel;

          let signatureTop = signatureData.top - signatureData.imageHeight + 4;
          let signatureLeft = signatureData.left + 30 - 6;
          const ratioZoom = props.zoomLevel / signatureData.zoomLevel;
          // handle zoom and image position
          if (props.zoomLevel !== signatureData.zoomLevel) {
            signatureTop *= ratioZoom;
            signatureLeft *= ratioZoom;
          }

          const isOwnSignature =
            props.annotationMode === "SIGNATURE" &&
            userData.id === signatureData.userId &&
            props.taskId === signatureData.taskId;

          const isVisible = () => {
            if (props.taskState === "CLOSED") {
              return props.taskId >= signatureData.taskId; // show signatures from previous tasks as well
            } else {
              return true;
            }
          };

          return (
            <div key={key}>
              {isVisible() && (
                <div
                  style={{
                    top: signatureTop + 19,
                    left: signatureLeft - 1,
                    width: signatureWidth,
                    height: signatureHeight,
                  }}
                  className={"absolute z-5"}
                >
                  <div className="flex">
                    <div className="flex">
                      <img
                        alt=""
                        src={signature.binaryData}
                        width={signatureWidth}
                        height={signatureHeight}
                      ></img>
                    </div>
                    <div
                      className="flex absolute"
                      style={{ top: "-1px", right: "-1px" }}
                    >
                      {isOwnSignature && (
                        <button
                          className="edgebutton bg-orange-500"
                          id={"button-" + signature.metadata.id}
                          type="button"
                          name="deleteSignatureButton"
                        >
                          ×
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        } else {
          return false;
        }
      })}
    </>
  );
}
