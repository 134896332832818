import { classNames } from "primereact/utils";
import { ToggleButton } from "primereact/togglebutton";

export default function TaskDecision(props) {
  return (
    <>
      <span className="p-buttonset flex justify-content-center mt-4">
        <ToggleButton
          className={classNames(
            "w-2",
            props.approveDecision ? "bg-green-500" : ""
          )}
          checked={props.approveDecision}
          onLabel={props.approveLabel}
          offLabel={props.approveLabel}
          onIcon="pi pi-thumbs-up"
          offIcon="pi pi-thumbs-up"
          onChange={props.onApproveDecision}
        />
        {props.showRejectButton && (
          <ToggleButton
            className={classNames(
              "w-2",
              props.rejectDecision ? "bg-pink-500 text-white" : ""
            )}
            checked={props.rejectDecision}
            onLabel="DISAPPROVE"
            offLabel="DISAPPROVE"
            onIcon="pi pi-thumbs-down"
            offIcon="pi pi-thumbs-down"
            onChange={props.onRejectDecision}
          />
        )}
        {props.showReviseButton && (
          <ToggleButton
            className={classNames(
              "w-2",
              props.rejectReviseDecision ? "bg-yellow-500" : ""
            )}
            checked={props.rejectReviseDecision}
            onLabel="FOR REVISION"
            offLabel="FOR REVISION"
            onIcon="pi pi-replay"
            offIcon="pi pi-replay"
            onChange={props.onRevisionDecision}
          />
        )}
        {props.showCancelButton && (
          <ToggleButton
            className={classNames(
              "w-2",
              props.cancelDecision ? "bg-gray-500" : ""
            )}
            checked={props.cancelDecision}
            onLabel="CANCEL"
            offLabel="CANCEL"
            onIcon="pi pi-times"
            offIcon="pi pi-times"
            onChange={props.onCancelDecision}
          />
        )}
      </span>
    </>
  );
}
