import React, { useState, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { confirmDialog } from "primereact/confirmdialog";
import { dialogMessageList, deleteDialog } from "../common/dialogMessages";
import { getBinaryFile, deleteFile } from "../services/apiFiles";
import useAuth from "../hooks/useAuth";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import AddMetadata from "../components/AddMetadata";
import { Tag } from "primereact/tag";
import DocumentNavigator from "../components/DocumentNavigator";
import MetadataUpdater from "../components/MetadataUpdater";
import {
  getIndexOccurrence,
  formatFilename,
  getFilenameDate,
} from "../common/utilities";
import SignaturesAdded from "./SignaturesAdded";

export default function FileListItem(props) {
  const [binaryFile, setBinaryFile] = useState();
  const [visibleTop, setVisibleTop] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const menu = useRef(null);
  const { getToken } = useAuth();
  const accessToken = getToken();
  const { setShouldRefreshContents } = useContext(RefreshFolderContentContext);

  const overlayMenuItems = [
    {
      label: "Share",
      icon: "pi pi-share-alt",
      disabled: true,
    },
    {
      separator: true,
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: (event) => {
        confirmDelete();
      },
    },
  ];

  const loadFile = async (file) => {
    getBinaryFile(file.id, accessToken)
      .then((buffer) => {
        setBinaryFile(buffer.data);
      })
      .catch((e) => {
        throw e;
      });
  };

  const accept = () => {
    // do delete
    deleteFile(props.id, accessToken)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        setShouldRefreshContents(true);
        setNumPages(null);
      });
  };

  const reject = () => {
    setNumPages(null);
  };

  const confirmDelete = () => {
    confirmDialog(
      deleteDialog(accept, reject, dialogMessageList.DocumentDeletePrompt)
    );
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function lastPage() {
    setPageNumber(numPages);
  }

  function firstPage() {
    setPageNumber(1);
  }

  const viewFile = (data) => {
    let signatureData;
    if (props.signatures) {
      signatureData = props.signatures;
    }

    return (
      <>
        <h3>
          {data.name.substring(getIndexOccurrence(data.name, "-", 4) + 1)}
        </h3>
        <DocumentNavigator
          pageNumber={pageNumber}
          firstPage={firstPage}
          previousPage={previousPage}
          numPages={numPages}
          nextPage={nextPage}
          lastPage={lastPage}
        />
        <div className="flex justify-content-center">
          <div className="relative">
            <Document
              file={binaryFile}
              onLoadSuccess={onDocumentLoadSuccess}
              error="Unable to generate thumbnail"
            >
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                error="Unable to generate thumbnail"
                className="mb-5"
                renderMode="canvas"
              />
              {signatureData && (
                <SignaturesAdded
                  className="z-5"
                  userSignatures={signatureData.userSignatures}
                  zoomLevel={1}
                  rotation={0}
                  pageNumber={pageNumber}
                  //pageDimension={pageDimension}
                  annotationMode={"VIEW"}
                />
              )}
            </Document>
          </div>
        </div>

        <DocumentNavigator
          pageNumber={pageNumber}
          firstPage={firstPage}
          previousPage={previousPage}
          numPages={numPages}
          nextPage={nextPage}
          lastPage={lastPage}
        />
      </>
    );
  };

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = binaryFile;
    link.setAttribute(
      "download",
      props.name.substring(getIndexOccurrence(props.name, "-", 4) + 1)
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const customIcons = (
    <React.Fragment>
      <button className="p-sidebar-icon p-link mr-1" onClick={downloadDocument}>
        <span className="pi pi-download" />
      </button>
    </React.Fragment>
  );

  return (
    <>
      {props.displayMode === "list" && (
        <div className="col-12">
          <div className="flex flex-column md:flex-row align-items-center px-3 pb-2 w-full">
            <div className="flex-1 text-center md:text-left">
              <div className="flex align-items-center">
                {!props.readOnlyMode && (
                  <>
                    {/* <FolderSelector targetFileId={props.id} />
                    <i className="pi pi-angle-double-right mr-2"></i> */}
                  </>
                )}
                {props.readOnlyMode && props.showRemoveIcon && (
                  <>
                    <Button
                      icon="pi pi-times"
                      className="p-button-rounded p-button-text mr-2"
                      onClick={() => {
                        props.removeFromList(props);
                      }}
                    />
                  </>
                )}
                <div className="flex w-full justify-content-between">
                  <Button
                    label={formatFilename(props.name)}
                    className="p-button p-button-link mt-2"
                    onClick={() => {
                      loadFile(props);
                      setVisibleTop(true);
                    }}
                  />
                  <p className="flex mt-2">{getFilenameDate(props.name)}</p>
                </div>
              </div>
              <small className="flex justify-content-end">
                {!props.readOnlyMode && (
                  <>
                    {props.metadata.map((metadata, i) => {
                      return (
                        <Tag
                          key={i}
                          className="mr-2 p-tag-gray"
                          value={metadata.value}
                        />
                      );
                    })}
                  </>
                )}
              </small>
            </div>
            <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
              <span className="text-2xl font-semibold align-self-center md:align-self-end">
                {!props.readOnlyMode && (
                  <>
                    <Menu ref={menu} model={overlayMenuItems} popup />
                    <AddMetadata
                      targetFileId={props.id}
                      metadata={props.metadata}
                      fileName={props.name}
                    />
                  </>
                )}

                {props.readOnlyMode && (
                  <>
                    <MetadataUpdater
                      fileName={props.name}
                      metadata={props.metadata}
                      readOnlyMode={props.readOnlyMode}
                    />
                  </>
                )}

                {!props.readOnlyMode && (
                  <>
                    {/* <Button
                      type="button"
                      icon="pi pi-trash"
                      onClick={(target) => {
                        confirmDelete();
                        //toggleMenu(target);
                      }}
                      className="p-button-text p-button p-button-icon-only text-pink-500"
                    /> */}
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* {props.displayMode === "grid" && (
        <div className="ml-4 w-3 min-w-max">
          {props && (
            <div className="card m-2 border-1 surface-border bg-blue-50">
              <div className="text-center">
                <Document
                  file={binaryFile}
                  renderMode="canvas"
                  className={"pdf-grid-view-preview"}
                  error="Unable to generate thumbnail"
                >
                  <Page
                    pageNumber={1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    error="Unable to generate thumbnail"
                  />
                </Document>

                <div className="my-3 overflow-hidden line-height-2 white-space-normal text-overflow-ellipsis">
                  <Button
                    label={props.name}
                    className="p-button-link"
                    onClick={() => {
                      loadFile(props);
                      setVisibleTop(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex align-items-center justify-content-between">
                <span></span>
                <Menu ref={menu} model={overlayMenuItems} popup />
                <Button
                  type="button"
                  icon="pi pi-ellipsis-v"
                  onClick={toggleMenu}
                  style={{ width: "auto" }}
                />
              </div>
            </div>
          )}
        </div>
      )} */}

      <Sidebar
        fullScreen
        visible={visibleTop}
        className="bg-blue-100"
        onHide={() => setVisibleTop(false)}
        icons={customIcons}
        blockScroll
      >
        {visibleTop && viewFile(props)}
      </Sidebar>
    </>
  );
}
