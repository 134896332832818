import React, { useState } from "react";

const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = (props) => {
  const [user, setUser] = useState({
    accessToken: "",
    displayName: "",
    emailAddress: "",
    userId: "",
    userName: "",
    idToken: "",
    permissions: [],
  });

  return (
    <AuthContext.Provider value={[user, setUser]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
