import React, { useState } from "react";

const SelectedFolderContext = React.createContext();

const SelectedFolderProvider = (props) => {
  const [selectedFolder, setSelectedFolder] = useState({});

  return (
    <SelectedFolderContext.Provider
      value={{ selectedFolder, setSelectedFolder }}
    >
      {props.children}
    </SelectedFolderContext.Provider>
  );
};

export { SelectedFolderContext, SelectedFolderProvider };
