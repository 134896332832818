import React, { useState } from "react";
import { classNames } from "primereact/utils";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import KeyValueControl from "../components/KeyValueControl";
import { getIndexOccurrence } from "../common/utilities";

export default function MetadataUpdater(props) {
  const [visibleTop, setVisibleTop] = useState(false);

  const viewMetadata = (props) => {
    return (
      <>
        <h3>
          {props.fileName.substring(
            getIndexOccurrence(props.fileName, "-", 4) + 1
          )}
        </h3>
        <hr className="border-black-alpha-20"></hr>
        {props.readOnlyMode && (
          <>
            <div className="grid mb-3">
              <div className="col">
                <strong>File Metadata</strong>
              </div>
            </div>
          </>
        )}
        {!props.readOnlyMode && (
          <>
            <div className="grid mb-3">
              <div className="col-5">
                <strong>Metadata</strong>
              </div>
              <div className="col-5">
                <strong>Value</strong>
              </div>
              <div className="col-2"></div>
            </div>
          </>
        )}
        <hr className="border-black-alpha-20"></hr>
        {props.metadata.map((kvp, i) => {
          return (
            <KeyValueControl
              key={i}
              propertyKey={kvp.key}
              propertyValue={kvp.value}
              fileId={kvp.fileId}
              kvpId={kvp.id}
              readOnlyMode={props.readOnlyMode}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <Button
        type="button"
        label={props.readOnlyMode ? "" : "Manage Metadata"}
        className="p-button p-button-text"
        icon="pi pi-chart-bar rotate-90"
        onClick={() => {
          setVisibleTop(true);
        }}
      />

      <Sidebar
        position="right"
        visible={visibleTop}
        className={classNames("bg-blue-100", {
          "p-sidebar-lg": !props.readOnlyMode,
        })}
        onHide={() => setVisibleTop(false)}
      >
        {visibleTop && viewMetadata(props)}
      </Sidebar>
    </>
  );
}
