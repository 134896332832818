import { postRequest } from "../common/apiHelper";

const fetchLocalActivities = () => {
  return fetch("/assets/test/data/activities.json")
    .then((res) => res.json())
    .then((d) => d.data);
};

const updateActivityStatus = (id, data, accessToken) => {
  return postRequest("/activities/" + id + "/status", data, accessToken);
};

const saveDraftActivity = (data, accessToken) => {
  return postRequest("/activities", data, accessToken);
};

export {
  fetchLocalActivities,
  updateActivityStatus,
  saveDraftActivity,
};
