import { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Menu } from "primereact/menu";
import { Divider } from "primereact/divider";

export default function DocumentToolbar(props) {
  const [signatureMode, setSignatureMode] = useState();

  useEffect(() => {
    if (props.annotationMode === "TEXT") {
      setSignatureMode(false);
    } else if (props.annotationMode === "SIGNATURE") {
      setSignatureMode(true);
    } else if (props.annotationMode === "VIEW") {
      setSignatureMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.annotationMode]);

  return (
    <>
      <span className="p-buttonset flex align-items-start align-items-center">
        <span className="flex align-self-center justify-content-end mx-1 border-1 p-2 border-round border-400 w-4rem">
          {props.zoomLevel * 100}%
        </span>
        <Button icon="pi pi-search-plus" onClick={props.onZoomIn} />
        <Button icon="pi pi-search-minus" onClick={props.onZoomOut} />
        <Button
          icon="pi pi-chevron-down"
          onClick={(event) => props.menu.current.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
        />
        <Menu model={props.zoomLevels} popup ref={props.menu} id="popup_menu" />
      </span>
      {props.showSignatureButtons && (
        <>
          <Divider layout="vertical"></Divider>
          <span className="p-buttonset flex align-items-start align-items-center">
            <Button
              label={"Sign"}
              onClick={() => props.handleAnnotationMode("SIGNATURE")}
              className={classNames(signatureMode ? "p-button-warning" : "")}
              icon="pi pi-user-edit"
            />
            <FileUpload
              mode="basic"
              ref={props.uploadRef}
              accept="image/*"
              customUpload
              uploadHandler={props.customBase64Uploader}
              auto
              chooseOptions={{
                label: props.hasSignatureImage ? "Selected" : "Browse",
                icon: props.hasSignatureImage ? "pi pi-check" : "pi pi-upload",
              }}
            />
            <Button
              icon="pi pi-times"
              label={"Clear"}
              onClick={props.clearSignature}
            />
          </span>
        </>
      )}
      <Divider layout="vertical"></Divider>
      <span className="flex align-items-start align-items-center">
        <Button
          onClick={props.handleDownload}
          className="p-button-icon-only"
          icon="pi pi-download"
        />
      </span>
    </>
  );
}
