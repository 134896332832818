import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Password } from "primereact/password";
import { Image } from "primereact/image";
import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

const userCredentialsModel = {
  companyId: parseInt(process.env.REACT_APP_IDP_COMPANY_ID),
  grantType: process.env.REACT_APP_IDP_GRANT_TYPE,
  username: "",
  password: "",
};

export default function Login() {
  const [loggingIn, setLoggingIn] = useState(false);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const { signInUser, restoreSession, isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";

  const [userCredentials, setUserCredentials] = useState(userCredentialsModel);

  const blockPanel = () => {
    setBlockedPanel(true);
  };

  const unblockPanel = () => {
    setBlockedPanel(false);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      restoreSession();
    } else {
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const signIn = (e) => {
    e.preventDefault();

    setLoggingIn(true);
    blockPanel();
    signInUser(userCredentials);

    setTimeout(() => {
      setLoggingIn(false);
      unblockPanel();

      navigate(from, { replace: true });
    }, 2000);
  };

  return (
    <BlockUI blocked={blockedPanel} template={<ProgressSpinner />}>
      <Tooltip target=".p-custom-tooltip" />
      <div className="login-body bg-primary">
        <div className="login-panel ui-fluid">
          <div className="flex justify-content-center my-1">
            <Image
              src="/assets/layout/images/ddb-logo.png"
              alt="Image"
              width="100"
              height="100"
              className="-mb-2"
            />
          </div>
          <div className="login-panel-content">
            <form onSubmit={signIn}>
              <div className="grid">
                <div className="col-12"></div>
                <div className="col-12">
                  <h1>Log In</h1>
                  <h2>Sign in to start your session</h2>
                </div>

                <div className="col-12 -mb-3">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="username" className="text-color">
                      Username
                    </label>
                    <InputText
                      id="username"
                      type="text"
                      style={{ width: "100%" }}
                      v-model="username"
                      value={userCredentials.username}
                      onChange={(e) =>
                        setUserCredentials({
                          ...userCredentials,
                          username: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 -mb-3">
                  <div className="flex flex-column gap-2">
                    <label htmlFor="password" className="text-color">
                      Password
                    </label>
                    <Password
                      id="password"
                      type="password"
                      style={{ width: "100%" }}
                      v-model="password"
                      value={userCredentials.password}
                      onChange={(e) =>
                        setUserCredentials({
                          ...userCredentials,
                          password: e.target.value,
                        })
                      }
                      toggleMask
                      pt={{
                        input: {
                          style: { width: "100%" },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="flex justify-content-between align-items-center">
                    <Button label="Sign In" loading={loggingIn} type="submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BlockUI>
  );
}
