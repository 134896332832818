import { PickList } from "primereact/picklist";

export default function CollaboratorSelection(props) {
  return (
    <>
      <div className="mb-3">
        <PickList
          source={props.picklistSourceValue}
          target={props.picklistTargetValue}
          sourceHeader="Available"
          targetHeader="Shared To"
          itemTemplate={(item) => <div>{item.name}</div>}
          onChange={props.onCollaboratorsUpdate}
          sourceStyle={{ minHeight: "29rem" }}
          targetStyle={{ minHeight: "29rem" }}
          showSourceControls={false}
          showTargetControls={false}
          filterBy="name"
        />
      </div>
    </>
  );
}
