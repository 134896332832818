import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserIdClaim, isTokenExpired } from "../common/utilities";

export default function RequireAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const state = { from: location };
  const authToken = localStorage.getItem("userSession");

  let isLoggedIn = false;
  if (authToken) {
    isLoggedIn = getUserIdClaim(authToken) > 0 && !isTokenExpired(authToken);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      navigate("/login", { state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return children;
}
