import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AppLayout from "../app/AppLayout";

import HomePage from "../pages/Home";
import TaskViewer from "../pages/TaskViewer";
import ErrorPage from "../pages/Error";
import LoginPage from "../pages/Login";
import FilesPage from "../pages/Files";
import WorkflowPage from "../pages/Workflow";
import WorkflowDesignerPage from "../pages/WorkflowDesigner";
import ActivityWizardPage from "../pages/ActivityWizard";
import TasksPage from "../pages/Tasks";
import HelpPage from "../pages/Help";

import { AuthProvider } from "../contexts/AuthContext";
import RequireAuth from "../components/RequireAuth";
import RequireAdminPermission from "../components/RequireAdminPermission";

function App() {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route
            index
            element={
              <RequireAuth>
                <HomePage />
              </RequireAuth>
            }
          />
          <Route
            path="/task/:taskId"
            element={
              <RequireAuth>
                <TaskViewer />
              </RequireAuth>
            }
          />
          <Route
            path="/tasks/:activityId"
            element={
              <RequireAuth>
                <TasksPage />
              </RequireAuth>
            }
          />

          <Route
            path="/files"
            element={
              <RequireAuth>
                <FilesPage />
              </RequireAuth>
            }
          />

          <Route
            path="/workflows"
            element={
              <RequireAdminPermission>
                <WorkflowPage />
              </RequireAdminPermission>
            }
          />

          <Route
            path="/designer/:workflowId"
            element={
              <RequireAuth>
                <WorkflowDesignerPage />
              </RequireAuth>
            }
          />

          <Route
            path="/wizard"
            element={
              <RequireAuth>
                <ActivityWizardPage />
              </RequireAuth>
            }
          />
          <Route path="/help" element={<HelpPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
