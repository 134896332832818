import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import { getDepartments } from "../services/apiDepartments";

export default function WorkflowTemplateDialog(props) {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const { getToken } = useAuth();
  const accessToken = getToken();

  useEffect(() => {
    getDepartments(accessToken)
      .then((response) => {
        setDepartments(response);
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDepartmentSelection = (e) => {
    props.setValue(e.target.id, e.value.id);
    setSelectedDepartment(e.value);
  };
  return (
    <>
      <Dialog
        header={"New Workflow Template"}
        visible={props.showTemplateName}
        onHide={props.onHide}
        resizable={false}
        draggable={true}
        maximized={false}
        style={{ maxWidth: 900, maxHeight: 800 }}
      >
        {props.showTemplateName && (
          <form onSubmit={props.handleSubmit}>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="workflowName"
                  control={props.control}
                  rules={{
                    required: "Template name is required.",
                    validate: (value) => {
                      return !!value.trim() || "Template name is required.";
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.workflowName}
                      {...field}
                      className={classNames("w-18rem", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="workflowName"
                  className={classNames({ "p-error": props.errors.name })}
                >
                  Template Name *
                </label>
              </span>
              {props.getFormErrorMessage("workflowName")}
            </div>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="departmentId"
                  control={props.control}
                  rules={{
                    required: "Department is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Dropdown
                        className="w-full"
                        id="departmentId"
                        value={selectedDepartment}
                        onChange={handleDepartmentSelection}
                        options={departments}
                        optionLabel="name"
                        showClear
                      />
                      <label htmlFor="departmentId">Department</label>
                    </>
                  )}
                />
              </span>
              {props.getFormErrorMessage("departmentId")}
            </div>
            <div className="flex justify-content-evenly">
              <div className="flex justify-content-evenly">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={props.onHide}
                  className="p-button p-button-outlined"
                />
              </div>
              <div className="flex justify-content-evenly">
                <Button
                  label="Save"
                  icon="pi pi-check"
                  type="submit"
                  className="p-button"
                  loading={props.loadingIndicatorButtons}
                />
              </div>
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
}
