import {
  useState,
  useRef,
  useCallback,
  useContext,
  useLayoutEffect,
} from "react";
import { Toolbar } from "primereact/toolbar";
import DocumentNavigator from "../components/DocumentNavigator";
import DocumentToolbar from "../components/DocumentToolbar";
import { ScrollPanel } from "primereact/scrollpanel";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import useEventListener from "../hooks/useEventListener";
import { getUUID } from "../common/utilities";
import { SignatureContext } from "../contexts/SignatureContext";
import SignaturesAdded from "../components/SignaturesAdded";
import { getIndexOccurrence } from "../common/utilities";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { PDFDocument } from "pdf-lib";

export default function DocumentViewer(props) {
  const [blockedPanel, setBlockedPanel] = useState(true);
  const [binaryFile, setBinaryFile] = useState(props.file);
  const [numPages, setNumPages] = useState(null);
  const [fileName, setFileName] = useState(props.fileName);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const [rotation, setRotation] = useState(0);
  const [pageDimension, setPageDimension] = useState({ width: 0, height: 0 });
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [annotationMode, setAnnotationMode] = useState("VIEW");
  const [hasSignatureImage, setHasSignatureImage] = useState(false);
  const [imageData, setImageData] = useState({});
  const { userSignatures, setUserSignatures } = useContext(SignatureContext);

  const targetElement = useRef(null);
  const signRef = useRef(null);
  const menu = useRef(null);
  const uploadRef = useRef(null);

  const { getToken } = useAuth();
  const accessToken = getToken();

  const { userData } = useCurrentUser(accessToken);

  const image = document.createElement("img");
  image.src = imageData;

  useLayoutEffect(() => {
    setBinaryFile(props.file);
    setBlockedPanel(true);
    setFileName(props.fileName);
  }, [props.file, props.fileName]);

  const zoomLevels = [
    {
      label: "60%",
      command: (e) => {
        setZoomLevel(0.6);
      },
    },
    {
      label: "80%",
      command: (e) => {
        setZoomLevel(0.8);
      },
    },
    {
      label: "100%",
      command: (e) => {
        setZoomLevel(1.0);
      },
    },
    {
      label: "120%",
      command: (e) => {
        setZoomLevel(1.2);
      },
    },
    {
      label: "140%",
      command: (e) => {
        setZoomLevel(1.4);
      },
    },
    {
      label: "160%",
      command: (e) => {
        setZoomLevel(1.6);
      },
    },
    {
      label: "180%",
      command: (e) => {
        setZoomLevel(1.8);
      },
    },
    {
      label: "200%",
      command: (e) => {
        setZoomLevel(2.0);
      },
    },
  ];

  function onLoadSuccess({ numPages }) {
    setNumPages(numPages);

    if (!userSignatures) {
      setUserSignatures([]);
    }
    setBlockedPanel(false);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function lastPage() {
    setPageNumber(numPages);
  }

  function firstPage() {
    setPageNumber(1);
  }

  const handleRotateRight = () => {
    let rotationValue = rotation;
    rotationValue += 90;

    if (rotationValue >= 360) {
      rotationValue = 0;
    }

    setRotation(rotationValue);
  };

  const handleRotateLeft = () => {
    let rotationValue = rotation;
    rotationValue -= 90;

    if (rotationValue < 0) {
      rotationValue = 270;
    }

    setRotation(rotationValue);
  };

  const handleZoomIn = () => {
    let zoom = zoomLevel;
    zoom += 0.2;

    if (zoom > 2) {
      zoom = 2;
    }
    setZoomLevel(parseFloat(zoom.toFixed(2)));
    setAnnotationMode("VIEW");
    setSignatureImageZoomAttribute(parseFloat(zoom.toFixed(2)));
  };

  const handleZoomOut = () => {
    let zoom = zoomLevel;
    zoom -= 0.2;

    if (zoom < 0.6) {
      zoom = 0.6;
    }
    setZoomLevel(parseFloat(zoom.toFixed(2)));
    setAnnotationMode("VIEW");
    setSignatureImageZoomAttribute(parseFloat(zoom.toFixed(2)));
  };

  const onRenderSuccess = (page) => {
    setPageDimension({ width: page.width, height: page.height });
  };

  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event.files[0];
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      setHasSignatureImage(true);
      setImageData(base64data);
    };
  };

  const clearSignature = () => {
    setHasSignatureImage(false);
    setImageData();
    uploadRef.current.clear();
  };

  const setSignatureImageZoomAttribute = (currentZoomLevel) => {
    signRef.current.setAttribute(
      "height",
      image.naturalHeight * currentZoomLevel
    );
    signRef.current.setAttribute(
      "width",
      image.naturalWidth * currentZoomLevel
    );
  };

  const mouseMoveHandler = useCallback(
    (data) => {
      setCoords({ x: data.offsetX, y: data.offsetY });
      if (annotationMode === "SIGNATURE") {
        if (data.target.className === "react-pdf__Page__canvas") {
          if (imageData) {
            signRef.current.setAttribute("class", "absolute z-1");
            signRef.current.setAttribute("src", imageData);

            setSignatureImageZoomAttribute(zoomLevel);
          } else {
            signRef.current.setAttribute("height", 0);
            signRef.current.setAttribute("width", 0);
            signRef.current.setAttribute("class", "absolute z-0");
          }
        } else if (
          data.target.name === "deleteSignatureButton" ||
          data.target.name === ""
        ) {
          signRef.current.setAttribute("height", 0);
          signRef.current.setAttribute("width", 0);
          signRef.current.setAttribute("class", "absolute z-0");
        }
      } else {
        signRef.current.setAttribute("height", 0);
        signRef.current.setAttribute("width", 0);
        signRef.current.setAttribute("class", "absolute z-0");
      }
    },
    [setCoords, annotationMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const mouseUpHandler = useCallback(
    (data) => {
      if (annotationMode === "SIGNATURE" && hasSignatureImage) {
        setCoords({ x: data.offsetX, y: data.offsetY });
        if (data.target.className === "react-pdf__Page__canvas") {
          const signaturePlacement = {
            top: data.offsetY, // vertical coordinates of image's top left corner placement
            left: data.offsetX, // horizontal coordinates of image's top left corner placement
            imageHeight: image.naturalHeight, // image height
            imageWidth: image.naturalWidth, // image width
            pageHeight: pageDimension.height,
            pageWidth: pageDimension.width,
            pageNumber: pageNumber,
            zoomLevel: zoomLevel,
            pageRotation: rotation,
            objectType: "signature",
            userId: userData.id,
            taskId: props.taskId,
            activityId: props.activityId,
            fileName: fileName,
            id: getUUID(), //unique id
          };

          const signatures = userSignatures;
          signatures.push({
            metadata: signaturePlacement,
            binaryData: imageData,
            order: Date.now(),
          });
          setUserSignatures(signatures);

          setAnnotationMode("VIEW");
          // clean up
          image.remove();
        } else if (data.target.name === "deleteSignatureButton") {
          const signatures = userSignatures;

          const index = signatures
            ?.map(function (sig) {
              return sig.metadata.id;
            })
            .indexOf(
              data.target.id.substring(
                getIndexOccurrence(data.target.id, "-", 1) + 1
              )
            );
          signatures.splice(index, 1);
          setUserSignatures(signatures);
        } else {
        }
        //setCoords({ x: data.offsetX, y: data.offsetY });
      }
    },
    [pageDimension, setCoords, annotationMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEventListener("mouseup", mouseUpHandler, targetElement.current);
  useEventListener("mousemove", mouseMoveHandler, targetElement.current);

  const embedUserSignatures = async () => {
    //convert PDF blob to arrayBuffer
    const arrayBufferFile = await fetch(binaryFile).then((res) =>
      res.arrayBuffer()
    );

    // load PDF
    const pdfDoc = await PDFDocument.load(arrayBufferFile);
    const pages = pdfDoc.getPages();

    for (let index = 0; index < pages.length; index++) {
      const page = pages[index];

      const signatureData = userSignatures.map((signature) => {
        if (
          signature.metadata.pageNumber === index + 1 &&
          signature.metadata.fileName === props.fileName
        ) {
          return signature;
        }
        return false;
      });
      const signatureDataList = signatureData.filter(Boolean);

      for (let index = 0; index < signatureDataList.length; index++) {
        const signatureInfo = signatureDataList[index];

        const sigImage = await pdfDoc.embedPng(signatureInfo.binaryData);

        page.drawImage(sigImage, {
          x: signatureInfo.metadata.left + 24,
          y:
            signatureInfo.metadata.pageHeight - signatureInfo.metadata.top - 22,
          width: signatureInfo.metadata.imageWidth,
          height: signatureInfo.metadata.imageHeight,
          opacity: 1,
        });
      }
    }

    const pdfBinary = await pdfDoc.save();

    let blob = new window.Blob([pdfBinary], { type: "application/pdf" });
    const newPdfBinary = window.URL.createObjectURL(blob);

    return newPdfBinary;
  };

  const downloadDocument = async () => {
    const link = document.createElement("a");
    link.href = await embedUserSignatures();
    link.setAttribute(
      "download",
      props.fileName.substring(getIndexOccurrence(props.fileName, "-", 4) + 1)
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const leftContents = (
    <>
      <DocumentToolbar
        zoomLevel={zoomLevel}
        zoomLevels={zoomLevels}
        menu={menu}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onRotateRight={handleRotateRight}
        onRotateLeft={handleRotateLeft}
        handleAnnotationMode={(mode) => {
          if (
            (annotationMode !== "TEXT" || annotationMode !== "SIGNATURE") &&
            mode !== annotationMode
          ) {
            setAnnotationMode(mode);
          } else {
            setAnnotationMode("VIEW");
          }
        }}
        annotationMode={annotationMode}
        uploadRef={uploadRef}
        customBase64Uploader={customBase64Uploader}
        hasSignatureImage={hasSignatureImage}
        clearSignature={clearSignature}
        showSignatureButtons={
          props.isCurrentUserTaskOwner && props.taskState !== "CLOSED"
        }
        handleDownload={downloadDocument}
      />
    </>
  );

  const rightContents = (
    <>
      <DocumentNavigator
        pageNumber={pageNumber}
        firstPage={firstPage}
        previousPage={previousPage}
        numPages={numPages}
        nextPage={nextPage}
        lastPage={lastPage}
      />
    </>
  );

  return (
    <>
      <BlockUI blocked={blockedPanel} template={<ProgressSpinner />}>
        <Toolbar
          left={leftContents}
          right={rightContents}
          className="p-1 px-2 mb-2"
        />
        {/* {coords.x},{coords.y} */}
        <div className="flex justify-content-center">
          <ScrollPanel
            style={{ width: "100%", height: "780px" }}
            className="customScrollbar bg-blue-100"
          >
            <div className="flex justify-content-start">
              <img
                alt=""
                ref={signRef}
                src={imageData}
                className="absolute z-0"
                style={{
                  top: coords.y - image.naturalHeight + 30,
                  left: coords.x + 30,
                }}
                width={
                  isNaN(parseInt(image.naturalWidth * zoomLevel))
                    ? 0
                    : image.naturalWidth * zoomLevel
                }
                height={
                  isNaN(parseInt(image.naturalHeight * zoomLevel))
                    ? 0
                    : image.naturalHeight * zoomLevel
                }
              ></img>
              <Document file={binaryFile} onLoadSuccess={onLoadSuccess}>
                <Page
                  inputRef={targetElement}
                  rotate={rotation}
                  renderMode="canvas"
                  scale={zoomLevel}
                  className="mt-2 ml-2"
                  pageNumber={pageNumber}
                  renderAnnotationLayer={true}
                  renderTextLayer={false}
                  onRenderSuccess={onRenderSuccess}
                >
                  {fileName && userSignatures && (
                    <SignaturesAdded
                      className="z-5"
                      userSignatures={userSignatures}
                      zoomLevel={zoomLevel}
                      rotation={rotation}
                      pageNumber={pageNumber}
                      pageDimension={pageDimension}
                      annotationMode={annotationMode}
                      taskId={props.taskId}
                      fileName={fileName}
                      taskState={props.taskState}
                      activityId={props.activityId}
                    />
                  )}
                </Page>
              </Document>
            </div>
          </ScrollPanel>
        </div>
      </BlockUI>
    </>
  );
}
