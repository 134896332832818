import { deleteRequest, postRequest, putRequest } from "../common/apiHelper";

const deleteWorkflow = (id, accessToken) => {
  return deleteRequest("/workflows/" + id, accessToken);
};

const createWorkflow = (data, accessToken) => {
  return postRequest("/workflows", data, accessToken);
};

const updateWorkflow = (data, accessToken) => {
  return putRequest("/workflows", data, accessToken);
};

const fetchLocalWorkflow = () => {
  return fetch("/assets/test/data/workflows.json")
    .then((res) => res.json())
    .then((d) => d.data);
};

export { deleteWorkflow, createWorkflow, updateWorkflow, fetchLocalWorkflow };
