import useSWR from "swr";
import useCurrentUser from "../hooks/useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_USER_ACTIVITIES } from "../graphQL/activities";

export default function useActivityList(accessToken) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getUserActivities", userData.id],
    () =>
      queryGraphQL(
        GET_USER_ACTIVITIES,
        { userId: userData.id },
        accessToken
      ).then((response) => response.activities)
  );

  return {
    activityList: data,
    isLoading: !error && !data,
    isError: error,
    mutateActivityList: mutate,
  };
}
