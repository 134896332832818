import { gql } from "@apollo/client";

export const GET_FILE_COPIES = gql`
  query ($fileName: String!, $activityId: BigInt!) {
    files(
      where: {
        AND: {
          name: { equals: $fileName }
          originalFileTask: { is: { activityId: { equals: $activityId } } }
        }
      }
    ) {
      id
      name
      originalName
      size
      folderId
      originalFileTask {
        id
        activityId
      }
    }
  }
`;

export const GET_FILE_DETAILS = gql`
  query ($fileId: BigInt) {
    files(where: { id: { equals: $fileId } }) {
      id
      name
      clonedFileTaskId
      createdDate
      fileMetaData {
        id
        key
        value
      }
      originalName
      originalFileTaskId
      folder {
        id
        name
        folderType
        createdDate
        updatedDate
        canBeDeleted
        parentId
        userId
        folderOwners {
          id
          userId
          ownerType
          folderId
          createdDate
          updatedDate
        }
        activity {
          id
        }
      }
    }
  }
`;

export const GET_FOLDER_DETAILS = gql`
  query ($folderId: BigInt) {
    folders(where: { id: { equals: $folderId } }) {
      id
      name
      activityId
      canBeDeleted
      createdDate
      folderType
      parentId
      updatedDate
      userId
      children {
        id
        name
        folderType
      }
      folderOwners {
        id
        userId
        ownerType
      }
      files {
        id
        name
      }
    }
  }
`;

export const GET_USER_FOLDERS = gql`
  query ($userId: BigInt) {
    folders(where: { userId: { equals: $userId } }) {
      id
      name
      activityId
      canBeDeleted
      createdDate
      folderType
      parentId
      updatedDate
      userId
    }
  }
`;

// export const GET_USER_FOLDERS = gql`
//   query ($userId: BigInt) {
//     folders(
//       where: {
//         AND: [{ userId: { equals: $userId } }, { parentId: { equals: null } }]
//       }
//     ) {
//       id
//       name
//       activityId
//       canBeDeleted
//       createdDate
//       folderType
//       parentId
//       updatedDate
//       userId
//     }
//   }
// `;
