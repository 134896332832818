import React from "react";
import AppSubmenu from "../app/AppSubMenu";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";
import { IsUserRoleAllowed } from "../common/utilities";


export const AppMenu = (props) => {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const { userData } = useCurrentUser(accessToken);
  const menuItems = [
    { label: "Home", icon: "pi pi-fw pi-home", to: "/", requireAuth: true },
    { label: "Files", icon: "pi pi-fw pi-file", to: "/files", requireAuth: true },
    { label: "Workflows", icon: "pi pi-fw pi-sitemap -rotate-90", to: "/workflows", requireAuth: true, visible(){ return IsUserRoleAllowed(process.env.REACT_APP_ADMIN_ROLES, (userData? userData.roles:[]));} }
    // { label: "Help", icon: "pi pi-fw pi-question-circle", to: "/help", requireAuth: false },
  ];

  return (
    <div className="layout-menu-container" onClick={props.onSidebarClick}>
      <div className="layout-menu-wrapper">
        <AppSubmenu
          userData={userData}
          items={menuItems}
          className="layout-menu"
          mega={false}
          root={true}
          isMobile={props.isMobile}
          menuHoverActive={props.menuHoverActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMenuItemClick={props.onMenuItemClick}
          horizontal={props.horizontal}
        />
      </div>
    </div>
  );
};

export default AppMenu;
