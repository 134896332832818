import { ApolloClient, InMemoryCache } from "@apollo/client";

const graphQLClient = (accessToken) => {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_BASE,
    cache: new InMemoryCache(),
    request: (operation) => {
      operation.setContext({
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });
    },
  });

  return client;
};

export { graphQLClient };
