const fetchBinaryAsset = (asset) =>
  fetch(process.env.REACT_APP_UPLOADFILE_BASE + "/" + asset).then((res) =>
    res.arrayBuffer()
  );

const fetchLocalBinaryAsset = (asset) =>
  fetch("/assets/test/" + asset).then((res) => res.arrayBuffer());

const fetchSupportFilesMetadata = () => {
  return fetch("/assets/docs/supportFiles.json")
    .then((res) => res.json())
    .then((d) => d.data);
};
export { fetchBinaryAsset, fetchLocalBinaryAsset, fetchSupportFilesMetadata };
