import useSWR from "swr";
import useCurrentUser from "../hooks/useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_DEPARTMENT_WORKFLOWS } from "../graphQL/workflows";

export default function useDepartmentWorkflows(accessToken) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getDepartmentWorkflows", userData.departmentId],
    () =>
      queryGraphQL(
        GET_DEPARTMENT_WORKFLOWS,
        { departmentId: userData.departmentId },
        accessToken
      ).then((response) => response.workflowTemplates)
  );

  return {
    workflowList: data,
    isLoading: !error && !data,
    isError: error,
    mutateWorkflowList: mutate,
  };
}
