import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  formatDate,
  taskAssigneeGraphQlFilterString,
} from "../common/utilities";
import useAuth from "../hooks/useAuth";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import { ActivityDetailsContext } from "../contexts/ActivityDetailsContext";
import useCollaborationList from "../hooks/useCollaborationList";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Tooltip } from "primereact/tooltip";

export default function ActivityCollaboratorList(props) {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { setShouldRefreshContents } = useContext(ActivityDetailsContext);
  const [
    loadingIndicatorActivityInvolved,
    setLoadingIndicatorActivityInvolved,
  ] = useState(true);
  const [filters, setFilters] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const { getToken, userId } = useAuth();
  const accessToken = getToken();

  const { collaborationList: activitiesStarted } = useCollaborationList(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  useEffect(() => {
    if (activitiesStarted) {
      setLoadingIndicatorActivityInvolved(false);
      setShouldRefreshContents(false);

      setFilters(userSettings.filters.collaborations);
      setPageSize(userSettings.pageSize);
    }
  }, [activitiesStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateDataFormat = (rowData) => {
    return formatDate(rowData.createdDate);
  };

  const statusDisplayTemplate = (data) => {
    if (data.status === "IN_PROGRESS") {
      return "IN PROGRESS";
    } else if (data.status === "DRAFT") {
      return "DRAFT";
    } else if (data.status === "COMPLETED") {
      return "COMPLETED";
    } else {
      return "CANCELLED";
    }
  };

  const listButtonsTemplate = (rowData) => {
    return rowData.status !== "CANCELLED" ? (
      <>
        <Tooltip target=".p-button-custom" />
        <div className="actions flex-nowrap flex justify-content-center">
          <span
            className="p-button-custom"
            data-pr-tooltip="Activity Dashboard"
            data-pr-position="top"
            data-pr-at="center top-7"
          >
            <Link
              to={"/tasks/" + rowData.id}
              className="p-button p-button-icon-only p-button-text mr-2"
            >
              <i className="pi pi-list text-2xl font-light" />
            </Link>
          </span>
        </div>
      </>
    ) : (
      <div></div>
    );
  };

  const handleFilterChange = (newFilters) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      filters: {
        ...userSettings.filters,
        collaborations: newFilters.filters,
      },
    }));

    setFilters(newFilters.filters);
  };

  const handlePageChange = ({ first, rows }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      pageSize: rows,
    }));

    setPageSize(rows);
    setOffset(first);
  };

  const statuses = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "DRAFT", value: "DRAFT" },
    { label: "IN PROGRESS", value: "IN_PROGRESS" },
  ];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        className="p-column-filter"
        placeholder="Select a Status"
        value={options.value}
        options={statuses}
        optionValue="value"
        optionLabel="label"
        onChange={(e) => options.filterCallback(e.value)}
        showClear
      />
    );
  };

  const paginatorTemplate = {
    layout: `FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport`,
    CurrentPageReport: (options) => {
      const itemTerm = options.totalRecords > 1 ? "activities" : "activity";
      return (
        <>
          <div className="flex justify-content-end ml-4">
            <span className="font-bold">{options.first}</span>&nbsp;to&nbsp;
            <span className="font-bold">{options.last}</span>&nbsp;of&nbsp;
            <span className="font-bold">{options.totalRecords}</span>
            &nbsp;{itemTerm}.
          </div>
        </>
      );
    },
  };

  return (
    <>
      {activitiesStarted && (
        <DataTable
          value={activitiesStarted}
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          paginatorTemplate={paginatorTemplate}
          paginator
          rows={pageSize}
          first={offset}
          rowsPerPageOptions={[10, 25, 50]}
          className="p-datatable-gridlines"
          showGridlines
          dataKey="id"
          loading={loadingIndicatorActivityInvolved}
          responsiveLayout="scroll"
          emptyMessage="No activities found."
          size="small"
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={["name", "status"]}
          sortMode="multiple"
          removableSort
          rowClassName={(data) => {
            return data.status === "COMPLETED"
              ? "bg-green-75"
              : data.status === "IN_PROGRESS"
              ? "bg-blue-75"
              : data.status === "CANCELLED"
              ? "bg-gray-100"
              : "";
          }}
          onFilter={handleFilterChange}
          onPage={handlePageChange}
        >
          <Column
            field="name"
            header="Activity"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            showFilterMenu={true}
            showFilterOperator={false}
            filter
            filterField="name"
            sortable
            filterPlaceholder="Activity name"
          />
          <Column
            header="Date Created"
            field="createdDate"
            dataType="date"
            bodyClassName="text-center"
            body={dateDataFormat}
            style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
            sortable
          />
          <Column
            header="Creator"
            field="createdBy"
            bodyClassName="text-left"
            sortable
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            body={statusDisplayTemplate}
            filter
            filterElement={statusFilterTemplate}
            style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
            sortable
          />
          <Column
            body={listButtonsTemplate}
            bodyClassName="text-center"
            style={{ width: "5rem", minWidth: "5rem" }}
          />
        </DataTable>
      )}
    </>
  );
}
