import React, { useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import useAuth from "../hooks/useAuth";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { upsertMetadata } from "../services/apiMetadata";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";
import MetadataUpdater from "../components/MetadataUpdater";

export default function AddMetadata(props) {
  const defaultValues = {
    metadataName: "",
    metadataValue: "",
  };
  const { getToken } = useAuth();
  const accessToken = getToken();
  const metadataOverlayPanel = useRef(null);
  const { setShouldRefreshContents } = useContext(RefreshFolderContentContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const createNewMetadata = (data) => {
    let fileMetadata = {};

    let existingMetadata = props.metadata;
    for (let i = 0; i < existingMetadata.length; i++) {
      const metadataObject = existingMetadata[i];
      fileMetadata[`${metadataObject.key}`] = metadataObject.value;
    }

    fileMetadata[`${data.metadataName}`] = data.metadataValue;

    upsertMetadata(props.targetFileId, fileMetadata, accessToken)
      .then((response) => {
        onClear();
        setShouldRefreshContents(true);
      })
      .catch((e) => {
        throw e;
      });
  };

  const onClear = () => {
    // close overlay panel
    metadataOverlayPanel.current.hide();
    reset();
  };

  return (
    <>
      <Toast />
      <Button
        type="button"
        icon="pi pi-chart-bar rotate-90"
        onClick={(e) => {
          metadataOverlayPanel.current.toggle(e);
        }}
        className="p-button p-button-text"
      />

      <OverlayPanel ref={metadataOverlayPanel} style={{ width: "295px" }}>
        <form onSubmit={handleSubmit(createNewMetadata)}>
          <div className="formgrid grid">
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="metadataName"
                  control={control}
                  rules={{
                    required: "Metadata name is required.",
                    validate: (value) => {
                      return !!value.trim() || "Metadata name is required.";
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.metadataName}
                      {...field}
                      className={classNames("w-18rem", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="metadataName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Metadata Name *
                </label>
              </span>
              {getFormErrorMessage("metadataName")}
            </div>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="metadataValue"
                  control={control}
                  rules={{ required: "Value is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.metadataValue}
                      {...field}
                      className={classNames("w-18rem", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="metadataValue"
                  className={classNames({ "p-error": errors.name })}
                >
                  Value *
                </label>
              </span>
              {getFormErrorMessage("metadataValue")}
            </div>
          </div>
          <div className="flex justify-content-end">
            <Button
              label="Clear"
              icon="pi pi-times"
              onClick={() => onClear()}
              className="p-button p-button-outlined mr-2"
            />
            <Button
              type="submit"
              label="Save"
              className="p-button"
              icon="pi pi-check"
            />
          </div>
        </form>
        <div className="flex justify-content-start mt-5">
          <MetadataUpdater
            fileName={props.fileName}
            metadata={props.metadata}
          />
        </div>
      </OverlayPanel>
    </>
  );
}
