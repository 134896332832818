import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";

export default function WorkflowButtons(props) {
  return (
    <>
      <div className="actions flex-nowrap flex justify-content-center">
        <Button
          icon="pi pi-sitemap text-2xl font-light -rotate-90"
          className="p-button p-button-icon-only p-button-text hover:bg-white"
          onClick={props.onShowTemplate}
        />
        <NavLink
          to={"/designer/" + props.workflowId}
          className="p-button p-button-icon-only p-button-text text-yellow-500"
        >
          <i className="pi pi-file-edit text-2xl font-light" />
        </NavLink>
        <Button
          icon="pi pi-trash text-2xl font-light"
          className="p-button p-button-icon-only p-button-text hover:bg-white text-pink-500"
          onClick={props.onDelete}
        />
      </div>
    </>
  );
}
