import { deleteRequest, postRequest } from "../common/apiHelper";

const upsertMetadata = (id, data, accessToken) => {
  return postRequest("/files/" + id + "/metadata", data, accessToken);
};

const deleteMetadata = (id, metadataId, accessToken) => {
  return deleteRequest(
    "/files/" + id + "/metadata/by-id/" + metadataId,
    accessToken
  );
};

export { upsertMetadata, deleteMetadata };
