import { useContext, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { UPDATE_ACTIVITY } from "../graphQL/activities";
import { UPDATE_ACTIVITY_TASKS } from "../graphQL/tasks";
import { mutateGraphQL } from "../services/apiGraphQL";
import useAuth from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { ActivityDetailsContext } from "../contexts/ActivityDetailsContext";
import useActivityList from "../hooks/useActivityList";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";
import useAssignmentCounts from "../hooks/useAssignmentCounts";

export default function ActivityCancelDialog(props) {
  const { setShouldRefreshContents } = useContext(ActivityDetailsContext);
  const [loading, setLoading] = useState(false);
  const { getToken, userId } = useAuth();
  const accessToken = getToken();

  const { mutateActivityList } = useActivityList(accessToken);
  const { mutateAssignmentCounts } = useAssignmentCounts(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  const defaultValues = {
    activityName: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const cancelActivity = () => {
    setLoading(true);
    mutateGraphQL(
      UPDATE_ACTIVITY,
      {
        id: props.activityId,
        data: { status: { set: "CANCELLED" } },
      },
      accessToken
    )
      .then((response) => {
        mutateGraphQL(
          UPDATE_ACTIVITY_TASKS,
          {
            activityId: props.activityId,
            data: { status: { set: "CANCELLED" } },
          },
          accessToken
        )
          .then((response) => {
            mutateActivityList();
            mutateAssignmentCounts();
            setShouldRefreshContents(true);
          })
          .catch((e) => {
            console.log("e: ", e);
          });
      })
      .catch((e) => {
        console.log("e: ", e);
      })
      .finally(() => {
        props.onHide();
        setLoading(false);
      });
  };

  const header = () => {
    return (
      <>
        <span>Are you sure you want to cancel </span>
        <span>{props.activityName}?</span>
      </>
    );
  };

  return (
    <>
      <Dialog
        header={header}
        visible={props.displayActivityCancelDialog}
        onHide={props.onHide}
        resizable={false}
        draggable={true}
        maximized={false}
        style={{ maxWidth: 900, maxHeight: 800 }}
      >
        {props.displayActivityCancelDialog && (
          <form onSubmit={handleSubmit(cancelActivity)}>
            <div className="grid align-items-center">
              <div className="col-fixed">
                <i className="pi pi-exclamation-triangle text-4xl text-pink-500" />
              </div>
              <div className="col">
                WARNING! Canceling the activity {props.activityName} is
                irreversible. <br />
                This action cannot be undone. <br />
                Continuing from here will end this activity and any related
                tasks.
              </div>
            </div>
            <br />
            <span className="field col">Type the Activity Name:</span>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="activityName"
                  control={control}
                  rules={{
                    required: "Activity name is required.",
                    validate: {
                      hasValue: (value) =>
                        !!value.trim() || "Activity name is required.",
                      nameMatches: (value) =>
                        value === props.activityName ||
                        "Activity name is incorrect.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.activityName}
                      {...field}
                      className={classNames("w-full", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="activityName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Activity Name *
                </label>
              </span>
              {getFormErrorMessage("activityName")}
            </div>
            <div className="flex justify-content-evenly">
              <div className="flex justify-content-evenly">
                <Button
                  label="No"
                  icon="pi pi-times"
                  onClick={props.onHide}
                  className="p-button p-button-outlined"
                />
              </div>
              <div className="flex justify-content-evenly">
                <Button
                  label="Yes"
                  icon="pi pi-check"
                  type="submit"
                  className="p-button p-button-danger"
                  loading={loading}
                />
              </div>
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
}
