import React from "react";
import FileList from "../components/FileList";
import { ConfirmDialog } from "primereact/confirmdialog";

import { Splitter, SplitterPanel } from "primereact/splitter";
import FolderNavigator from "../components/FolderNavigator";
import SelectedFolder from "../components/SelectedFolder";
import SupportFiles from "../components/SupportFiles";

export default function Files() {
  return (
    <>
      <ConfirmDialog />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h3 className="m-3 mb-4">File Manager</h3>
            <SelectedFolder readOnlyMode={false} />
            <Splitter className="mb-5">
              <SplitterPanel
                className="overflow-auto flex align-items-top justify-content-start flex-column"
                size={30}
                minSize={20}
              >
                <FolderNavigator readOnlyMode={false} />
                <SupportFiles />
              </SplitterPanel>
              <SplitterPanel
                className="flex align-items-top"
                size={70}
                minSize={60}
              >
                <FileList readOnlyMode={false} />
              </SplitterPanel>
            </Splitter>
          </div>
        </div>
      </div>
    </>
  );
}
