import React, { useState } from "react";

const ActivityDetailsContext = React.createContext();

const ActivityDetailsContextProvider = (props) => {
  const [shouldRefreshContents, setShouldRefreshContents] = useState({});

  return (
    <ActivityDetailsContext.Provider
      value={{ shouldRefreshContents, setShouldRefreshContents }}
    >
      {props.children}
    </ActivityDetailsContext.Provider>
  );
};

export { ActivityDetailsContext, ActivityDetailsContextProvider };
