import { postRequest } from "../common/apiHelper";

const addActivityComment = (id, data, accessToken) => {
  return postRequest("/activities/" + id + "/comments", data, accessToken);
};

const attachFileToComment = (id, commentId, data, accessToken) => {
  return postRequest(
    "/activities/" + id + "/comments/" + commentId + "/files",
    data,
    accessToken
  );
};

export { addActivityComment, attachFileToComment };
