import React, { useState, useId } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import DOMPurify from "dompurify";
import { classNames } from "primereact/utils";
import { ConfirmPopup } from "primereact/confirmpopup";
import useAuth from "../hooks/useAuth";
import { getBinaryFile } from "../services/apiFiles";
import { getIndexOccurrence } from "../common/utilities";

export default function MessageContent(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [visible, setVisible] = useState(false);
  var cleanMessage = DOMPurify.sanitize(props.message);

  const container = document.createElement("div");
  container.innerHTML = cleanMessage;
  const imgSrcs = [...container.querySelectorAll("[src]")].map((element) =>
    element.getAttribute("src")
  );
  const domId = useId();

  const reject = () => {};

  const downloadAttachment = async () => {
    getBinaryFile(props.fileId, accessToken)
      .then((buffer) => {
        const link = document.createElement("a");
        link.href = buffer.data;
        link.setAttribute(
          "download",
          props.fileName.substring(props.fileName.lastIndexOf("-") + 1)
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((e) => {
        throw e;
      });
  };

  const handleDownloadAttachment = () => {
    downloadAttachment();
  };

  return (
    <>
      <div className="flex justify-content-between">
        <div
          className={classNames(
            "flex justify-content-start font-semibold mb-2",
            { "ml-2": props.isMessageMine }
          )}
        >
          {props.displayName} ({props.timeStamp}):
        </div>
        <div className="flex justify-content-end">
          {props.isMessageMine && (
            <>
              <ConfirmPopup
                target={document.getElementById(domId + "-deleteButton")}
                visible={visible}
                onHide={() => setVisible(false)}
                message="Are you sure you want to delete this?"
                icon="pi pi-exclamation-triangle"
                accept={() => props.onDelete(props.messageId)}
                reject={reject}
                acceptClassName="p-button-danger"
              />

              <Button
                id={domId + "-deleteButton"}
                onClick={() => setVisible(true)}
                className="pi p-button-icon-only p-button-text pi-trash bg-transparent p-button-danger"
              />
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(
          "flex justify-content-start",
          props.isMessageMine ? "ml-3" : "ml-2"
        )}
      >
        {imgSrcs.length === 0 && (
          <>
            {cleanMessage === "File Attachment" && (
              <>
                <Button
                  icon="pi pi-download"
                  label={props.fileName.substring(
                    getIndexOccurrence(props.fileName, "-", 4) + 1
                  )}
                  onClick={handleDownloadAttachment}
                />
              </>
            )}
            {cleanMessage !== "File Attachment" && (
              <span dangerouslySetInnerHTML={{ __html: cleanMessage }} />
            )}
          </>
        )}
        {imgSrcs.length > 0 && (
          <div className="flex flex-column">
            {imgSrcs.map((src, i) => {
              return (
                <Image key={i} src={src} preview imageClassName="max-w-full" />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
