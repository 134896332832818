import { Button } from "primereact/button";

export default function DocumentNavigator(props) {
  return (
    <>
      <div className="flex justify-content-between flex-wrap card-container">
        <div className="flex align-items-center justify-content-center m-2">
          <Button
            type="button"
            disabled={props.pageNumber <= 1}
            onClick={props.firstPage}
            icon="pi pi-fast-backward"
            className="p-button-rounded mr-2 mb-2"
          ></Button>
          <Button
            type="button"
            disabled={props.pageNumber <= 1}
            onClick={props.previousPage}
            icon="pi pi-caret-left"
            className="p-button-rounded mr-2 mb-2"
          ></Button>
        </div>
        <div className="flex align-items-center justify-content-center font-bold m-2">
          <p>
            Page {props.pageNumber || (props.numPages ? 1 : "--")} of {props.numPages || "--"}
          </p>
        </div>
        <div className="flex align-items-center justify-content-center m-2">
          <Button
            type="button"
            disabled={props.pageNumber >= props.numPages}
            onClick={props.nextPage}
            icon="pi pi-caret-right"
            className="p-button-rounded mr-2 mb-2"
          ></Button>
          <Button
            type="button"
            disabled={props.pageNumber >= props.numPages}
            onClick={props.lastPage}
            icon="pi pi-fast-forward"
            className="p-button-rounded mr-2 mb-2"
          ></Button>
        </div>
      </div>
    </>
  );
}
