import useSWR from "swr";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_WORKFLOW_DETAILS } from "../graphQL/workflows";

export default function useWorkflowDetails(workflowId, accessToken) {
  const { data, error, mutate } = useSWR(
    workflowId && ["getWorkflowDetails", workflowId],
    () =>
      queryGraphQL(
        GET_WORKFLOW_DETAILS,
        { workflowId: workflowId },
        accessToken
      ).then((response) => response.workflowTemplates[0])
  );

  return {
    workflowDetails: data,
    isLoading: !error && !data,
    isError: error,
    mutateWorkflowDetails: mutate,
  };
}
