import { Divider } from "primereact/divider";
import WorkflowCanvas from "../components/WorkflowCanvas";
import TaskAssigneeList from "../components/TaskAssigneeList";

export default function ReviewActivityDetails(props) {
  return (
    <>
      <div className="grid row mb-2">
        <div className="col-1"></div>
        <div className="col-3">
          <span className="font-bold">Activity Name</span>
        </div>
        <div className="col">
          <span>{props.getValues().activityName}</span>
        </div>
      </div>
      
      <div className="grid row mb-2">
        <div className="col-1"></div>
        <div className="col-3">
          <span className="font-bold">Shared To</span>
        </div>
        <div className="col">
          {props.picklistTargetValue.map((user, i) => {
            return (
              <div className="mb-1" key={i}>
                {user.name}
              </div>
            );
          })}
        </div>
      </div>

      <div className="grid row mb-2">
        <div className="col-1"></div>
        <div className="col-3">
          <span className="font-bold">Workflow Template Name</span>
        </div>
        <div className="col">
          <span>{props.selectedWorkflow && props.selectedWorkflow.name}</span>
        </div>
      </div>

      <Divider align="left" type="solid">
        <b>Workflow Template</b>
      </Divider>
      <div className="mb-3 flex justify-content-start">
        <div className="w-screen h-fit" style={{ maxWidth: "50vw" }}>
          <WorkflowCanvas
            nodes={props.nodes}
            edges={props.edges}
            allowEdit={false}
            assignUser={true}
            reviewMode={true}
            taskAssignees={props.taskAssignees}
          />
        </div>
        <div className="ml-2">
          <TaskAssigneeList
            taskAssignees={props.taskAssignees}
            nodes={props.nodes}
          />
        </div>
      </div>
    </>
  );
}
