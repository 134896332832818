import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";
import { UPDATE_ACTIVITY } from "../graphQL/activities";
import { mutateGraphQL } from "../services/apiGraphQL";
import useAuth from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import useActivityList from "../hooks/useActivityList";
import useCollaborationList from "../hooks/useCollaborationList";
import useTaskList from "../hooks/useTaskList";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";

export default function ActivityRenameDialog(props) {
  const { getToken, userId } = useAuth();
  const accessToken = getToken();
  const { mutateTaskList } = useTaskList(accessToken);
  const { mutateActivityList } = useActivityList(accessToken);
  const { mutateCollaborationList } = useCollaborationList(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  const defaultValues = {
    activityName: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renameActivity = (data) => {
    mutateGraphQL(
      UPDATE_ACTIVITY,
      {
        id: props.activityId,
        data: { name: { set: data.activityName } },
      },
      accessToken
    )
      .then((response) => {
        // mutate task list
        mutateTaskList();
        // mutate collab list
        mutateCollaborationList();
        // mutate activity list
        mutateActivityList();
      })
      .catch((e) => {
        console.log("e: ", e);
      })
      .finally(() => {
        props.onHide();
      });
  };

  return (
    <>
      <Dialog
        header={<span>Rename Activity</span>}
        visible={props.displayActivityRename}
        onHide={props.onHide}
        resizable={false}
        draggable={true}
        maximized={false}
        style={{ maxWidth: 900, maxHeight: 800, minWidth: 400 }}
      >
        {props.displayActivityRename && (
          <form onSubmit={handleSubmit(renameActivity)}>
            <div className="flex justify-content-between">
              <div className="text-base font-semibold">
                Current Activity Name
              </div>
              <div className="field">{props.activityName}</div>
            </div>
            <br />
            <span className="field col">New Activity Name:</span>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="activityName"
                  control={control}
                  rules={{
                    required: "Activity name is required.",
                    validate: {
                      hasValue: (value) =>
                        !!value.trim() || "Activity name is required.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.activityName}
                      {...field}
                      className={classNames("w-full", {
                        "p-invalid": fieldState.error,
                      })}
                      value={field.activityName}
                    />
                  )}
                />
                <label
                  htmlFor="activityName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Activity Name *
                </label>
              </span>
              {getFormErrorMessage("activityName")}
            </div>
            <div className="flex justify-content-evenly">
              <div className="flex justify-content-evenly">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={props.onHide}
                  className="p-button p-button-outlined"
                />
              </div>
              <div className="flex justify-content-evenly">
                <Button
                  label="Save"
                  icon="pi pi-check"
                  type="submit"
                  className="p-button"
                  loading={props.loadingIndicatorButtons}
                />
              </div>
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
}
