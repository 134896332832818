import React, { useState, useEffect, useContext } from "react";
import ActivityList from "../components/ActivityList";
import ActivityWorkflowDialog from "../components/ActivityWorkflowDialog";
import ActivityCollaboratorsDialog from "../components/ActivityCollaboratorsDialog";
import TaskList from "../components/TaskList";
import ActivityCancelDialog from "../components/ActivityCancelDialog";
import ActivityCollaboratorList from "../components/ActivityCollaboratorList";
import ActivityReviewDialog from "../components/ActivityReviewDialog";
import ActivityRenameDialog from "../components/ActivityRenameDialog";
import ActivityTasksDialog from "../components/ActivityTasksDialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Link } from "react-router-dom";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import useAuth from "../hooks/useAuth";
import useAssignmentCounts from "../hooks/useAssignmentCounts";
import { taskAssigneeGraphQlFilterString } from "../common/utilities";

export default function Home() {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [displayTemplate, setDisplayTemplate] = useState(false);
  const [displayActivityReview, setDisplayActivityReview] = useState(false);
  const [displayActivityRename, setDisplayActivityRename] = useState(false);
  const [
    displayActivityCollaboratorsDialog,
    setDisplayActivityCollaboratorsDialog,
  ] = useState(false);
  const [displayActivityCancelDialog, setDisplayActivityCancelDialog] =
    useState(false);
  const [displayActivityTasks, setDisplayActivityTasks] = useState(false);
  const [targetActivityName, setTargetActivityName] = useState({});
  const [targetActivityId, setTargetActivityId] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const { getToken, userId } = useAuth();
  const accessToken = getToken();
  const { assignmentCounts } = useAssignmentCounts(
    accessToken,
    taskAssigneeGraphQlFilterString(userId)
  );

  useEffect(() => {
    setActiveIndex(userSettings.dashboardSelectedTabIndex);
  }, [userSettings]);

  const handleShowTemplate = (data) => {
    setDisplayTemplate(true);
    setTargetActivityId(data);
  };

  const handleShowCollaboratorsDialog = (activityId) => {
    setTargetActivityId(activityId);
    setDisplayActivityCollaboratorsDialog(true);
  };

  const handleShowCancelDialog = (activityId, activityName) => {
    setDisplayActivityCancelDialog(true);

    setTargetActivityId(activityId);
    setTargetActivityName(activityName);
  };

  const handleShowActivityReviewDialog = (activityId) => {
    setDisplayActivityReview(true);
    setTargetActivityId(activityId);
  };

  const handleShowActivityRenameDialog = (activityId, activityName) => {
    setDisplayActivityRename(true);
    setTargetActivityId(activityId);
    setTargetActivityName(activityName);
  };

  const handleShowActivityTasksDialog = (activityId) => {
    setDisplayActivityTasks(true);
    setTargetActivityId(activityId);
  };

  const onHide = () => {
    setDisplayTemplate(false);
    setDisplayActivityCancelDialog(false);
    setDisplayActivityCollaboratorsDialog(false);
    setDisplayActivityReview(false);
    setDisplayActivityRename(false);
    setDisplayActivityTasks(false);
  };

  const handleTabChange = ({ index }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      dashboardSelectedTabIndex: index,
    }));

    setActiveIndex(index);
  };

  const setTaskListFilter = (data) => {
    // console.log("data: ", data);
  };
  const setActivityListFilter = (data) => {
    // console.log("data: ", data);
  };

  const myTasksHeaderTemplate = (options) => {
    const itemTerm =
      assignmentCounts?.aggregateTask._count.id > 1 ? "tasks" : "task";
    return (
      <span onClick={options.onClick} className={options.className}>
        My Tasks
        <Badge
          value={assignmentCounts?.aggregateTask._count.id}
          className="ml-2 p-badge-danger p-button-custom"
          data-pr-tooltip={`You have ${assignmentCounts?.aggregateTask._count.id} ${itemTerm} In Progress`}
          data-pr-position="top"
          data-pr-at="center top-10"
          onClick={setTaskListFilter}
        ></Badge>
      </span>
    );
  };

  const collabHeaderTemplate = (options) => {
    const itemTerm =
      assignmentCounts?.aggregateActivity._count.id > 1
        ? "activities"
        : "activity";
    return (
      <span onClick={options.onClick} className={options.className}>
        Shared Activities
        <Badge
          value={assignmentCounts?.aggregateActivity._count.id}
          className="ml-2 p-badge-danger p-button-custom"
          data-pr-tooltip={`You have ${assignmentCounts?.aggregateActivity._count.id} ${itemTerm} In Progress`}
          data-pr-position="top"
          data-pr-at="center top-10"
          onClick={setActivityListFilter}
        ></Badge>
      </span>
    );
  };

  return (
    <>
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <Tooltip target=".p-button-custom" />
            <div className="flex justify-content-end">
              <Link
                to={"/wizard"}
                className="p-button-custom p-button z-5"
                data-pr-tooltip="Create New Activity"
                data-pr-position="top"
                data-pr-at="center top-10"
              >
                <i className="pi pi-plus mr-2" />
                New Activity
              </Link>
            </div>
            <TabView
              className="-mt-5"
              activeIndex={activeIndex}
              onTabChange={handleTabChange}
            >
              <TabPanel
                header="My Tasks"
                headerTemplate={myTasksHeaderTemplate}
              >
                <TaskList handleShowTemplate={handleShowTemplate} />
              </TabPanel>
              <TabPanel header="My Activities">
                <ActivityList
                  handleShowWorkflowData={handleShowTemplate}
                  handleShowCancelDialog={handleShowCancelDialog}
                  handleShowCollaboratorsDialog={handleShowCollaboratorsDialog}
                  handleShowActivityReviewDialog={
                    handleShowActivityReviewDialog
                  }
                  handleShowActivityRenameDialog={
                    handleShowActivityRenameDialog
                  }
                  handleShowActivityTasksDialog={handleShowActivityTasksDialog}
                />
              </TabPanel>
              <TabPanel
                header="Shared Activities"
                headerTemplate={collabHeaderTemplate}
              >
                <ActivityCollaboratorList />
              </TabPanel>
            </TabView>
          </div>
          <ActivityWorkflowDialog
            displayBasic={displayTemplate}
            onHide={onHide}
            activityId={targetActivityId}
          />

          <ActivityCancelDialog
            displayActivityCancelDialog={displayActivityCancelDialog}
            onHide={onHide}
            activityName={targetActivityName}
            activityId={targetActivityId}
          />

          <ActivityCollaboratorsDialog
            displayActivityCollaboratorsDialog={
              displayActivityCollaboratorsDialog
            }
            onHide={onHide}
            activityId={targetActivityId}
          />

          <ActivityReviewDialog
            displayActivityReview={displayActivityReview}
            onHide={onHide}
            activityId={targetActivityId}
          />

          <ActivityRenameDialog
            displayActivityRename={displayActivityRename}
            onHide={onHide}
            activityId={targetActivityId}
            activityName={targetActivityName}
          />

          <ActivityTasksDialog
            displayActivityTasks={displayActivityTasks}
            onHide={onHide}
            activityId={targetActivityId}
          />
        </div>
      </div>
    </>
  );
}
