import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Handle,
  Position,
  useReactFlow,
  getConnectedEdges,
} from "react-flow-renderer";
import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { removeNodeWithEdges } from "../common/workflowHelper";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import useUserList from "../hooks/useUserList";

export default function WorkflowNode(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [allowUpload, setAllowUpload] = useState(true);
  const [allowFileSelect, setAllowFileSelect] = useState(true);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayAssignee, setDisplayAssignee] = useState(false);
  const [displayRenameNode, setDisplayRenameNode] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [customNodeName, setCustomNodeName] = useState();
  const { userList } = useUserList(accessToken);
  const [extendedUserList, setExtendedUserList] = useState(userList);
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow();

  useEffect(() => {
    const users = [...userList];
    users.sort((a, b) => {
      return a.name - b.name;
    });

    users.splice(0, 0, { name: "< ACTIVITY OWNER >", code: -1 });
    setExtendedUserList(users);
  }, [userList]);

  useLayoutEffect(() => {
    setAllowFileSelect(props.data.allowFileSelect);
    setAllowUpload(props.data.allowUpload);
    setSelectedUser(props.data.taskAssignee);
    setCustomNodeName(props.data.customNodeName);
  }, [
    props.data.allowFileSelect,
    props.data.allowUpload,
    props.data.taskAssignee,
    props.data.customNodeName,
  ]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === props.id) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.data = {
            ...node.data,
            allowUpload: allowUpload,
            allowFileSelect: allowFileSelect,
            customNodeName: customNodeName,
            taskAssignee: selectedUser,
          };
        }

        return node;
      })
    );
  }, [allowUpload, allowFileSelect, customNodeName, selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleConfigureNode = () => {
    setDisplayBasic(true);
  };

  const handleAssignee = () => {
    setDisplayAssignee(true);
  };

  const handleRenameNode = () => {
    setDisplayRenameNode(true);
  };

  const onHide = () => {
    setDisplayBasic(false);
    setDisplayAssignee(false);
    setDisplayRenameNode(false);
  };

  const handleRemoveNode = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to remove this step?",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => acceptFunc(),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const edgeList = removeNodeWithEdges(
      nodes,
      edges,
      props.id,
      getConnectedEdges
    );

    setNodes(nodes);
    setEdges(edgeList);
  };

  const rejectFunc = () => {};

  const defaultValues = {
    customNodeName: "< Node Name >",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renameNode = () => {
    const formValue = getValues();
    setCustomNodeName(formValue.customNodeName);
    onHide();
  };

  const onResetNodeNameClick = () => {
    setValue("customNodeName", "< Node Name >");
    setCustomNodeName("< Node Name >");
  };

  return (
    <>
      <Tooltip target=".input-port" />
      <Handle
        type="target"
        position={Position.Top}
        data-pr-tooltip="INPUT"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{ fontSize: "2rem", cursor: "pointer" }}
        className="p-1 input-port"
      />
      <Tooltip target=".output-port-green" />
      <Handle
        type="source"
        position={Position.Bottom}
        className="p-1 output-port-green"
        data-pr-tooltip="OUTPUT"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{ fontSize: "2rem", cursor: "pointer" }}
      />
      <div className="card mb-0 p-2 border-1 border-gray-600 w-15rem">
        <div className="flex justify-content-between">
          <div className="flex justify-content-start"></div>
          <div className="flex justify-content-end mb-2">
            {props.data.assignUser && (
              <>
                {!props.data.reviewMode && (
                  <i
                    className="pi pi-user-edit mr-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={handleAssignee}
                  ></i>
                )}
              </>
            )}
            {props.data.allowEdit && (
              <i
                className="pi pi-pencil mr-2"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleRenameNode}
              ></i>
            )}
            <i
              className="pi pi-cog mr-2"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleConfigureNode}
            ></i>
            {props.data.allowEdit && (
              <>
                <i
                  className="pi pi-times"
                  style={{ color: "#f00", cursor: "pointer" }}
                  onClick={handleRemoveNode}
                ></i>
                <ConfirmPopup />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-content-end">
          <label className="text-gray-500 text-sm">{props.data.label}</label>
        </div>
        <div className="flex justify-content-center">
          <label className="mt-3">
            {props.data.customNodeName
              ? props.data.customNodeName
              : "< Node Name >"}
          </label>
        </div>
        <div className="flex justify-content-center">
          <label className="text-green-500">
            {props.data.taskAssignee
              ? props.data.taskAssignee.name
              : selectedUser?.name}
          </label>
        </div>
      </div>

      <Dialog
        header={props.data.label + " Configuration"}
        visible={displayBasic}
        onHide={() => onHide()}
      >
        <div className="flex flex-column mb-3">
          <div className="flex my-2">
            <InputSwitch
              checked={allowUpload}
              disabled={!props.data.allowEdit}
              onChange={(e) => setAllowUpload(e.value)}
            />
            <span className="ml-3">Allow File Upload</span>
            <span className="ml-2">
              <Tooltip target=".upload-info" />
              <i
                className="pi pi-info-circle upload-info text-xl text-yellow-500"
                data-pr-tooltip="Allow uploading file(s) from the user's computer."
                data-pr-position="top"
                data-pr-at="right top-6"
                style={{ fontSize: "2rem", cursor: "pointer" }}
              ></i>
            </span>
          </div>
          <div className="flex my-2">
            <InputSwitch
              checked={allowFileSelect}
              disabled={!props.data.allowEdit}
              onChange={(e) => setAllowFileSelect(e.value)}
            />
            <span className="ml-3">Allow File Selection</span>
            <span className="ml-2">
              <Tooltip target=".select-info" />
              <i
                className="pi pi-info-circle select-info text-xl text-yellow-500"
                data-pr-tooltip="Allow selecting file(s) from the user's file manager."
                data-pr-position="top"
                data-pr-at="right top-6"
                style={{ fontSize: "2rem", cursor: "pointer" }}
              ></i>
            </span>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={props.data.label + " Task Assignee"}
        visible={displayAssignee}
        onHide={() => onHide()}
      >
        <Dropdown
          value={selectedUser}
          options={extendedUserList}
          onChange={(e) => setSelectedUser(e.value)}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          placeholder="Select assignee"
          className="min-w-full"
        />

        <div className="flex justify-content-center mt-3">
          <div className="flex justify-content-center">
            <Button
              label="Close"
              icon="pi pi-times"
              onClick={() => onHide()}
              className="p-button p-button-outlined mr-2"
            />
          </div>
          <div className="flex justify-content-center">
            <Button
              label="Set"
              icon="pi pi-check"
              type="submit"
              className="p-button ml-2"
              loading={props.loadingIndicatorButtons}
              onClick={() => {
                onHide();
                props.data.onAssignUserClick(selectedUser);
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header={"Rename Node"}
        visible={displayRenameNode}
        onHide={() => onHide()}
      >
        <form onSubmit={handleSubmit(renameNode)}>
          <div className="flex justify-content-between">
            <div className="text-base font-semibold">Current Node Name</div>
            <div className="field">
              {props.data.customNodeName
                ? props.data.customNodeName
                : "< Node Name >"}
            </div>
          </div>
          <br />
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="customNodeName"
                control={control}
                rules={{
                  required: "Node name is required.",
                  validate: (value) => {
                    return !!value.trim() || "Node name is required.";
                  },
                  nameMatches: (value) =>
                    value === "< Node Name >" || "Node name is incorrect.",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="p-inputgroup">
                      <InputText
                        id={field.customNodeName}
                        {...field}
                        className={classNames("w-18rem", {
                          "p-invalid": fieldState.error,
                        })}
                        //value={field.customNodeName}
                      />
                      <Button
                        icon="pi pi-times-circle"
                        type="submit"
                        label="Reset"
                        onClick={() => {
                          onHide();
                          onResetNodeNameClick();
                        }}
                        className="p-button p-button-danger"
                      />
                    </div>
                  </>
                )}
              />
              {/* <label
                htmlFor="customNodeName"
                className={classNames({ "p-error": errors.name })}
              >
                Node Name *
              </label> */}
            </span>
            {getFormErrorMessage("customNodeName")}
          </div>
          <div className="flex justify-content-center mt-3">
            <div className="flex justify-content-center">
              <Button
                label="Close"
                icon="pi pi-times"
                onClick={() => onHide()}
                className="p-button p-button-outlined mr-2"
              />
            </div>
            <div className="flex justify-content-center"></div>
            <div className="flex justify-content-center">
              <Button
                label="Set"
                icon="pi pi-check"
                type="submit"
                className="p-button ml-2"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
