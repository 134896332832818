import { useEffect, useMemo } from "react";
import WorkflowToolbar from "../components/WorkflowToolbar";
import ReactFlow, {
  Background,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType,
} from "react-flow-renderer";
import { initialNodes, connectionLine } from "../common/workflowHelper";
import WorkflowEdge from "../components/WorkflowEdge";
import WorkflowNode from "../components/WorkflowNode";
import WorkflowReviewNode from "../components/WorkflowReviewNode";
import WorkflowNotificationNode from "../components/WorkflowNotificationNode";
import WorkflowBasicApprovalNode from "../components/WorkflowBasicApprovalNode";
import WorkflowFinalApprovalNode from "../components/WorkflowFinalApprovalNode";
import WorkflowTerminalNode from "../components/WorkflowTerminalNode";
import WorkflowRouterNode from "../components/WorkflowRouterNode";
import WorkflowCanvasControlExtensions from "../components/WorkflowCanvasControlExtensions";
import useAuth from "../hooks/useAuth";
import useUserList from "../hooks/useUserList";

export default function WorkflowCanvas(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();

  const edgeTypes = useMemo(() => ({ buttonEdge: WorkflowEdge }), []);
  const nodeTypes = useMemo(
    () => ({
      basicNode: WorkflowNode,
      basicApprovalNode: WorkflowBasicApprovalNode,
      finalApprovalNode: WorkflowFinalApprovalNode,
      notificationNode: WorkflowNotificationNode,
      reviewNode: WorkflowReviewNode,
      reviewNode1: WorkflowReviewNode,
      terminalNode: WorkflowTerminalNode,
      routerNode4: WorkflowRouterNode,
      routerNode3: WorkflowRouterNode,
      routerNode2: WorkflowRouterNode,
    }),
    []
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const { userList } = useUserList(accessToken);

  useEffect(() => {
    for (let i = 0; i < props.edges.length; i++) {
      const edge = props.edges[i];
      edge.data = { ...edge.data, allowEdit: props.allowEdit };
    }

    for (let i = 0; i < props.nodes.length; i++) {
      const node = props.nodes[i];
      const assignee = props.taskAssignees.find(
        (assignee) => assignee.node.nodeId === node.id
      );

      node.data = {
        ...node.data,
        allowEdit: props.allowEdit,
        assignUser: props.assignUser,
        reviewMode: props.reviewMode,
        onAssignUserClick: (assigneeId) =>
          props.onAssignUserClick(
            {
              nodeId: node.id,
              label: node.data.label,
            },
            assigneeId
          ),
        userList: userList,
        taskAssignee: assignee ? assignee.user : {},
        customNodeName: node.data.customNodeName,
        targetUsers: node.data.targetUsers,
      };
    }

    setNodes(props.nodes);
    setEdges(props.edges);
  }, [props.nodes, props.edges, props.workflowId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onConnect = (params) => {
    setEdges((edge) =>
      addEdge(
        {
          ...params,
          type: "buttonEdge",
          markerEnd: { type: MarkerType.ArrowClosed, color: "#b1b1b7" },
          animated: true,
          style: { strokeWidth: 2 },
          data: { allowEdit: true },
        },
        edge
      )
    );
  };

  return (
    <>
      <div className="w-full workflow-designer-height border-solid border-blue-100">
        <ReactFlowProvider>
          {props.allowEdit && (
            <WorkflowToolbar
              nodes={nodes}
              setNodes={setNodes}
              edges={edges}
              workflowId={props.workflowId}
            />
          )}
          <ReactFlow
            nodes={nodes}
            edges={edges}
            connectionLineComponent={connectionLine}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            fitView={true}
            deleteKeyCode={null}
            zoomActivationKeyCode={null}
            zoomOnScroll={false}
            preventScrolling={false}
            selectionKeyCode={null}
            multiSelectionKeyCode={null}
            nodesDraggable={props.allowEdit ? true : false}
            nodesConnectable={props.allowEdit ? true : false}
          >
            <Background
              variant={props.allowEdit ? "lines" : "dots"}
              gap={props.allowEdit ? 10 : 7}
              size={props.allowEdit ? 0.5 : 1}
              color="#badaff"
            />
            <WorkflowCanvasControlExtensions
              allowEdit={props.allowEdit}
              nodes={nodes}
              edges={edges}
              workflowId={props.workflowId}
            />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </>
  );
}
