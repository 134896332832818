import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { Tooltip } from "primereact/tooltip";

export default function WorkflowTerminalNode(props) {
  return (
    <>
      {props.data.label === "END" && (
        <>
          <Tooltip target=".input-port" />
          <Handle
            type="target"
            position={Position.Top}
            className="p-1 input-port"
            data-pr-tooltip="INPUT PORT"
            data-pr-position="top"
            data-pr-at="right top-6"
            style={{ fontSize: "2rem", cursor: "pointer" }}
          />
        </>
      )}
      {props.data.label === "START" && (
        <>
          <Tooltip target=".output-port-green" />
          <Handle
            type="source"
            position={Position.Bottom}
            className="p-1 output-port-green"
            data-pr-tooltip="OUTPUT PORT"
            data-pr-position="top"
            data-pr-at="right top-6"
            style={{ fontSize: "2rem", cursor: "pointer" }}
          />
        </>
      )}
      <div className="card mb-0 p-2 border-1 border-gray-600 w-10rem flex justify-content-center">
        <label>{props.data.label}</label>
      </div>
    </>
  );
}
