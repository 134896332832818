import { useState, useLayoutEffect } from "react";
import { Dialog } from "primereact/dialog";
import useAuth from "../hooks/useAuth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Link } from "react-router-dom";
import { formatDate } from "../common/utilities";
import { FilterMatchMode } from "primereact/api";
import useUserList from "../hooks/useUserList";
import useActivityDetailsWithTasks from "../hooks/useActivityDetailsWithTasks";

export default function ActivityTasksDialog(props) {
  const [tasks, setTasks] = useState([]);
  const [activityTasks, setActivityTasks] = useState([]);
  const [activityName, setActivityName] = useState();
  const [loadingIndicatorTasks, setLoadingIndicatorTasks] = useState(true);
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [filters] = useState({
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const { userList } = useUserList(accessToken);
  const { activityDetails } = useActivityDetailsWithTasks(
    props.activityId > 0 ? props.activityId : 0,
    accessToken
  );

  useLayoutEffect(() => {
    setLoadingIndicatorTasks(true);
    if (activityDetails && props.activityId > 0) {
      setActivityTasks(activityDetails.tasks);
      setActivityName(activityDetails.name);
    }
  }, [activityDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    resolveUserIds();
  }, [activityTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  const resolveUserIds = () => {
    const updatedTasks = activityTasks.map((task) => {
      const initiatorUser = userList.find(
        (user) => user.code === parseInt(task.initiatorUserId)
      );

      const assigneeUser = userList.find(
        (user) => user.code === parseInt(task.assigneeId)
      );

      let taskDetails = {
        id: task.id,
        name: task.name,
        customNodeName: task.customNodeName,
        dateRequested: task.dateRequested,
        status: task.status,
        activityName: activityDetails.name,
        templateId: activityDetails.templateId,
        folderId: activityDetails.folderId,
        initiatorName: initiatorUser ? initiatorUser.name : "",
        assigneeName: assigneeUser ? assigneeUser.name : "",
      };

      return taskDetails;
    });

    setTasks(updatedTasks);
    setLoadingIndicatorTasks(false);
  };

  const dateDataFormat = (rowData) => {
    return formatDate(rowData.dateRequested);
  };

  const statusDisplayTemplate = (data) => {
    if (data.status === "IN_PROGRESS") {
      return "IN PROGRESS";
    } else if (data.status === "NOT_STARTED") {
      return "NOT STARTED";
    } else if (data.status === "REJECT_AND_REVISE") {
      return "FOR REVISION";
    } else {
      return data.status;
    }
  };

  const statuses = [
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "FOR REVISION", value: "REJECT_AND_REVISE" },
    { label: "IN PROGRESS", value: "IN_PROGRESS" },
    { label: "REJECTED", value: "REJECTED" },
  ];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        className="p-column-filter"
        placeholder="Select a Status"
        value={options.value}
        options={statuses}
        optionValue="value"
        optionLabel="label"
        onChange={(e) => options.filterCallback(e.value)}
      />
    );
  };

  const actionTasksTemplate = (rowData) => {
    return rowData.status !== "CANCELLED" ? (
      <div className="actions flex-nowrap flex justify-content-center">
        <Link
          to={"/task/" + rowData.id}
          className="p-button p-button-icon-only p-button-text mr-2"
        >
          {rowData.status !== "COMPLETED" &&
            rowData.status !== "REJECT_AND_REVISE" &&
            rowData.status !== "REJECTED" && (
              <i className="pi pi-arrow-circle-right text-2xl font-light" />
            )}
          {rowData.status === "COMPLETED" && (
            <i className="pi pi-check-circle text-2xl font-light text-green-500" />
          )}
          {rowData.status === "REJECT_AND_REVISE" && (
            <i className="pi pi-exclamation-circle text-2xl font-light text-yellow-500" />
          )}
          {rowData.status === "REJECTED" && (
            <i className="pi pi-times-circle text-2xl font-light text-pink-500" />
          )}
        </Link>
      </div>
    ) : (
      <div></div>
    );
  };

  const taskNameDisplayTemplate = (rowData) => {
    if (rowData.customNodeName && rowData.customNodeName !== "< Node Name >") {
      return rowData.customNodeName;
    } else {
      return rowData.name;
    }
  };

  return (
    <>
      <Dialog
        header={
          <div>
            {activityName}&nbsp;(
            {activityDetails &&
              (activityDetails.status === "IN_PROGRESS"
                ? "IN PROGRESS"
                : activityDetails.status === "NOT_STARTED"
                ? "NOT STARTED"
                : activityDetails.status === "REJECT_AND_REVISE"
                ? "FOR REVISION"
                : activityDetails.status)}
            )
          </div>
        }
        visible={props.displayActivityTasks}
        onHide={props.onHide}
        resizable={false}
        draggable={false}
        blockScroll
        maximized={false}
        className="w-fit"
      >
        <DataTable
          value={tasks}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25]}
          className="p-datatable-gridlines"
          showGridlines
          dataKey="id"
          loading={loadingIndicatorTasks}
          responsiveLayout="scroll"
          emptyMessage="No pending tasks found."
          size="small"
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={["status"]}
        >
          <Column body={taskNameDisplayTemplate} header="Name" />
          <Column
            header="Assignee"
            field="assigneeName"
            showFilterMatchModes={false}
            filterMenuStyle={{ width: "14rem" }}
          />
          <Column
            header="Date Requested"
            field="dateRequested"
            dataType="date"
            bodyClassName="text-center"
            body={dateDataFormat}
            style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
          />
          <Column
            field="status"
            header="Status"
            showFilterMatchModes={false}
            body={statusDisplayTemplate}
            filter
            filterElement={statusFilterTemplate}
            style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
          />
          <Column
            body={actionTasksTemplate}
            bodyClassName="text-center"
            style={{ maxWidth: "8rem", width: "8rem", minWidth: "5rem" }}
          />
        </DataTable>
      </Dialog>
    </>
  );
}
