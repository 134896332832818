const initialNodes = [
  {
    id: "start_node",
    type: "terminalNode",
    data: { label: "START" },
    position: { x: 110, y: 0 },
  },
  {
    id: "end_node",
    type: "terminalNode",
    data: { label: "END" },
    position: { x: 110, y: 500 },
  },
];

const workflowJsonModel = {
  nodes: [
    {
      id: "start_node",
      position: {
        x: 110,
        y: 0,
      },
      type: "terminalNode",
      height: 33,
      width: 140,
      data: {
        label: "START",
        settings: [{}],
      },
    },
    {
      id: "end_node",
      position: {
        x: 110,
        y: 500,
      },
      type: "terminalNode",
      height: 33,
      width: 140,
      data: {
        label: "END",
        settings: [{}],
      },
    },
  ],
  edges: [],
};

function nodeTemplate(id, label, nodeType, userList) {
  const node = {
    id: id,
    type: nodeType,
    data: {
      label: label,
      allowEdit: true,
      assignUser: true,
      reviewMode: false,
      userList: userList,
      onAssignUserClick: (assigneeId) => {},
    },
    position: { x: 0, y: 50 },
  };

  return node;
}

function removeNodeWithEdges(nodes, edges, nodeId, getConnectedEdges) {
  const index = nodes
    .map(function (item) {
      return item.id;
    })
    .indexOf(nodeId);
  const deletedNode = nodes.splice(index, 1);

  const connectedEdges = getConnectedEdges(deletedNode, edges);

  const edgeList = edges
    .map((edge) => {
      if (connectedEdges.find((item) => item.id === edge.id)) {
        return false;
      } else {
        return edge;
      }
    })
    .filter(Boolean);

  return edgeList;
}

function removeOrphanEdges(nodes, edges) {
  const newList = edges
    .map((edge) => {
      // edge source must exist
      if (nodes.find((node) => node.id === edge.source)) {
        return edge;
      } else {
        return false;
      }
    })
    .filter(Boolean)
    .map((edge) => {
      // edge target must exist
      if (nodes.find((node) => node.id === edge.target)) {
        return edge;
      } else {
        return false;
      }
    })
    .filter(Boolean);

  return newList;
}

const connectionLine = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  connectionLineType,
  connectionLineStyle,
}) => {
  return (
    <g>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX} ${targetY}`}
      />
      <circle
        cx={targetX}
        cy={targetY}
        fill="#fff"
        r={3}
        stroke="#222"
        strokeWidth={1.5}
      />
    </g>
  );
};

export {
  initialNodes,
  nodeTemplate,
  connectionLine,
  workflowJsonModel,
  removeNodeWithEdges,
  removeOrphanEdges,
};
