import useSWR from "swr";
import useCurrentUser from "../hooks/useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_USER_TASKS } from "../graphQL/tasks";

export default function useTaskList(accessToken) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getUserTasks", userData.id],
    () =>
      queryGraphQL(GET_USER_TASKS, { userId: userData.id }, accessToken).then(
        (response) => response.tasks
      )
  );

  return {
    taskList: data,
    isLoading: !error && !data,
    isError: error,
    mutateTaskList: mutate,
  };
}
