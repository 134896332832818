import {
  deleteRequest,
  getRequest,
  getBlobRequest,
  postRequest,
} from "../common/apiHelper";

const getFiles = (accessToken) => {
  return getRequest("/files", accessToken);
};

const getFile = (id, accessToken) => {
  return getRequest("/files/" + id, accessToken);
};

const getBinaryFile = (id, accessToken) => {
  return getBlobRequest("/files/" + id + "/download/", accessToken);
};

const uploadFile = (data, accessToken) => {
  return postRequest("/files/upload", data, accessToken);
};

const moveFile = (id, data, accessToken) => {
  return postRequest("/files/" + id + "/move", data, accessToken);
};

const deleteFile = (id, accessToken) => {
  return deleteRequest("/files/" + id, accessToken);
};

const attachMainFiles = (id, data, accessToken) => {
  return postRequest("/activities/" + id + "/main-files", data, accessToken);
};

export {
  getFile,
  getFiles,
  uploadFile,
  moveFile,
  deleteFile,
  getBinaryFile,
  attachMainFiles,
};
