import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Handle,
  Position,
  useReactFlow,
  getConnectedEdges,
} from "react-flow-renderer";
import { confirmPopup } from "primereact/confirmpopup"; // To use confirmPopup method
import { ConfirmPopup } from "primereact/confirmpopup"; // To use <ConfirmPopup> tag
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { removeNodeWithEdges } from "../common/workflowHelper";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import useAuth from "../hooks/useAuth";
import useUserList from "../hooks/useUserList";

export default function WorkflowRouterNode(props) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [allowUpload, setAllowUpload] = useState(true);
  const [displayAssignee, setDisplayAssignee] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [displayRenameNode, setDisplayRenameNode] = useState(false);
  const [customNodeName, setCustomNodeName] = useState();
  const { userList } = useUserList(accessToken);
  const [extendedUserList, setExtendedUserList] = useState(userList);
  const { getNodes, setNodes, getEdges, setEdges } = useReactFlow();

  useEffect(() => {
    const users = [...userList];
    users.sort((a, b) => {
      return a.name - b.name;
    });

    setExtendedUserList(users);
  }, [userList]);

  useLayoutEffect(() => {
    setSelectedUser(props.data.taskAssignee);
    setCustomNodeName(props.data.customNodeName);
    setAllowUpload(props.data.allowUpload);
  }, [
    props.data.taskAssignee,
    props.data.customNodeName,
    props.data.allowUpload,
  ]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === props.id) {
          // it's important that you create a new object here
          // in order to notify react flow about the change
          node.data = {
            ...node.data,
            customNodeName: customNodeName,
            taskAssignee: selectedUser,
            allowUpload: allowUpload,
          };
        }

        return node;
      })
    );
  }, [customNodeName, selectedUser, allowUpload]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssignee = () => {
    setDisplayAssignee(true);
  };

  const handleRenameNode = () => {
    setDisplayRenameNode(true);
  };

  const onHide = () => {
    setDisplayAssignee(false);
    setDisplayRenameNode(false);
  };

  const handleRemoveNode = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to remove this step?",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => acceptFunc(),
      reject: () => rejectFunc(),
    });
  };

  const acceptFunc = () => {
    const nodes = getNodes();
    const edges = getEdges();

    const edgeList = removeNodeWithEdges(
      nodes,
      edges,
      props.id,
      getConnectedEdges
    );

    setNodes(nodes);
    setEdges(edgeList);
  };
  const rejectFunc = () => {};

  const defaultValues = {
    customNodeName: "< Node Name >",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const renameNode = () => {
    const formValue = getValues();
    setCustomNodeName(formValue.customNodeName);
    onHide();
  };

  const onResetNodeNameClick = () => {
    setValue("customNodeName", "< Node Name >");
    setCustomNodeName("< Node Name >");
  };

  return (
    <>
      <Tooltip target=".input-port" />
      <Handle
        type="target"
        position={Position.Top}
        data-pr-tooltip="INPUT"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{ fontSize: "2rem", cursor: "pointer" }}
        className="p-1 input-port"
      />
      <Tooltip target=".output-port-gray-e" />
      <Handle
        type="source"
        position={Position.Bottom}
        id="e"
        data-pr-tooltip="ROUTE 1"
        data-pr-position="top"
        data-pr-at="right top-6"
        style={{
          fontSize: "2rem",
          cursor: "pointer",
          left:
            props.type === "routerNode2"
              ? "20%"
              : props.type === "routerNode3"
              ? "15%"
              : "10%",
        }}
        className="p-1 output-port-gray output-port-gray-e"
      />

      {(props.type === "routerNode2" ||
        props.type === "routerNode3" ||
        props.type === "routerNode4") && (
        <>
          <Tooltip target=".output-port-gray-f" />
          <Handle
            type="source"
            position={Position.Bottom}
            id="f"
            data-pr-tooltip="ROUTE 2"
            data-pr-position="top"
            data-pr-at="right top-6"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
              left:
                props.type === "routerNode2"
                  ? "80%"
                  : props.type === "routerNode3"
                  ? "50%"
                  : "37%",
            }}
            className="p-1 output-port-gray output-port-gray-f"
          />
        </>
      )}

      {(props.type === "routerNode3" || props.type === "routerNode4") && (
        <>
          <Tooltip target=".output-port-gray-g" />
          <Handle
            type="source"
            position={Position.Bottom}
            id="g"
            data-pr-tooltip="ROUTE 3"
            data-pr-position="top"
            data-pr-at="right top-6"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
              left: props.type === "routerNode3" ? "85%" : "64%",
            }}
            className="p-1 output-port-gray output-port-gray-g"
          />
        </>
      )}
      {props.type === "routerNode4" && (
        <>
          <Tooltip target=".output-port-gray-h" />
          <Handle
            type="source"
            position={Position.Bottom}
            id="h"
            data-pr-tooltip="ROUTE 4"
            data-pr-position="top"
            data-pr-at="right top-6"
            style={{ fontSize: "2rem", cursor: "pointer", left: "90%" }}
            className="p-1 output-port-gray output-port-gray-h"
          />
        </>
      )}
      <div className="card mb-0 p-2 border-1 border-gray-600 w-15rem">
        <div className="flex justify-content-between">
          <div className="flex justify-content-start"></div>
          <div className="flex justify-content-end mb-2">
            {props.data.assignUser && (
              <>
                {!props.data.reviewMode && (
                  <i
                    className="pi pi-user-edit mr-2"
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={handleAssignee}
                  ></i>
                )}
              </>
            )}
            {props.data.allowEdit && (
              <i
                className="pi pi-pencil mr-2"
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleRenameNode}
              ></i>
            )}
            {props.data.allowEdit && (
              <>
                <i
                  className="pi pi-times"
                  style={{ color: "#f00", cursor: "pointer" }}
                  onClick={handleRemoveNode}
                ></i>
                <ConfirmPopup />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-content-end">
          <label className="text-gray-500 text-sm">{props.data.label}</label>
        </div>
        <div className="flex justify-content-center">
          <label className="mt-3">
            {props.data.customNodeName
              ? props.data.customNodeName
              : "< Node Name >"}
          </label>
        </div>

        <div className="flex justify-content-center">
          <label className="text-green-500">
            {props.data.taskAssignee
              ? props.data.taskAssignee.name
              : selectedUser?.name}
          </label>
        </div>
      </div>

      <Dialog
        header={props.data.label + " Task Assignee"}
        visible={displayAssignee}
        onHide={() => onHide()}
      >
        <Dropdown
          value={selectedUser}
          options={extendedUserList}
          onChange={(e) => setSelectedUser(e.value)}
          optionLabel="name"
          filter
          showClear
          filterBy="name"
          placeholder="Select assignee"
          className="min-w-full"
        />

        <div className="flex justify-content-center mt-3">
          <div className="flex justify-content-center">
            <Button
              label="Close"
              icon="pi pi-times"
              onClick={() => onHide()}
              className="p-button p-button-outlined mr-2"
            />
          </div>
          <div className="flex justify-content-center">
            <Button
              label="Set"
              icon="pi pi-check"
              type="submit"
              className="p-button ml-2"
              loading={props.loadingIndicatorButtons}
              onClick={() => {
                onHide();
                props.data.onAssignUserClick(selectedUser);
              }}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        header={"Rename Node"}
        visible={displayRenameNode}
        onHide={() => onHide()}
      >
        <form onSubmit={handleSubmit(renameNode)}>
          <div className="flex justify-content-between">
            <div className="text-base font-semibold">Current Node Name</div>
            <div className="field">
              {props.data.customNodeName
                ? props.data.customNodeName
                : "< Node Name >"}
            </div>
          </div>
          <br />
          <div className="field col">
            <span className="p-float-label">
              <Controller
                name="customNodeName"
                control={control}
                rules={{
                  required: "Node name is required.",
                  validate: (value) => {
                    return !!value.trim() || "Node name is required.";
                  },
                  nameMatches: (value) =>
                    value === "< Node Name >" || "Node name is incorrect.",
                }}
                render={({ field, fieldState }) => (
                  <>
                    <div className="p-inputgroup">
                      <InputText
                        id={field.customNodeName}
                        {...field}
                        className={classNames("w-18rem", {
                          "p-invalid": fieldState.error,
                        })}
                        //value={field.customNodeName}
                      />
                      <Button
                        icon="pi pi-times-circle"
                        type="submit"
                        label="Reset"
                        onClick={() => {
                          onHide();
                          onResetNodeNameClick();
                        }}
                        className="p-button p-button-danger"
                      />
                    </div>
                  </>
                )}
              />
            </span>
            {getFormErrorMessage("customNodeName")}
          </div>
          <div className="flex justify-content-center mt-3">
            <div className="flex justify-content-center">
              <Button
                label="Close"
                icon="pi pi-times"
                onClick={() => onHide()}
                className="p-button p-button-outlined mr-2"
              />
            </div>
            <div className="flex justify-content-center"></div>
            <div className="flex justify-content-center">
              <Button
                label="Set"
                icon="pi pi-check"
                type="submit"
                className="p-button ml-2"
              />
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}
