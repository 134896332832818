import React from "react";
import {
  getBezierPath,
  getEdgeCenter,
  useReactFlow,
  useNodes,
} from "react-flow-renderer";
import {
  getSmartEdge,
  svgDrawStraightLinePath,
  pathfindingAStarNoDiagonal,
} from "@tisoap/react-flow-smart-edge";

const foreignObjectSize = 20;

export default function WorkflowEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  let edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  let [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const pathOptions = {
    nodePadding: 2,
    gridRatio: 2,
    drawEdge: svgDrawStraightLinePath,
    generatePath: pathfindingAStarNoDiagonal,
  };
  const nodes = useNodes();
  const getSmartEdgeResponse = getSmartEdge({
    sourcePosition,
    targetPosition,
    sourceX,
    sourceY,
    targetX,
    targetY,
    nodes,
    pathOptions,
  });
  let svgPathString;
  if (getSmartEdgeResponse !== null) {
    ({ edgeCenterX, edgeCenterY, svgPathString } = getSmartEdgeResponse);

    edgePath = svgPathString;
  }

  const { getEdges, setEdges } = useReactFlow();

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();

    const edges = getEdges();
    const index = edges
      .map(function (item) {
        return item.id;
      })
      .indexOf(id);
    edges.splice(index, 1);

    setEdges(edges);
  };

  return (
    <>
      <path
        style={{
          ...style,
          strokeDasharray: 0,
          stroke: "transparent",
          strokeWidth: 20,
        }}
        className="react-flow__edge-path-selector"
        d={edgePath}
      />
      <path
        id={id}
        style={{
          ...style,
          strokeDasharray: "7 4",
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {data.allowEdit && (
        <foreignObject
          width={foreignObjectSize}
          height={foreignObjectSize}
          x={edgeCenterX - foreignObjectSize / 2}
          y={edgeCenterY - foreignObjectSize / 2}
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <button
            className="edgebutton"
            onClick={(event) => onEdgeClick(event, id)}
          >
            ×
          </button>
        </foreignObject>
      )}
    </>
  );
}
