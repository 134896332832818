import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { nodeTemplate, removeOrphanEdges } from "../common/workflowHelper";
import { getUUID } from "../common/utilities";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import useAuth from "../hooks/useAuth";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { getDepartments } from "../services/apiDepartments";
import { createWorkflow, updateWorkflow } from "../services/apiWorkflow";
import {
  actionCancel,
  dialogMessageList,
  actionSuccess,
} from "../common/dialogMessages";
import useUserList from "../hooks/useUserList";
import useWorkflowDetails from "../hooks/useWorkflowDetails";

export default function WorkflowToolbar({
  nodes,
  setNodes,
  edges,
  workflowId,
}) {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [showTemplateName, setShowTemplateName] = useState(false);
  const [loadingIndicatorButtons, setLoadingIndicatorButtons] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const { getToken } = useAuth();
  const accessToken = getToken();

  const { userList } = useUserList(accessToken);
  const { workflowDetails } = useWorkflowDetails(workflowId, accessToken);

  const handleShowTemplateName = () => {
    setShowTemplateName(true);
  };

  const defaultValues = {
    workflowName: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const createNewWorkflow = (data) => {
    setLoadingIndicatorButtons(true);

    const targetWorkflow = {
      active: workflowDetails.active,
      departmentId: workflowDetails.departmentId,
      id: workflowDetails.id,
      name: workflowDetails.name,
      createdById: workflowDetails.createdById,
      updatedById: workflowDetails.updatedById,
      workflow: workflowDetails.workflow,
    };

    targetWorkflow.name = data.workflowName;
    targetWorkflow.departmentId = selectedDepartment.id;

    delete targetWorkflow["createdBy"];
    delete targetWorkflow["createdById"];
    delete targetWorkflow["updatedBy"];
    delete targetWorkflow["updatedById"];
    delete targetWorkflow["createdDate"];
    delete targetWorkflow["updatedDate"];
    delete targetWorkflow["id"];

    createWorkflow(targetWorkflow, accessToken)
      .then((newResponse) => {
        onHide();

        navigate("/designer/" + newResponse.id, { replace: true });

        toast.current.show(
          actionSuccess(dialogMessageList.WorkflowDuplicateSuccessful)
        );
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const onHide = () => {
    reset();
    setShowTemplateName(false);
    setLoadingIndicatorButtons(false);
    toast.current.show(actionCancel(dialogMessageList.UserCanceled));
  };

  const renderNewHeader = () => {
    return "Copy As...";
  };

  const handleSaveTemplate = () => {
    const workflowUpdate = {
      nodes: nodes,
      edges: edges,
    };

    const targetWorkflow = {
      active: workflowDetails.active,
      departmentId: workflowDetails.departmentId,
      id: workflowDetails.id,
      name: workflowDetails.name,
      createdById: workflowDetails.createdById,
      updatedById: workflowDetails.updatedById,
      workflow: workflowDetails.workflow,
    };

    workflowUpdate.edges = removeOrphanEdges(nodes, edges);
    const workflowUpdateSlim = workflowUpdate.nodes.map((node) => {
      delete node.data["userList"];
      return node;
    });

    workflowUpdate.nodes = workflowUpdateSlim;
    targetWorkflow.workflow = JSON.stringify(workflowUpdate);

    delete targetWorkflow["createdBy"];
    delete targetWorkflow["createdById"];
    delete targetWorkflow["updatedBy"];
    delete targetWorkflow["updatedById"];
    delete targetWorkflow["createdDate"];
    delete targetWorkflow["updatedDate"];

    updateWorkflow(targetWorkflow, accessToken)
      .then((updateResponse) => {
        toast.current.show(
          actionSuccess(dialogMessageList.WorkflowSaveSuccessful)
        );
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };

  const workflowStepsMenu = [
    {
      label: "Template Options",
      items: [
        {
          label: "Save",
          icon: "pi pi-fw pi-save",
          command: () => {
            handleSaveTemplate();
          },
        },
        {
          label: "Duplicate",
          icon: "pi pi-fw pi-copy",
          command: () => {
            handleShowTemplateName();
          },
        },
        // {
        //   label: "Validate Connections",
        //   icon: "pi pi-fw pi-bolt rotate-45",
        //   disabled: true,
        // },
      ],
    },
    {
      separator: true,
    },
    {
      label: "Steps Available",
      items: [
        {
          label: "Notification",
          icon: "pi pi-fw pi-bell",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Notification",
              "notificationNode",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Document Selection",
          icon: "pi pi-fw pi-paperclip",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Document Selection",
              "basicNode",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Review Only",
          icon: "pi pi-fw pi-search",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Review",
              "reviewNode1",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Review (with Revision)",
          icon: "pi pi-fw pi-search",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Review (with Revision)",
              "reviewNode",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Basic Approval",
          icon: "pi pi-fw pi-check-square",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Basic Approval",
              "basicApprovalNode",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Final Approval",
          icon: "pi pi-fw pi-thumbs-up",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Final Approval",
              "finalApprovalNode",
              userList
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Route (2 Options)",
          icon: "pi pi-fw pi-reply",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Route (2 Options)",
              "routerNode2",
              userList,
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Route (3 Options)",
          icon: "pi pi-fw pi-reply",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Route (3 Options)",
              "routerNode3",
              userList,
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
        {
          label: "Route (4 Options)",
          icon: "pi pi-fw pi-reply",
          command: (event) => {
            let newNode = nodeTemplate(
              getUUID(),
              "Route (4 Options)",
              "routerNode4",
              userList,
            );
            setNodes((nds) => nds.concat(newNode));
          },
        },
      ],
    },
  ];

  useEffect(() => {
    getDepartments(accessToken)
      .then((response) => {
        setDepartments(response);
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDepartmentSelection = (e) => {
    setValue(e.target.id, e.value.id);
    setSelectedDepartment(e.value);
  };

  const menu = useRef(null);

  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />
      <Button
        icon="pi pi-th-large"
        onClick={(event) => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        className="absolute z-5 ml-2 mt-2"
      />
      <Menu
        model={workflowStepsMenu}
        className="w-16rem z-5 opacity-90"
        popup
        ref={menu}
        id="popup_menu"
      />

      <Dialog
        header={renderNewHeader()}
        visible={showTemplateName}
        onHide={() => onHide()}
        resizable={false}
        draggable={true}
        maximized={false}
        style={{ maxWidth: 900, maxHeight: 800 }}
      >
        {showTemplateName && (
          <form onSubmit={handleSubmit(createNewWorkflow)}>
            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="workflowName"
                  control={control}
                  rules={{
                    required: "Template name is required.",
                    validate: (value) => {
                      return !!value.trim() || "Template name is required.";
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.workflowName}
                      {...field}
                      className={classNames("w-18rem", {
                        "p-invalid": fieldState.error,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="workflowName"
                  className={classNames({ "p-error": errors.name })}
                >
                  Template Name *
                </label>
              </span>
              {getFormErrorMessage("workflowName")}
            </div>

            <div className="field col">
              <span className="p-float-label">
                <Controller
                  name="departmentId"
                  control={control}
                  rules={{
                    required: "Department is required.",
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Dropdown
                        className="w-full"
                        id="departmentId"
                        value={selectedDepartment}
                        onChange={handleDepartmentSelection}
                        options={departments}
                        optionLabel="name"
                        showClear
                      />
                      <label htmlFor="departmentId">Department</label>
                    </>
                  )}
                />
              </span>
              {getFormErrorMessage("departmentId")}
            </div>
            <div className="flex justify-content-evenly">
              <div className="flex justify-content-evenly">
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  onClick={() => onHide()}
                  className="p-button p-button-outlined"
                />
              </div>
              <div className="flex justify-content-evenly">
                <Button
                  label="Save"
                  icon="pi pi-check"
                  type="submit"
                  className="p-button"
                  loading={loadingIndicatorButtons}
                />
              </div>
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
}
