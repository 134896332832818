import React, { useContext, useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
// import { Button } from "primereact/button";
import FolderNavigator from "../components/FolderNavigator";

import { SelectedFolderContext } from "../contexts/SelectedFolderContext";

export default function SelectedFolder(props) {
  const folderSelectorOverlayPanel = useRef(null);
  const { selectedFolder } = useContext(SelectedFolderContext);

  return (
    <>
      <div className="ml-3 mb-2">
        <div className="flex flex-row align-items-center">
          Current Folder:
          {/* <Button
            type="button"
            icon="pi pi-folder-open"
            onClick={(e) => {
              // clear fields
              folderSelectorOverlayPanel.current.toggle(e);
            }}
            className="p-button mx-2"
          /> */}
          {selectedFolder.label === undefined ? (
            <span className="font-medium text-pink-500 ml-2">{`<None Selected>`}</span>
          ) : (
            <span className="text-2xl font-medium ml-2">{selectedFolder.label}</span>
          )}
        </div>
      </div>
      <OverlayPanel
        ref={folderSelectorOverlayPanel}
        style={{ width: "auto" }}
        showCloseIcon
      >
        <FolderNavigator readOnlyMode={props.readOnlyMode} />
      </OverlayPanel>
    </>
  );
}
