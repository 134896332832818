import axios from "axios";

const APIEndpointBaseURI = process.env.REACT_APP_API_BASE;
const IdPAPIEndpointBaseURI = process.env.REACT_APP_IDP_API_BASE;

const postRequestLogin = (endPoint, data) => {
  return axios
    .post(IdPAPIEndpointBaseURI + "/auth" + endPoint, data)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

function configHeader(accessToken) {
  return {
    headers: {
      Authorization: "Bearer " + accessToken,
      ResponseType: "application/json",
    },
  };
}

const getBlobRequest = (endPoint, accessToken) => {
  return axios.get(APIEndpointBaseURI + endPoint, {
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    transformResponse: [
      function (data) {
        let blob = new window.Blob([data], { type: "application/pdf" });
        return window.URL.createObjectURL(blob);
      },
    ],
  });
};

const getRequest = (endPoint, accessToken) => {
  return axios
    .get(APIEndpointBaseURI + endPoint, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

const getIdPRequest = (endPoint, accessToken) => {
  return axios
    .get(IdPAPIEndpointBaseURI + endPoint, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

const putRequest = (endPoint, data, accessToken) => {
  return axios
    .put(APIEndpointBaseURI + endPoint, data, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

const patchRequest = (endPoint, data, accessToken) => {
  return axios
    .patch(APIEndpointBaseURI + endPoint, data, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

const postRequest = (endPoint, data, accessToken) => {
  return axios
    .post(APIEndpointBaseURI + endPoint, data, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

const deleteRequest = (endPoint, accessToken) => {
  return axios
    .delete(APIEndpointBaseURI + endPoint, configHeader(accessToken))
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    })
    .finally(() => {});
};

export {
  getRequest,
  getBlobRequest,
  putRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  postRequestLogin,
  getIdPRequest
};
