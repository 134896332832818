import { Divider } from "primereact/divider";
import { Steps } from "primereact/steps";

export default function StepIndicators(props) {
  return (
    <>
      <Divider align="left" type="solid">
        <b>Steps</b>
      </Divider>
      <div className="mx-5">
        <Steps model={props.steps} activeIndex={props.activeIndex} />
      </div>
      <Divider type="solid" />
    </>
  );
}
