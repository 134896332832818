import { gql } from "@apollo/client";

export const GET_USER_ACTIVITIES = gql`
  query ($userId: BigInt) {
    activities(
      where: { createdById: { equals: $userId } }
      orderBy: { createdDate: desc }
    ) {
      id
      name
      startTime
      createdDate
      status
      alternateOICId
      createdBy
      createdById
      templateId
      collaborators {
        id
      }
      _count {
        tasks
      }
      tasks {
        id
        status
      }
    }
  }
`;

export const COUNT_USER_ACTIVITIES = gql`
  query ($userId: BigInt) {
    activities(
      where: { createdById: { equals: $userId } }
      orderBy: { createdDate: desc }
    ) {
      id
    }
  }
`;

export const USER_ACTIVITIES_WITH_FINAL_APPROVAL = gql`
  query ($userId: BigInt) {
    activities(
      where: {
        AND: {
          createdById: { equals: $userId }
          workflow: { string_contains: "finalApprovalNode" }
          status: { notIn: [COMPLETED, CANCELLED] }
        }
      }
    ) {
      id
    }
  }
`;

export const GET_ACTIVITY_DETAILS = gql`
  query ($activityId: BigInt) {
    activities(where: { id: { equals: $activityId } }) {
      id
      name
      templateId
      collaborators {
        collaboratorId
      }
      workflow
      taskAssignees
      alternateOICId
      createdById
    }
  }
`;

export const GET_ACTIVITY_DETAILS_WITH_TASKS = gql`
  query ($activityId: BigInt) {
    activities(where: { id: { equals: $activityId } }) {
      id
      taskAssignees
      name
      status
      createdById
      alternateOICId
      workflow
      templateId
      folderId
      mainFiles {
        id
        name
        folderId
        urlPath
        originalName
      }
      collaborators {
        collaboratorId
      }
      tasks {
        id
        name
        customNodeName
        status
        activityId
        assigneeId
        initiatorUserId
        dateRequested
        createdDate
        startTime
      }
    }
  }
`;

export const GET_ACTIVITY_COLLABORATORS = gql`
  query ($userId: BigInt!, $filter: String) {
    activities(
      where: {
        OR: [
          { alternateOICId: { equals: $userId } }
          { collaborators: { some: { collaboratorId: { in: [$userId] } } } }
          { taskAssignees: { string_contains: $filter } }
        ]
      }
      orderBy: { createdDate: desc }
    ) {
      id
      name
      createdDate
      status
      createdBy
      createdById
    }
  }
`;

export const GET_ACTIVITY_COMMENTS = gql`
  query ($activityId: BigInt) {
    activityComments(
      where: { activityId: { equals: $activityId } }
      orderBy: { createdDate: asc }
    ) {
      id
      message
      createdBy
      createdById
      createdDate
      attachments {
        id
        folderId
        name
      }
    }
  }
`;

export const GET_ACTIVITY_OIC = gql`
  query ($id: BigInt) {
    activity(where: { id: $id }) {
      id
      name
      alternateOICId
    }
  }
`;

export const DELETE_ACTIVITY_COMMENT = gql`
  mutation deleteOneActivityComment($id: BigInt) {
    deleteOneActivityComment(where: { id: $id }) {
      id
      message
      createdBy
      createdById
      createdDate
    }
  }
`;

export const DELETE_ACTIVITY_COLLABORATORS = gql`
  mutation deleteManyCollaborator($activityId: BigInt) {
    deleteManyCollaborator(where: { activityId: { equals: $activityId } }) {
      count
    }
  }
`;

export const CREATE_ACTIVITY_COLLABORATORS = gql`
  mutation createManyCollaborator($data: [CollaboratorCreateManyInput!]!) {
    createManyCollaborator(data: $data) {
      count
    }
  }
`;

export const UPDATE_ACTIVITY = gql`
  mutation updateOneActivity($data: ActivityUpdateInput!, $id: BigInt!) {
    updateOneActivity(data: $data, where: { id: $id }) {
      id
      name
      alternateOICId
    }
  }
`;
