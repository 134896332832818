import { getIdPRequest } from "../common/apiHelper";

const getDepartments = (accessToken) => {
  return getIdPRequest("/departments", accessToken);
};

const getDepartment = (id, accessToken) => {
  return getIdPRequest("/departments/" + id, accessToken);
};

export { getDepartments, getDepartment };
