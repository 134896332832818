import { graphQLClient } from "../common/graphqlHelper";

const queryGraphQL = (query, variables, accessToken) => {
  return graphQLClient(accessToken)
    .query({ query: query, variables: variables })
    .then((response) => {
      return response.data;
    });
};

const mutateGraphQL = (mutation, variables, accessToken) => {
  return graphQLClient(accessToken)
    .mutate({ mutation: mutation, variables: variables })
    .then((response) => {
      return response.data;
    });
};

export { queryGraphQL, mutateGraphQL };
