import React, { useState, useEffect, useContext, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataView } from "primereact/dataview";
import { getFolderDetails } from "../services/apiFolders";
import useAuth from "../hooks/useAuth";
import BulkUploader from "../components/BulkUploader";
import FileListItem from "../components/FileListItem";

import { SelectedFolderContext } from "../contexts/SelectedFolderContext";
import { RefreshFolderContentContext } from "../contexts/RefreshFolderContentContext";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

export default function FileList(props) {
  const [dataviewValue, setDataviewValue] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [first, setFirst] = useState(0);
  const { getToken } = useAuth();
  const accessToken = getToken();
  const rows = useRef(10);
  const dataSource = useRef(null);

  const { selectedFolder } = useContext(SelectedFolderContext);
  const { shouldRefreshContents, setShouldRefreshContents } = useContext(
    RefreshFolderContentContext
  );

  useEffect(() => {
    getFolderContents(selectedFolder.key);
  }, [selectedFolder, shouldRefreshContents]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFolderContents = (folderId) => {
    if (folderId) {
      setLoadingIndicator(true);
      getFolderDetails(folderId, accessToken)
        .then((response) => {
          dataSource.current = response.files.sort((a, b) => {
            return b.id - a.id;
          });
          setTotalRecords(response.files.length);

          return response.files;
        })
        .then(() => {
          setFirst(0);
          setDataviewValue(dataSource.current.slice(0, rows.current));
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          setLoadingIndicator(false);
          setShouldRefreshContents(false);
        });
    }
  };

  const onPage = (event) => {
    setLoadingIndicator(true);

    const startIndex = event.first;
    const endIndex = Math.min(event.first + rows.current, totalRecords);
    const newProducts =
      startIndex === endIndex
        ? dataSource.current.slice(startIndex)
        : dataSource.current.slice(startIndex, endIndex);

    setFirst(startIndex);
    setDataviewValue(newProducts);
    setLoadingIndicator(false);
  };

  const itemTemplate = (data, layout) => {
    if (!data) {
      return;
    }

    return (
      <FileListItem
        id={data.id}
        name={data.name}
        metadata={data.fileMetaData}
        displayMode={layout}
        readOnlyMode={props.readOnlyMode}
      />
    );
  };

  const rowTemplate = (data) => {
    return (
      <FileListItem
        id={data.id}
        name={data.name}
        metadata={data.fileMetaData}
        displayMode="list"
        readOnlyMode={props.readOnlyMode}
      />
    );
  };

  const handleFileSelection = (data) => {
    props.onFileSelect(data);
  };

  const dataviewHeader = (
    <div className="grid grid-nogutter">
      <div className="col-12 flex justify-content-end p-fluid">
        {(selectedFolder?.data?.folderType !== "SHARED" &&
          selectedFolder?.data?.folderType !== "PUBLIC") && <BulkUploader />}
      </div>
    </div>
  );

  const dataviewFooter = (
    <>
      {props.showSelectedFiles && (
        <div
          className={classNames(
            "text-white p-3 flex justify-content-between align-items-center flex-wrap",
            !props.selectedFile || props.selectedFile?.length === 0
              ? "bg-pink-500"
              : "bg-green-400"
          )}
          style={{ borderRadius: "8px" }}
        >
          {props.selectedFile &&
            (props.selectedFile.length === 1 ? (
              <div>Selected file</div>
            ) : props.selectedFile.length > 1 ? (
              <div>Selected file(s)</div>
            ) : (
              ``
            ))}

          {(!props.selectedFile || props.selectedFile?.length === 0) && (
            <div>No files selected!</div>
          )}

          <div className="align-items-center flex"></div>
        </div>
      )}
      {props.showSelectedFiles &&
        props.selectedFile?.map((file, index) => {
          return (
            <FileListItem
              key={index}
              id={file.id}
              name={file.name}
              metadata={file.fileMetaData}
              displayMode="list"
              readOnlyMode={props.readOnlyMode}
              showRemoveIcon
              removeFromList={props.onFileRemove}
            />
          );
        })}
    </>
  );

  return (
    <>
      {dataviewValue !== null && (
        <>
          {!props.readOnlyMode && (
            <div className="w-full">
              <DataView
                value={dataviewValue}
                layout={"list"}
                emptyMessage={"No file(s) found."}
                loading={loadingIndicator}
                lazy
                paginator
                totalRecords={totalRecords}
                rows={rows.current}
                itemTemplate={itemTemplate}
                header={dataviewHeader}
                first={first}
                onPage={onPage}
                footer={dataviewFooter}
                width="100%"
              ></DataView>
            </div>
          )}

          {props.readOnlyMode && (
            <div className="w-full">
              <DataTable
                size="small"
                value={dataviewValue}
                emptyMessage={"No file(s) found."}
                loading={loadingIndicator}
                lazy
                paginator
                totalRecords={totalRecords}
                rows={rows.current}
                header={dataviewHeader}
                first={first}
                onPage={onPage}
                footer={dataviewFooter}
                dataKey="id"
                rowHover
                selection={props.selectedFile}
                selectionMode="checkbox"
                onSelectionChange={(e) => handleFileSelection(e.value)}
                width="100%"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3em", display: "none" }}
                  bodyStyle={{ paddingLeft: "1rem" }}
                />
                <Column body={rowTemplate} headerStyle={{ display: "none" }} />
              </DataTable>
            </div>
          )}
        </>
      )}
    </>
  );
}
