import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import { saveDraftActivity } from "../services/apiActivities";
import useUserList from "../hooks/useUserList";
import TemplateSelection from "../components/TemplateSelection";
import CollaboratorSelection from "../components/CollaboratorSelection";
import ReviewActivityDetails from "../components/ReviewActivityDetails";
import WizardButtons from "../components/WizardButtons";
import StepIndicators from "../components/StepIndicators";
import useAuth from "../hooks/useAuth";
import useWorkflowDetails from "../hooks/useWorkflowDetails";

import { useNavigate } from "react-router-dom";
import { actionSuccess, dialogMessageList } from "../common/dialogMessages";

const defaultValues = {
  activityName: "",
};

const steps = [
  {
    label: "Template Selection",
  },
  {
    label: "Set Participants",
  },
  {
    label: "Review Details",
  },
];

export default function ActivityWizard() {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedWorkflow, setSelectedWorkflow] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [workflowHasFinalApprovalNode, setWorkflowHasFinalApprovalNode] =
    useState(false);

  const { userList } = useUserList(accessToken);
  const { workflowDetails } = useWorkflowDetails(
    selectedWorkflow?.code,
    accessToken
  );

  const [picklistSourceValue, setPicklistSourceValue] = useState(
    userList?.sort((current, next) => {
      return current.name.localeCompare(next.name);
    })
  );
  const [picklistTargetValue, setPicklistTargetValue] = useState([]);

  const navigate = useNavigate();

  const toast = useRef(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    if (userList) {
      setPicklistSourceValue(
        userList?.sort((current, next) => {
          return current.name.localeCompare(next.name);
        })
      );
    }
  }, [userList]);

  useEffect(() => {
    if (workflowDetails && selectedWorkflow && selectedWorkflow.code > 0) {
      const workflow = JSON.parse(workflowDetails.workflow);

      setNodes(workflow.nodes);
      setEdges(workflow.edges);

      const hasFinalApprovalNode =
        workflow.nodes.find((node) => node.type === "finalApprovalNode") !==
        undefined;

      setWorkflowHasFinalApprovalNode(hasFinalApprovalNode);

      let newList = [];
      for (let i = 0; i < workflow.nodes.length; i++) {
        const node = workflow.nodes[i];

        if (node.type !== "terminalNode") {
          newList.push({
            node: {
              nodeId: node.id,
              label: node.data.label,
            },
            user: node.data.taskAssignee
              ? node.data.taskAssignee
              : { name: "", code: 0 },
          });
        }
      }

      setTaskAssignees(newList);
    }
  }, [workflowDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePreviousStep = () => {
    let index = activeIndex;
    if (index <= 1) {
      index = 1;
    }
    setActiveIndex(index - 1);
  };

  const handleNextStep = () => {
    let isValid = false;
    if (activeIndex === 0) {
      const activityNameValidation = getFieldState("activityName");

      isValid =
        activityNameValidation.isTouched &&
        !activityNameValidation.error &&
        selectedWorkflow?.hasOwnProperty("name");
    } else if (activeIndex === 1) {
      isValid = true;
    } else if (activeIndex === 2) {
    }

    let index = activeIndex;
    if (index >= 3) {
      index = 3;
    }

    if (isValid) {
      setActiveIndex(index + 1);
    }
  };

  const onSubmit = (data) => {
    // console.log(data);
  };

  const handleSubmitStep = () => {
    setLoading(true);
    const taskUsers = taskAssignees.map((record) => {
      return { nodeId: record.node.nodeId, userId: record.user.code };
    });

    const activitySettings = getValues();

    const newActivity = {
      name: activitySettings.activityName,
      workflowTemplateId: selectedWorkflow.code,
      collaboratorUserIds: picklistTargetValue.map((user) => {
        return user.code;
      }),
      taskAssignees: taskUsers,
      alternateOICId: null,
    };

    saveDraftActivity(newActivity, accessToken)
      .then((response) => {
        if (response.id > 0) {
          toast.current.show(
            actionSuccess(dialogMessageList.ActivityCreateSuccessful)
          );

          setTimeout(() => {
            navigate("/", { replace: false });
          }, 2000);
        }
      })
      .catch((e) => {
        console.log("e: ", e);
        setLoading(false);
      });
  };

  const handleWorkflowTemplateSelection = (e) => {
    setSelectedWorkflow(e.value);
  };

  const handleCollaboratorsUpdate = (e) => {
    setPicklistSourceValue(
      e.source.sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );
    setPicklistTargetValue(
      e.target.sort((current, next) => {
        return current.name.localeCompare(next.name);
      })
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="grid">
        <div className="col-12">
          <div className="card">
            <h3 className="m-3 mb-4">New Activity</h3>

            <StepIndicators steps={steps} activeIndex={activeIndex} />

            <form onSubmit={handleSubmit(onSubmit)}>
              {activeIndex === 0 && (
                <>
                  <TemplateSelection
                    selectedWorkflow={selectedWorkflow}
                    onWorkflowTemplateSelection={
                      handleWorkflowTemplateSelection
                    }
                    nodes={nodes}
                    edges={edges}
                    control={control}
                    errors={errors}
                    getFormErrorMessage={getFormErrorMessage}
                    taskAssignees={taskAssignees}
                    setValue={setValue}
                  />
                </>
              )}

              {activeIndex === 1 && (
                <>
                  <CollaboratorSelection
                    picklistSourceValue={picklistSourceValue}
                    picklistTargetValue={picklistTargetValue}
                    onCollaboratorsUpdate={handleCollaboratorsUpdate}
                  />
                </>
              )}

              {activeIndex === 2 && (
                <>
                  <ReviewActivityDetails
                    selectedWorkflow={selectedWorkflow}
                    selectedOIC={null}
                    workflowHasFinalApprovalNode={workflowHasFinalApprovalNode}
                    nodes={nodes}
                    edges={edges}
                    picklistTargetValue={picklistTargetValue}
                    getValues={getValues}
                    taskAssignees={taskAssignees}
                  />
                </>
              )}

              <WizardButtons
                activeIndex={activeIndex}
                onPreviousStep={handlePreviousStep}
                onNextStep={handleNextStep}
                onSubmitStep={handleSubmitStep}
                isLoading={loading}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
