import { Button } from "primereact/button";

export default function WizardButtons(props) {
  return (
    <>
      <div className="flex justify-content-between">
        {props.activeIndex > 0 && (
          <Button
            label="Previous Step"
            icon="pi pi-chevron-left"
            disabled={props.activeIndex === 0}
            onClick={props.onPreviousStep}
          />
        )}
        {props.activeIndex === 0 && (
          <>
            <span>&nbsp;</span>
          </>
        )}
        {props.activeIndex <= 1 && (
          <Button
            type="submit"
            label="Next Step"
            iconPos="right"
            icon="pi pi-chevron-right"
            onClick={props.onNextStep}
          />
        )}
        {props.activeIndex === 2 && (
          <Button
            label="Save Draft"
            icon="pi pi-check"
            onClick={props.onSubmitStep}
            loading={props.isLoading}
          />
        )}
      </div>
    </>
  );
}
