import React, { useState } from "react";

const SignatureContext = React.createContext();

const SignatureProvider = (props) => {
  const [userSignatures, setUserSignatures] = useState([]);

  return (
    <SignatureContext.Provider value={{ userSignatures, setUserSignatures }}>
      {props.children}
    </SignatureContext.Provider>
  );
};

export { SignatureContext, SignatureProvider };
