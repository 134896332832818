import jwt_decode from "jwt-decode";

function getUserIdClaim(accessToken) {
  var decoded = jwt_decode(accessToken);
  return parseInt(decoded.id);
}

function getUsernameClaim(accessToken) {
  var decoded = jwt_decode(accessToken);
  return decoded.sub;
}

function getPermissionsClaim(accessToken) {
  var decoded = jwt_decode(accessToken);
  return decoded.permissions;
}

function isTokenExpired(accessToken) {
  if (accessToken.length > 0) {
    var decoded = jwt_decode(accessToken);
    return Date.now() >= decoded.exp * 1000;
  } else {
    return true;
  }
}

function IsUserRoleAllowed(allowedRoles, userRoles) {
  let flag = false;
  allowedRoles
    .toString()
    .split("|")
    .forEach((role) => {
      if (!flag && userRoles.includes(role)) {
        flag = true;
      }
    });
  return flag;
}

function IsFeatureEnabled(featureFlag) {
  // add logic as needed

  // use JSON.parse() to convert bool string to bool type
  return JSON.parse(featureFlag);
}

function getUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-mixed-operators
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

function formatDate(value) {
  const date = new Date(value);

  return date.toLocaleString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

function getIndexOccurrence(string, char, nth) {
  var first_index = string.indexOf(char);
  var length_up_to_first_index = first_index + 1;

  if (nth === 1) {
    return first_index;
  } else {
    var string_after_first_occurrence = string.slice(length_up_to_first_index);
    var next_occurrence = getIndexOccurrence(
      string_after_first_occurrence,
      char,
      nth - 1
    );

    if (next_occurrence === -1) {
      return -1;
    } else {
      return length_up_to_first_index + next_occurrence;
    }
  }
}

function formatFilename(filename) {
  const lastPart = filename.substring(getIndexOccurrence(filename, "-", 3) + 1); // rand-name.pdf
  const origName = lastPart.substring(getIndexOccurrence(lastPart, "-", 1) + 1); // name.pdf
  const origNameWithoutExtension = origName.substring(0, origName.length - 4); // name
  const origNameExtension = origName.substring(origName.length - 4); // ext
  const newName =
    origNameWithoutExtension +
    origNameExtension;

  return newName;
}

function getFilenameDate(filename) {
  const lastPart = filename.substring(getIndexOccurrence(filename, "-", 2) + 1); // time-rand-name.pdf
  // time-rand-name.pdf
  const timestamp = lastPart.substring(0, getIndexOccurrence(lastPart, "-", 1)); // time
  return formatDate(new Date(parseInt(timestamp)));
}

function getFilenameFriendlyISODate(filename) {
  // from: file-admin-time-rand-name.pdf
  // to:   YYYY-MM-DD HH:MM:SS
  const lastPart = filename.substring(getIndexOccurrence(filename, "-", 2) + 1); // time-rand-name.pdf

  const timestamp = lastPart.substring(0, getIndexOccurrence(lastPart, "-", 1)); // time
  const timestampDate = new Date(parseInt(timestamp));
  return (
    timestampDate.getFullYear() +
    "-" +
    (timestampDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    timestampDate.getDate().toString().padStart(2, "0") +
    " " +
    timestampDate.getHours().toString().padStart(2, "0") +
    ":" +
    timestampDate.getMinutes().toString().padStart(2, "0") +
    ":" +
    timestampDate.getSeconds().toString().padStart(2, "0")
  );
}

function isObject(object) {
  return object != null && typeof object === "object";
}

const formatUserOption = (user) => {
  return { name: user.lastName + ", " + user.firstName, code: user.id };
};

const taskAssigneeGraphQlFilterString = (userId) => {
  // eslint-disable-next-line no-useless-escape
  return `,\\\\\"userId\\\\\":${userId}}`;
};

export {
  getUserIdClaim,
  getUsernameClaim,
  getPermissionsClaim,
  IsUserRoleAllowed,
  IsFeatureEnabled,
  getUUID,
  isTokenExpired,
  formatDate,
  getIndexOccurrence,
  round,
  formatFilename,
  getFilenameDate,
  getFilenameFriendlyISODate,
  isObject,
  formatUserOption,
  taskAssigneeGraphQlFilterString,
};
