import useSWR from "swr";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_ACTIVITY_COMMENTS } from "../graphQL/activities";

export default function useActivityComments(activityId, accessToken) {
  const { data, error, mutate } = useSWR(["getActivityComments", activityId], () =>
    queryGraphQL(
      GET_ACTIVITY_COMMENTS,
      { activityId: activityId },
      accessToken
    ).then((response) => response.activityComments)
  );

  return {
    activityComments: data,
    isLoading: !error && !data,
    isError: error,
    mutateActivityComments: mutate,
  };
}
