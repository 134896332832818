import { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useTaskList from "../hooks/useTaskList";
import { formatDate } from "../common/utilities";
import { Dropdown } from "primereact/dropdown";
import { UserSettingsContext } from "../contexts/UserSettingsContext";
import { Tooltip } from "primereact/tooltip";

export default function TaskList(props) {
  const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { getToken } = useAuth();
  const accessToken = getToken();

  const [loadingIndicatorTasks, setLoadingIndicatorTasks] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [filters, setFilters] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [offset, setOffset] = useState(0);
  const { taskList: userTasks } = useTaskList(accessToken);

  useEffect(() => {
    if (userTasks) {
      const updateUserTasks = userTasks.map((task) => {
        const newTaskJson = JSON.stringify(task);
        let newTaskObj = JSON.parse(newTaskJson);

        newTaskObj["displayName"] = task.name;
        if (task.customNodeName && task.customNodeName !== "< Node Name >") {
          newTaskObj["displayName"] = task.customNodeName;
        }
        return newTaskObj;
      });

      setTasks(updateUserTasks);
      setLoadingIndicatorTasks(false);

      setFilters(userSettings.filters.taskLists);
      setPageSize(userSettings.pageSize);
    }
  }, [userTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  const dateDataFormat = (rowData) => {
    return formatDate(rowData.dateRequested);
  };

  const statusDisplayTemplate = (data) => {
    if (data.status === "IN_PROGRESS") {
      return "IN PROGRESS";
    } else if (data.status === "NOT_STARTED") {
      return "NOT STARTED";
    } else if (data.status === "REJECT_AND_REVISE") {
      return "FOR REVISION";
    } else if (data.status === "REJECTED") {
      return "DISAPPROVED";
    } else {
      return data.status;
    }
  };

  const actionTasksTemplate = (rowData) => {
    // Need Id of step from workflow template (workflowStepId) to identify where we are in the template view
    // Also, use workflowStepId to get type of step (basic or review) and step configuration
    return rowData.status !== "CANCELLED" ? (
      <>
        <Tooltip target=".p-button-custom" />
        <Tooltip target=".p-button-custom-task" />
        <div className="actions flex-nowrap flex justify-content-center">
          <span
            className="p-button-custom-task"
            data-pr-tooltip="View Task"
            data-pr-position="top"
            data-pr-at="center top-7"
          >
            <Link
              to={"/task/" + rowData.id}
              className="p-button p-button-icon-only p-button-text mr-2"
              // tooltip="View Task"
              // tooltipOptions={{ position: "top" }}
            >
              {rowData.status !== "COMPLETED" &&
                rowData.status !== "REJECT_AND_REVISE" &&
                rowData.status !== "REJECTED" && (
                  <i className="pi pi-arrow-circle-right text-2xl font-light" />
                )}
              {rowData.status === "COMPLETED" && (
                <i className="pi pi-check-circle text-2xl font-light text-green-500" />
              )}
              {rowData.status === "REJECT_AND_REVISE" && (
                <i className="pi pi-exclamation-circle text-2xl font-light text-yellow-500" />
              )}
              {rowData.status === "REJECTED" && (
                <i className="pi pi-times-circle text-2xl font-light text-pink-500" />
              )}
            </Link>
          </span>
          <span
            className="p-button-custom"
            data-pr-tooltip="Activity Dashboard"
            data-pr-position="top"
            data-pr-at="center top-7"
          >
            <Link
              to={"/tasks/" + rowData.activity.id}
              className="p-button p-button-icon-only p-button-text mr-2"
            >
              <i className="pi pi-list text-2xl font-light" />
            </Link>
          </span>
        </div>
      </>
    ) : (
      <div></div>
    );
  };

  const handleFilterChange = (newFilters) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      filters: {
        ...userSettings.filters,
        taskLists: newFilters.filters,
      },
    }));

    setFilters(newFilters.filters);
  };

  const handlePageChange = ({ first, rows }) => {
    setUserSettings((userSettings) => ({
      ...userSettings,
      pageSize: rows,
    }));

    setPageSize(rows);
    setOffset(first);
  };

  const statuses = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "DISAPPROVED", value: "REJECTED" },
    { label: "FOR REVISION", value: "REJECT_AND_REVISE" },
    { label: "IN PROGRESS", value: "IN_PROGRESS" },
  ];

  const statusFilterTemplate = (options) => {
    return (
      <Dropdown
        style={{ width: "100%" }}
        className="p-column-filter"
        placeholder="Select a Status"
        value={options.value}
        options={statuses}
        optionValue="value"
        optionLabel="label"
        onChange={(e) => options.filterCallback(e.value)}
        showClear
      />
    );
  };

  const paginatorTemplate = {
    layout: `FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport`,
    CurrentPageReport: (options) => {
      const itemTerm = options.totalRecords > 1 ? "tasks" : "task";
      return (
        <>
          <div className="flex justify-content-end ml-4">
            <span className="font-bold">{options.first}</span>&nbsp;to&nbsp;
            <span className="font-bold">{options.last}</span>&nbsp;of&nbsp;
            <span className="font-bold">{options.totalRecords}</span>&nbsp;
            {itemTerm}.
          </div>
        </>
      );
    },
  };

  return (
    <>
      <DataTable
        value={tasks}
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate={paginatorTemplate}
        paginator
        rows={pageSize}
        first={offset}
        rowsPerPageOptions={[10, 25, 50]}
        className="p-datatable-gridlines"
        showGridlines
        dataKey="id"
        loading={loadingIndicatorTasks}
        responsiveLayout="scroll"
        emptyMessage="No pending tasks found."
        size="small"
        filters={filters}
        filterDisplay="menu"
        globalFilterFields={["displayName", "activity.name", "status"]}
        sortMode="multiple"
        removableSort
        rowClassName={(data) => {
          return data.status === "COMPLETED"
            ? "bg-green-75"
            : data.status === "IN_PROGRESS"
            ? "bg-blue-75"
            : data.status === "REJECT_AND_REVISE"
            ? "bg-yellow-75"
            : data.status === "REJECTED"
            ? "bg-pink-75"
            : data.status === "CANCELLED"
            ? "bg-gray-100"
            : "";
        }}
        onFilter={handleFilterChange}
        onPage={handlePageChange}
      >
        <Column
          field="displayName"
          header="Task Name"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showFilterMenu={true}
          showFilterOperator={false}
          filter
          filterField="displayName"
          sortable
          filterPlaceholder="Task name"
        />
        <Column
          header="Activity"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showFilterMenu={true}
          showFilterOperator={false}
          filter
          filterField="activity.name"
          field="activity.name"
          sortable
          filterPlaceholder="Activity name"
        />
        <Column
          header="Initiated By"
          field="initiator"
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "14rem" }}
          sortable
        />
        <Column
          header="Date Requested"
          field="dateRequested"
          dataType="date"
          showFilterMatchModes={false}
          bodyClassName="text-center"
          body={dateDataFormat}
          style={{ minWidth: "13rem", maxWidth: "13rem", width: "13rem" }}
          sortable
        />
        <Column
          field="status"
          header="Status"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          body={statusDisplayTemplate}
          filter
          filterElement={statusFilterTemplate}
          style={{ minWidth: "12rem", maxWidth: "12rem", width: "12rem" }}
          sortable
        />
        <Column
          body={actionTasksTemplate}
          bodyClassName="text-center"
          style={{ width: "8rem", minWidth: "5rem" }}
        />
      </DataTable>
    </>
  );
}
