import React, { useState } from "react";
import { classNames } from "primereact/utils";
import { Sidebar } from "primereact/sidebar";
import CommentFeedLayout from "./CommentFeedLayout";

export default function ChatPanel(props) {
  const [unpinned, setUnpinned] = useState(true);
  const customIcons = (
    <React.Fragment>
      <button
        className="p-sidebar-icon p-link mr-1"
        onClick={() => setUnpinned(!unpinned)}
      >
        <span
          className={classNames(
            "pi",
            unpinned ? "pi-bookmark" : "pi-bookmark-fill"
          )}
        />
      </button>
    </React.Fragment>
  );

  return (
    <>
      <Sidebar
        visible={props.visible}
        onHide={props.handleHide}
        position="right"
        dismissable={unpinned}
        blockScroll={true}
        icons={customIcons}
        className="p-sidebar-right-chatpanel"
      >
        <CommentFeedLayout
          activityId={props.activityId}
          activityFolderId={props.activityFolderId}
        />
      </Sidebar>
    </>
  );
}
