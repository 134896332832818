import useSWR from "swr";
import useCurrentUser from "./useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { COUNT_USER_ASSIGNMENTS_INPROGRESS } from "../graphQL/tasks";

export default function useAssignmentCounts(accessToken, filterString) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getUserAssignments", userData.id],
    () =>
      queryGraphQL(
        COUNT_USER_ASSIGNMENTS_INPROGRESS,
        {
          userId: userData.id,
          taskStatus: "IN_PROGRESS",
          activityStatus: "IN_PROGRESS",
          filter: filterString,
        },
        accessToken
      ).then((response) => response)
  );

  return {
    assignmentCounts: data,
    isLoading: !error && !data,
    isError: error,
    mutateAssignmentCounts: mutate,
  };
}
