import React, { useRef } from "react";
import { Controls, ControlButton } from "react-flow-renderer";
import { updateWorkflow } from "../services/apiWorkflow";
import useAuth from "../hooks/useAuth";
import { Toast } from "primereact/toast";
import { dialogMessageList, actionSuccess } from "../common/dialogMessages";
import { removeOrphanEdges } from "../common/workflowHelper";
import useWorkflowDetails from "../hooks/useWorkflowDetails";

export default function WorkflowCanvasControlExtensions({
  allowEdit,
  nodes,
  edges,
  workflowId,
}) {
  const { getToken } = useAuth();
  const accessToken = getToken();
  const toast = useRef(null);
  const { workflowDetails } = useWorkflowDetails(workflowId, accessToken);

  const handleSaveTemplate = () => {
    const workflowUpdate = {
      nodes: nodes,
      edges: edges,
    };

    const targetWorkflow = {
      active: workflowDetails.active,
      departmentId: workflowDetails.departmentId,
      id: workflowDetails.id,
      name: workflowDetails.name,
      createdById: workflowDetails.createdById,
      updatedById: workflowDetails.updatedById,
      workflow: workflowDetails.workflow,
    };

    workflowUpdate.edges = removeOrphanEdges(nodes, edges);
    const workflowUpdateSlim = workflowUpdate.nodes.map((node) => {
      delete node.data["userList"];
      return node;
    });

    workflowUpdate.nodes = workflowUpdateSlim;
    targetWorkflow.workflow = JSON.stringify(workflowUpdate);

    delete targetWorkflow["createdBy"];
    delete targetWorkflow["createdById"];
    delete targetWorkflow["updatedBy"];
    delete targetWorkflow["updatedById"];
    delete targetWorkflow["createdDate"];
    delete targetWorkflow["updatedDate"];

    updateWorkflow(targetWorkflow, accessToken)
      .then((updateResponse) => {
        toast.current.show(
          actionSuccess(dialogMessageList.WorkflowSaveSuccessful)
        );
      })
      .catch((e) => {
        console.log("e: ", e);
      });
  };
  return (
    <Controls showInteractive={false}>
      {allowEdit && (
        <>
          <Toast ref={toast} />
          <ControlButton onClick={() => handleSaveTemplate()}>
            <i className="pi pi-fw pi-save"></i>
          </ControlButton>
        </>
      )}
    </Controls>
  );
}
