import useSWR from "swr";
import useCurrentUser from "./useCurrentUser";
import { queryGraphQL } from "../services/apiGraphQL";
import { GET_USER_FOLDERS } from "../graphQL/files";

export default function useFolderList(accessToken) {
  const { userData } = useCurrentUser(accessToken);

  const { data, error, mutate } = useSWR(
    userData && ["getFolderList", userData.id],
    () =>
      queryGraphQL(GET_USER_FOLDERS, { userId: userData.id }, accessToken).then(
        (response) => response.folders
      )
  );

  return {
    folderList: data,
    isLoading: !error && !data,
    isError: error,
    mutateFolderList: mutate,
  };
}
