import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserIdClaim, isTokenExpired, IsUserRoleAllowed } from "../common/utilities";
import useAuth from "../hooks/useAuth";
import useCurrentUser from "../hooks/useCurrentUser";

export default function RequireAdminPermission({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const state = { from: location };
  const authToken = localStorage.getItem("userSession");
  const { getToken } = useAuth();
  const accessToken = getToken();
  const { userData } = useCurrentUser(accessToken);

  let isLoggedIn = false;
  if (authToken) {
    isLoggedIn = getUserIdClaim(authToken) > 0 && !isTokenExpired(authToken);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      navigate("/login", { state });
    }else{
      // Redirect them to the /home page, but save the current location they were
      if(!IsUserRoleAllowed(process.env.REACT_APP_ADMIN_ROLES, (userData?userData.roles:[]))){
        navigate("/", { state });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return children;
}
