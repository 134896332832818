import React, { useState, useEffect } from "react";
import { Tree } from "primereact/tree";
import { createNode, getNodeIcon } from "../common/treeNodeHelper";
import { fetchSupportFilesMetadata } from "../services/apiBinaryAssets";

export default function SupportFiles(props) {
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    fetchSupportFilesMetadata().then((response) => {
      loadRootFoldersAsync(response);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadRootFoldersAsync = (folderList) => {
    const data = folderList
      .map(function (folder) {
        if (folder.parentId === null) {
          return folder;
        } else {
          return false;
        }
      })
      .filter(Boolean);

    let nodeList = [];
    for (let i = 0; i < data.length; i++) {
      const node = createNode(
        data[i].id,
        data[i].name,
        data[i],
        getNodeIcon(data[i].folderType)
      );

      node.leaf = true;
      nodeList.push(node);
    }
    setNodes(nodeList);
  };

  const nodeTemplate = (node, options) => {
    const label = (
      <a
        href={process.env.PUBLIC_URL + "/assets/docs/" + node.data.fileName}
        target="_blank"
        rel="noreferrer"
      >
        {node.label}
      </a>
    );

    return (
      <>
        <span className={options.className}>
          <div className="grid grid-nogutter flex align-items-center justify-content-between">
            {label}
          </div>
        </span>
      </>
    );
  };

  return (
    <>
      {nodes.length > 0 && (
        <Tree
          className="border-none p-0"
          contentClassName="p-0"
          value={nodes}
          nodeTemplate={nodeTemplate}
        />
      )}
    </>
  );
}
