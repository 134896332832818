import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { classNames } from "primereact/utils";
import AppTopbar from "../app/AppTopbar";
// import AppBreadcrumb from "../app/AppBreadcrumb";
import AppFooter from "../app/AppFooter";
import { SelectedFolderProvider } from "../contexts/SelectedFolderContext";
import { RefreshFolderContentProvider } from "../contexts/RefreshFolderContentContext";
import { ActivityDetailsContextProvider } from "../contexts/ActivityDetailsContext";
import { SignatureProvider } from "../contexts/SignatureContext";
import { UserSettingsContextProvider } from "../contexts/UserSettingsContext";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function AppLayout() {
  //const [horizontal, setHorizontal] = useState(true);
  const horizontal = true;
  const [menuActive, setMenuActive] = useState(false);
  const [menuHoverActive, setMenuHoverActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let menuClick;
  let userMenuClick;

  const onMenuButtonClick = (event) => {
    menuClick = true;

    if (!horizontal || isMobile()) {
      setMenuActive((prevMenuActive) => !prevMenuActive);
    }

    event.preventDefault();
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const onTopbarUserMenuButtonClick = (event) => {
    userMenuClick = true;
    setTopbarUserMenuActive(
      (prevTopbarUserMenuActive) => !prevTopbarUserMenuActive
    );

    event.preventDefault();
  };

  const onTopbarUserMenuClick = (event) => {
    userMenuClick = true;

    if (
      event.target.nodeName === "BUTTON" ||
      event.target.parentNode.nodeName === "BUTTON"
    ) {
      setTopbarUserMenuActive(false);
    }
    event.preventDefault();
  };

  const onRootMenuItemClick = (event) => {
    menuClick = true;
    if (horizontal && isMobile()) {
      setMenuHoverActive(event.isSameIndex ? false : true);
    } else {
      setMenuHoverActive((prevMenuHoverActive) => !prevMenuHoverActive);
    }
  };

  const onMenuItemClick = (event) => {
    if (event.item && !event.item.items) {
      if (!horizontal || isMobile()) {
        setMenuActive(false);
        unblockBodyScroll();
      }

      setMenuHoverActive(false);
    }
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const isMobile = () => {
    return window.innerWidth <= 1024;
  };

  const onWrapperClick = () => {
    if (!menuClick) {
      setMenuActive(false);
      unblockBodyScroll();

      if (horizontal) {
        setMenuHoverActive(false);
      }
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    userMenuClick = false;
    menuClick = false;
  };

  const layoutContainerClassName = classNames(
    "layout-container layout-top-large layout-topbar-blue layout-menu-light flex",
    {
      "layout-menu-horizontal": horizontal,
      "layout-menu-active": menuActive,
    }
  );
  return (
    <div className={layoutContainerClassName} onClick={onWrapperClick}>
      <div className="layout-top">
        <AppTopbar
          topbarUserMenuActive={topbarUserMenuActive}
          menuActive={menuActive}
          menuHoverActive={menuHoverActive}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarUserMenuButtonClick={onTopbarUserMenuButtonClick}
          onTopbarUserMenuClick={onTopbarUserMenuClick}
          horizontal={horizontal}
          onSidebarClick={onSidebarClick}
          onRootMenuItemClick={onRootMenuItemClick}
          onMenuItemClick={onMenuItemClick}
          isMobile={isMobile}
        />
        <div className="layout-topbar-separator" />

        <span className="text-white text-sm font-light mt-2 text-justify vertical-align-top lg:hidden flex-wrap lg:inline-flex">
          Document Tracking and Process Monitoring System Version 01
        </span>
        {/* <span className="text-white text-sm font-light lg:ml-1 text-justify vertical-align-top md:max-w-11rem md:ml-4 flex-wrap flex">
          Process Monitoring System
        </span> */}
        {/* <AppBreadcrumb routers={PageList} /> */}
      </div>

      <div className="layout-content">
        <SignatureProvider>
          <SelectedFolderProvider>
            <RefreshFolderContentProvider>
              <ActivityDetailsContextProvider>
                <UserSettingsContextProvider>
                  <Outlet />
                </UserSettingsContextProvider>
              </ActivityDetailsContextProvider>
            </RefreshFolderContentProvider>
          </SelectedFolderProvider>
        </SignatureProvider>
      </div>

      <AppFooter />

      {menuActive && <div className="layout-mask" />}
    </div>
  );
}

export default AppLayout;
